import BusinessIcon from '@mui/icons-material/Business';
import ShopOutlinedIcon from '@mui/icons-material/ShopOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TicketsIcon from '../../../assets/icons/TicketsIcon';
import EarningsIcon from '../../../assets/icons/EarningsIcon';
import AddNewUserIcon from '../../../assets/icons/AddNewUser';

export const shortcuts = [
  {
    name: 'Companies',
    route: '/dashboard/customers/companies/list',
    icon: <BusinessIcon />,
    color: '#6774DF',
  },
  {
    name: 'Create New User',
    route: '/dashboard/customers/customers/add',
    icon: <AddNewUserIcon />,
    color: '#939FAD',
  },
  {
    name: 'Manage Invoices',
    route: '/dashboard/shipment/AWB/list',
    icon: <ReceiptOutlinedIcon />,
    color: '#7DC668',
  },
  { name: 'Earnings', route: '/dashboard/reports', icon: <EarningsIcon />, color: '#5DD2BC' },
  {
    name: 'Channel',
    route: '/dashboard/channels/channels/list',
    icon: <ShopOutlinedIcon />,
    color: '#4A90E2',
  },
  {
    name: 'Master Pricing',
    route: '/dashboard/master_pricing/master_pricing/list',
    icon: <AccountBalanceIcon />,
    color: '#F5B666',
  },
  {
    name: 'Transaction',
    route: '/dashboard/payment/payment/list',
    icon: <AssignmentIcon />,
    color: '#6774DF',
  },
  {
    name: 'SupportTicket',
    route: '/dashboard/support/open_ticket/list',
    icon: <TicketsIcon />,
    color: '#FF7076',
  },
];
