import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { ReactComponent as MasterPricing } from '../../assets/icons/MasterPricing.svg';
import { ReactComponent as CustomersIcon } from '../../assets/icons/Customers.svg';
import { ReactComponent as Shipment } from '../../assets/icons/Shipment.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/Support.svg';
import { ReactComponent as AdministrationIcon } from '../../assets/icons/Administration.svg';
import { ReactComponent as FaqsIcon } from '../../assets/icons/faqs.svg';
import { ReactComponent as TermsIcon } from '../../assets/icons/privacy-policy.svg';
import PaymentIcon from '../../assets/icons/PaymentIcon';
import { Route } from './types';
import PartnersIcon from '../../assets/icons/PartnersIcon';
import ChannelsIcon from '../../assets/icons/ChannelsIcon';

export const routes: Route[] = [
  {
    name: 'Dashboard',
    icon: <GridViewSharpIcon />,
    route: '/dashboard/quick_access',
    children: [],
  },
  {
    name: 'Partners',
    icon: <PartnersIcon />,
    route: '/dashboard/partners/interchange_point/list',
    children: [
      { name: 'Interchange Point', route: '/dashboard/partners/interchange_point/list' },
      { name: 'Clearance Company', route: '/dashboard/partners/clearance_company/list' },
      { name: 'Delivery Company', route: '/dashboard/partners/delivery_company/list' },
    ],
  },
  {
    name: 'Channels',
    icon: <ChannelsIcon />,
    route: '/dashboard/channels/channels/list',
    children: [],
  },
  {
    name: 'Master Pricing',
    icon: <MasterPricing />,
    route: '/dashboard/master_pricing/master_pricing/list',
    children: [],
  },
  {
    name: 'Customers',
    icon: <CustomersIcon />,
    route: '/dashboard/customers/customers/list',
    children: [
      { name: 'Customers', route: '/dashboard/customers/customers/list' },
      { name: 'Companies', route: '/dashboard/customers/companies/list' },
    ],
  },
  {
    name: 'Shipment',
    icon: <Shipment />,
    route: '/dashboard/shipment/shipment/list',
    children: [
      { name: 'Shipments List', route: '/dashboard/shipment/shipment/list' },
      { name: 'Book Shipment', route: '/dashboard/shipment/book/add' },
      { name: 'Receive Shipment', route: '/dashboard/shipment/receive/list' },
      { name: 'Process Shipment', route: '/dashboard/shipment/process/list' },
      { name: 'Deliver Shipment', route: '/dashboard/shipment/deliver/list' },
      { name: 'AWB Invoice', route: '/dashboard/shipment/AWB/list' },
      { name: 'Shipment Tracking', route: '/dashboard/shipment/track/select' },
    ],
  },
  {
    name: 'Payment',
    icon: <PaymentIcon />,
    route: '/dashboard/payment/payment/list',
    children: [],
  },
  {
    name: 'Support',
    icon: <SupportIcon />,
    route: '/dashboard/support/open_ticket/list',
    children: [
      { name: 'Ticket', route: '/dashboard/support/open_ticket/list' },
      // { name: 'Close Ticket', route: '/dashboard/support/close_ticket/list' },
      // { name: 'View Ticket', route: '/dashboard/support/add_open_ticket/view' },
    ],
  },
  {
    name: 'Administration',
    icon: <AdministrationIcon />,
    route: '/dashboard/administration/warehouses/list',
    children: [
      { name: 'Warehouses', route: '/dashboard/administration/warehouses/list' },
      { name: 'Admin & Staff', route: '/dashboard/administration/admin_&_staff/list' },
      { name: 'Global Settings', route: '/dashboard/administration/global_settings' },
    ],
  },
  {
    name: 'Reports',
    icon: <TroubleshootIcon />,
    route: '/dashboard/reports',
    children: [],
  },
  {
    name: 'FAQs',
    icon: <FaqsIcon />,
    route: '/dashboard/FAQs/list',
    children: [],
  },
  {
    name: 'Terms & Policies',
    icon: <TermsIcon />,
    route: '/dashboard/terms_&_policies/list',
    children: [],
  },
];
