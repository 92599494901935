import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

export const CheckBoxFormik = (props: {
  id: any;
  label: string;
  placeholder?: string;
  formik: any;
}) => {
  const { id, label, placeholder, formik } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Checkbox
        id={id}
        name={id}
        placeholder={placeholder || ''}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={
          typeof formik.values[id] === 'boolean' ? formik.values[id] : formik.values[id] === 'true'
        }
        checked={
          typeof formik.values[id] === 'boolean' ? formik.values[id] : formik.values[id] === 'true'
        }
        onChange={formik.handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        //   helperText={formik.touched[id] && formik.errors[id]}
      />
      {typeof formik.values[id] === 'boolean' ? `${formik.values[id]}` : formik.values[id]}
    </Box>
  );
};
