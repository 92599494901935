import { baseUrl } from '../../../../../utils/constants';
import { DashboardTableList } from '../../../../../components/DashboardTableList';

export const OpenTicketsList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'subject', label: 'Subject', align: 'left' },
      { id: 'status', label: 'Status', align: 'left', isTicketStatus: true },
      { id: 'updated_at', label: 'Last Update', align: 'left' },
      { id: 'actionView', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/support/open_ticket/add'
    getUrl={`${baseUrl}/api/v1/support/mobile/ticket/`}
    deleteUrl={`${baseUrl}/api/v1/support/mobile/ticket/`}
    viewUrl='/dashboard/support/add_open_ticket/view'
    category='Support'
    name='Tickets List'
  />
);
