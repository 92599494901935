import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../utils/constants';
import CustomizedSnackBars from '../../components/SnackBarMui';

const validationSchema = yup.object({
  mobile: yup.string().required('This Field is required'),
  code: yup.string().required('This Field is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('This field is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('This field is required'),
});

export const OtpForm = (props: { mobile: string }) => {
  const navigate = useNavigate();
  const { mobile } = props;
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const formik = useFormik({
    initialValues: {
      mobile: mobile || '',
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.post(`${baseUrl}/api/v1/auth/forget/password/confirm/`, {
          mobile: values.mobile,
          code: values.code,
          password: values.password,
        });
        navigate('/');
      } catch (error: any) {
        setOpenSnackBar(true);
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.password) {
            setErrorMsg(error?.response?.data?.errors?.password);
          }
          if (error?.response?.data?.errors?.mobile) {
            setErrorMsg(error?.response?.data?.errors?.mobile);
          }
          if (error?.response?.data?.errors?.code) {
            setErrorMsg(error?.response?.data?.errors?.code);
          }
          if (error?.response?.data?.message) {
            setErrorMsg(error?.response?.data?.message);
          }
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        margin='normal'
        required
        fullWidth
        id='mobile'
        label='Mobile'
        name='mobile'
        autoComplete='mobile'
        autoFocus
        value={formik.values.mobile}
        onChange={formik.handleChange}
        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
        helperText={formik.touched.mobile && formik.errors.mobile}
      />
      <TextField
        margin='normal'
        required
        fullWidth
        id='code'
        label='Verification Code'
        name='code'
        autoComplete='code'
        type='number'
        autoFocus
        value={formik.values.code}
        onChange={formik.handleChange}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
      />
      <TextField
        margin='normal'
        required
        fullWidth
        id='password'
        label='Password'
        name='password'
        type='password'
        autoComplete='password'
        autoFocus
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TextField
        margin='normal'
        required
        fullWidth
        type='password'
        id='confirmPassword'
        label='Confirm  Password'
        name='confirmPassword'
        autoComplete='confirmPassword'
        autoFocus
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
      />
      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs />
        <Grid item />
      </Grid>
      <Button type='submit' variant='contained' sx={{ mt: 1, mb: 2 }}>
        Reset Password
      </Button>
      <CustomizedSnackBars
        message={errorMsg}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type='error'
      />
    </form>
  );
};
