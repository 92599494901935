/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { TextFieldFormik } from './TextFieldFormik';
import { CheckBoxFormik } from './CheckBoxFormik';
import { RadioButtonFormik } from './RadioButtonFormik';
import { MasterPricingFormik } from './MasterPricingFormik';
import { ImageUpload } from './ImageUpload';
import { TextFieldGenerateFormik } from './TextFieldFormikGenerate';
import { ItemInfoFormik } from './ItemInfoformik';
import { DimensionFormik } from './Dimension';
import { MapFormik } from './MapFormik';
import { MultiLineFormik } from './multiLineFormik';
import { BarCodeFieldFormik } from './BarCodeFieldFormik';
import { CountrySelectFormik } from './CountrySelectFormik';
import { SelectFromApiFormik } from './SelectFromApiFormik';
import { UploadFilesFormik } from './UploadFilesFormik';
import { SelectFormik } from './SelectFormik';
import { SelectWithSearchFromApiFormik } from './SelectWithSearchFromApiFormik';
import { UploadMultipleFilesFormik } from './uploadMultipleFiles';
import { CalculationFormik } from './CalculationFormik';
import { PlacesAutocomplete } from './AddressAutoCompleteFormik';

export interface FormField {
  id: string;
  label: string;
  type: string;
  options: any[];
  fullWidth: boolean;
  placeholder?: string;
  textFieldType?: string;
  url?: string;
  disabled?: boolean;
}

export const AllFields = (props: { formFields: FormField[]; formik: any; values?: any }) => {
  const { formFields, formik, values } = props;
  useEffect(() => {
    if (values) {
      formik.setValues(values);
    }

    return () => {};
  }, [values]);

  return (
    <>
      {formFields?.length > 0 &&
        formFields.map((field: FormField) => {
          const { type, id, label, fullWidth, placeholder, options, textFieldType, url, disabled } =
            field;
          switch (type) {
            // case 'autocomplete':/
            // return <AutocompleteFormik formik={formik} key={id} />;
            case 'calculation':
              return <CalculationFormik formik={formik} key={id} />;
            case 'textField':
              return (
                <TextFieldFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  textFieldType={textFieldType}
                  disabled={disabled}
                />
              );
            case 'fileUpload':
              return (
                <UploadFilesFormik
                  id={id}
                  //  label={label}
                  formik={formik}
                  key={id}
                />
              );
            case 'fileMultipleUpload':
              return (
                <UploadMultipleFilesFormik
                  id={id}
                  //  label={label}
                  formik={formik}
                  key={id}
                />
              );
            case 'countrySelect':
              return (
                <CountrySelectFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                />
              );
            case 'customSelectApi':
              return (
                <SelectFromApiFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  url={url}
                  options={options}
                />
              );
            case 'customSelectWithSearchFromApi':
              return (
                <SelectWithSearchFromApiFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  url={url}
                  fieldType={textFieldType}
                  options={options}
                />
              );
            case 'customSelect':
              return (
                <SelectFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  options={options}
                />
              );
            case 'barCodeField':
              return (
                <BarCodeFieldFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  textFieldType={textFieldType}
                />
              );
            case 'textFieldGenerate':
              return (
                <TextFieldGenerateFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  formik={formik}
                  key={id}
                />
              );
            case 'checkBox':
              return (
                <CheckBoxFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  key={id}
                  formik={formik}
                />
              );

            case 'radioButton':
              return (
                <RadioButtonFormik
                  id={id}
                  label={label}
                  formik={formik}
                  options={options}
                  key={id}
                  initialValues={formik.initialValues}
                />
              );

            case 'masterPricing':
              return <MasterPricingFormik label={label} formik={formik} key={id} />;
            case 'imageUpload':
              return <ImageUpload id={id} url={url} label={label} key={id} formik={formik} />;
            case 'separator':
              if (label) {
                return (
                  <Typography
                    key={id}
                    sx={{ width: '100%', borderBottom: '2px solid gray', fontSize: '20px' }}
                  >
                    {label}
                  </Typography>
                );
              }
              return <hr className='formik-separator' key={id} />;
            case 'itemInfo':
              return <ItemInfoFormik id={id} key={id} formik={formik} />;
            case 'dimension':
              return <DimensionFormik id={id} key={id} formik={formik} />;
            case 'mapField':
              return <MapFormik id={id} key={id} formik={formik} options={options} />;
            case 'multiLine':
              return (
                <MultiLineFormik
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  formik={formik}
                  key={id}
                  textFieldType={textFieldType}
                />
              );
            case 'addressAutocomplete':
              return (
                <PlacesAutocomplete
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  formik={formik}
                  key={id}
                  textFieldType={textFieldType}
                  fullWidth={fullWidth}
                />
              );
            default:
              return <>not render {type}</>;
          }
        })}
    </>
  );
};
