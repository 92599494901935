/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { GeneralForm } from '../../../../../components/GeneralForm';
import { baseUrl } from '../../../../../utils/constants';
import { useAuth } from '../../../../../store/getValues';
import CustomizedSnackBars from '../../../../../components/SnackBarMui';

const validationSchema = yup.object({
  // shipment: yup.string().required('This Field is required'),
  origin_warehouse: yup.string().required('This Field is required'),
  payment_uid: yup.string().required('This Field is required'),
  // paymentRef: yup.string().required('This Field is required'),
});

type ResponseData = {
  origin_warehouse: string;
  // shipment: string;
  payment_uid: string;
  id: number;
  awb: string;
};

export const ShipmentReceiveAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state !== null;
  const { id } = location.state;
  const auth = useAuth();
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  const formFields = [
    {
      id: 'origin_warehouse',
      label: 'Origin Warehouse',
      type: 'customSelectApi',
      options: [{ value: 'id', label: 'name' }],
      fullWidth: true,
      url: `${baseUrl}/api/v1/shipment/admin/warehouse/`,
    },
    // {
    //   id: 'paymentStatus',
    //   label: 'Payment Status',
    //   type: 'radioButton',
    //   options: [
    //     { label: 'Yes', value: 'yes' },
    //     { label: 'No', value: 'no' },
    //   ],
    //   fullWidth: true,
    // },
    {
      id: 'payment_uid',
      label: 'Payment UID',
      type: 'customSelectApi',
      options: [{ value: 'uid', label: 'uid' }],
      fullWidth: true,
      url: `${baseUrl}/api/v1/utility/admin/payment/?shipment=${id}`,
    },
  ];
  // const [values, setValues] = useState<InitialValues>({
  //   shipment: '',
  //   clearance_company: '',
  //   delivery_company: '',
  //   delivery_man: '',
  //   status: 0,
  //   channel: '',
  // });

  const formData = useRef({
    validationSchema,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values: any) => {},
    initialValues: {
      origin_warehouse: '',
      payment_uid: '',
      id,
    },
    formFields,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        // eslint-disable-next-line no-unused-vars
        const response: { data: { data: ResponseData } } = await axios.get(
          `${baseUrl}/api/v1/shipment/admin/shipment/${id}/`,
          {
            headers: { Authorization: `Token ${auth.token}` },
          },
        );
        formData.current = {
          validationSchema,
          onSubmit: async (values: any) => {
            try {
              await axios.post(`${baseUrl}/api/v1/shipment/admin/shipment/receive/`, values, {
                headers: {
                  Authorization: `Token ${auth.token}`,
                  'Content-Type': 'multipart/form-data',
                },
              });
              setOpen(true);
              setType('success');
              if (isEdit) {
                setMessage(`Successfully Edit item with id# ${location.state.id}`);
              } else {
                setMessage('Successfully created');
              }
              setTimeout(() => navigate(-1), 1000);
              // Navigate({ to: '/dashboard/partners/interchange_point/list' });
            } catch (error) {
              // @ts-ignore
              const errorObj = error?.response?.data?.errors || {};
              // @ts-ignore
              const errorMsg = error?.response?.data?.message;
              // @ts-ignore
              if (errorObj) {
                for (const property in errorObj) {
                  setOpen(true);
                  setType('error');
                  setMessage(`${property}: ${errorObj[property]}`);
                }
              } else if (errorMsg) {
                setOpen(true);
                setType('error');
                setMessage(errorMsg);
              } else {
                setOpen(true);
                setType('error');
                setMessage('Something went wrong');
              }
            }
          },
          initialValues: {
            origin_warehouse: '',
            payment_uid: '',
            id,
          },
          formFields,
        };

        setLoadingData(false);
      } catch (error) {
        /* empty */
      }
    };
    fetchData();

    return () => {
      // second;
    };
  }, []);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <CustomizedSnackBars type={type} message={message} open={open} setOpen={setOpen} />
        <Typography paragraph variant='h5' fontWeight='bold'>
          {isEdit ? 'Edit' : 'Add'} Receive a Shipment
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / Shipment / <b>{isEdit ? 'Edit' : 'Add'} Receive a Shipment</b>
          </Typography>
        </div>
      </div>
      {loadingData ? (
        <Box
          sx={{
            width: '100%',
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <GeneralForm formData={formData.current} disableLocationState />
      )}
    </div>
  );
};
