import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
// import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './Theme';
import { AppRoutes } from './routes';
import { store } from './store';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Provider store={store}>
        <Router>
          <AppRoutes />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
