interface Action {
  type: string;
  payload: any;
}

const initialState: any | null = null;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const profileData = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_PROFILE_DATA':
      return action.payload;

    default:
      return state;
  }
};
