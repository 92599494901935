import { useSelector } from 'react-redux';
import { Country } from './reducers/countries';

export const useAuth = () =>
  useSelector(
    (state: { auth: { userName: string; isAuth: boolean; token: string; image: string } }) =>
      state.auth,
  );

export const useCountries = () => useSelector((state: { countries: Country[] }) => state.countries);

export const useProfileData = () => useSelector((state: { profileData: any }) => state.profileData);
