/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FieldArray } from 'formik';
// import { FieldArray } from 'formik';

export const ItemInfoFormik = (props: {
  id: string;
  //   label: string;
  //   placeholder?: string;
  formik: any;
}) => {
  const { id, formik } = props;
  //   const generateKey = () => {
  //     formik.setFieldValue(id, 'make sure to obtain this value from api');
  //   };
  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: '1rem', mt: 0, pt: 0, fontSize: '20px' }}>Item-Info</Typography>
      <FieldArray name={id}>
        {({ remove, push }) => (
          <div>
            {formik.values[id]?.length > 0 &&
              formik.values[id].map((item: any, index: number) => (
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', mb: '1rem' }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${id}.${index}.name`}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: {
                        xs: 'flex-start',
                        sm: 'flex-start',
                        md: 'space-between',
                      },
                      gap: '1rem',
                    }}
                  >
                    <Typography sx={{ alignSelf: 'center' }}>{index + 1})</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: {
                          xs: 'unset',
                          sm: 'unset',
                          md: '2',
                        },
                      }}
                    >
                      <InputLabel htmlFor={`${id}.${index}.name`} sx={{ color: 'black' }}>
                        Name
                      </InputLabel>
                      <TextField
                        id={`${id}.${index}.name`}
                        name={`${id}.${index}.name`}
                        type='text'
                        value={formik.values[id][index].name}
                        placeholder='Type Name'
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            `${id}.${index}.total`,
                            // @ts-ignore
                            formik.values[id][index].qyt * +e.target.value,
                          );
                        }}
                        error={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.name &&
                          formik.errors?.shipment_items &&
                          formik.errors?.shipment_items[index]?.name
                        }
                        helperText={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.name &&
                          formik.errors.shipment_items &&
                          formik.errors.shipment_items[index]?.name
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <InputLabel sx={{ color: 'black' }} htmlFor={`${id}.${index}.unit_price`}>
                        Unit Price
                      </InputLabel>
                      <TextField
                        type='number'
                        name={`${id}.${index}.unit_price`}
                        value={formik.values[id][index].unit_price}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            `${id}.${index}.total`,
                            // @ts-ignore
                            formik.values[id][index].qty * +e.target.value,
                          );
                        }}
                        id={`${id}.${index}.unit_price`}
                        error={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.unit_price &&
                          formik.errors?.shipment_items &&
                          formik.errors?.shipment_items[index]?.unit_price
                        }
                        helperText={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.unit_price &&
                          formik.errors.shipment_items &&
                          formik.errors.shipment_items[index]?.unit_price
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <InputLabel sx={{ color: 'black' }} htmlFor={`${id}.${index}.qty`}>
                        Quantity
                      </InputLabel>
                      <TextField
                        type='number'
                        name={`${id}.${index}.qty`}
                        value={formik.values[id][index].qty}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            `${id}.${index}.total`,
                            // @ts-ignore
                            formik.values[id][index].unit_price * +e.target.value,
                          );
                        }}
                        id={`${id}.${index}.qty`}
                        error={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.qty &&
                          formik.errors?.shipment_items &&
                          formik.errors?.shipment_items[index]?.qty
                        }
                        helperText={
                          formik.touched?.shipment_items &&
                          formik.touched?.shipment_items[index]?.qty &&
                          formik.errors.shipment_items &&
                          formik.errors.shipment_items[index]?.qty
                        }
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <InputLabel sx={{ color: 'black' }} htmlFor={`${id}.${index}.total`}>
                        Total
                      </InputLabel>
                      <TextField
                        type='number'
                        name={`${id}.${index}.total`}
                        id={`${id}.${index}.total`}
                        value={formik.values[id][index].unit_price * formik.values[id][index].qty}
                        // onChange={() => {
                        //   formik.setFieldValue(
                        //     `${id}.${index}.total`,
                        //     formik.values[id][index].unit_price * formik.values[id][index].qty,
                        //   );
                        // }}
                        disabled
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // error={
                        //   formik.touched?.itemInfo &&
                        //   formik.touched?.itemInfo[index]?.unitPrice &&
                        //   formik.errors?.itemInfo[index]?.unitPrice
                        // }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // helperText={
                        //   formik.touched?.itemInfo &&
                        //   formik.touched?.itemInfo[index]?.unitPrice &&
                        //   formik.errors?.itemInfo[index]?.unitPrice
                        // }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: {
                        xs: 'flex-start',
                        sm: 'flex-start',
                        md: 'center',
                      },
                      gap: '1rem',
                      flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                      },
                    }}
                  >
                    {index === 0 ? (
                      <div style={{ width: '112px' }} />
                    ) : (
                      <Button
                        type='button'
                        className='secondary'
                        color='error'
                        variant='contained'
                        sx={{ padding: '10px 29px' }}
                        onClick={() => remove(index)}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}

            <Button
              type='button'
              variant='contained'
              onClick={() =>
                push({
                  unit_price: 0,
                  qty: 0,
                  total: 0,
                })
              }
              sx={{ padding: '10px 16px' }}
            >
              + Add Item
            </Button>
          </div>
        )}
      </FieldArray>
    </Box>
  );
};
