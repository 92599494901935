export const baseUrl = 'https://bdropship.emenem.tech';

export const PAYMENT_STATUS = { 0: 'Pending', 1: 'Success', 2: 'Cancelled', 3: 'Failed' };

export const BILLING_TYPE = { 0: 'Cash', 1: 'Online' };

export const SHIPMENT_STATUS = {
  0: 'Created',
  1: 'Received',
  2: 'IN Transit',
  3: 'Handed to delivery partner',
  4: 'Waiting for clearance',
  5: 'Arrived at destination warehouse',
  6: 'Out for delivery',
  7: 'Delivered',
  8: 'No Response',
  9: 'Returning',
  10: 'Canceled',
  11: 'Returned',
};
