import * as yup from 'yup';
import { baseUrl } from '../../../../utils/constants';
import { DashboardForm } from '../../../../components/DashboardForm';

const formFields = [
  { id: 'subject', label: 'Subject', type: 'textField', options: [], fullWidth: true },
  {
    id: 'status',
    label: 'Status',
    type: 'radioButton',
    options: [
      { label: 'Open', value: 0 },
      { label: 'Close', value: 1 },
    ],
    fullWidth: true,
    textFieldType: 'number',
  },

  {
    id: 'body',
    label: 'Message Body',
    type: 'multiLine',
    options: [],
    fullWidth: true,
  },
  {
    id: 'priority',
    label: 'Priority',
    type: 'radioButton',
    options: [
      { value: 0, label: 'Low' },
      { value: 1, label: 'Medium' },
      { value: 2, label: 'High' },
    ],
    fullWidth: true,
  },
  {
    id: 'attachment',
    label: 'Attachment',
    type: 'fileMultipleUpload',
    options: [],
    fullWidth: true,
  },
];

const validationSchema = yup.object({
  subject: yup.string().required('This field is required'),
  status: yup.string().required('This field is required'),
  priority: yup.number().required('This field is required'),
  body: yup.string().required('This field is required'),
  attachment: yup.mixed().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }, index) => {
  switch (type) {
    case 'textField':
      initialValues[id] = '';
      break;
    case 'radioButton':
      // eslint-disable-next-line prefer-destructuring
      initialValues[id] = formFields[index].options[0].value || '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;

    case 'fileMultipleUpload':
      initialValues[id] = [];
      break;
    default:
      break;
  }
});

export const TicketAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Support'
    name='Ticket'
    validationSchema={validationSchema}
    listUrl='/dashboard/support/open_ticket/list'
    postUrl={`${baseUrl}/api/v1/support/mobile/ticket/`}
    patchUrl={`${baseUrl}/api/v1/support/mobile/ticket/`}
  />
);
