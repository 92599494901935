import Box from '@mui/material/Box';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      title: {
        display: true,
        text: 'Customer Statics',
      },
    },
  },
};

export function BarChart(props: { rawData: any }) {
  const { rawData } = props;
  const labelsObject = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };
  const labels = rawData.map(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (elem: { created_at_month: string }) => labelsObject[elem.created_at__month],
  );
  const data = {
    labels,
    datasets: [
      {
        label: 'Customers',
        data: rawData.map(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (elem: { count: string }) => elem.count,
        ),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '100%',
        // margin: '1rem 2rem',
        backgroundColor: 'white',
        // padding: '1rem',
        minHeight: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pt: '1rem',
      }}
    >
      <Bar options={options} data={data} />
    </Box>
  );
}
