import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import { useState } from 'react';
import { ReactComponent as DropShipIcon } from '../../assets/icons/DropShipLogo.svg';
import { signIn } from '../../store/actions/auth';
import { baseUrl } from '../../utils/constants';
import CustomizedSnackBars from '../../components/SnackBarMui';
import deliveryMan from '../../assets/icons/deliveryMan.svg';
import { setProfileData } from '../../store/actions/profileData';

const validationSchema = yup.object({
  mobile: yup.string().required('user name is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});
export interface ErrorResponseType {
  response: {
    data: {
      message: string;
    };
  };
}

function LoginPage() {
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mobile: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${baseUrl}/api/v1/auth/login/?is_staff=true`, {
          mobile: values.mobile,
          password: values.password,
        });
        const isAuth = await axios.get(`${baseUrl}/api/v1/auth/user/${response.data.data.id}/`, {
          headers: {
            Authorization: `Token ${response.data.data.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (isAuth.data.data.is_superuser) {
          const profileData = await axios.get(`${baseUrl}/api/v1/auth/profile/`, {
            headers: {
              Authorization: `Token ${response.data.data.token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          dispatch(
            signIn(
              `${response.data.data.first_name || ''} ${response.data.data.last_name || ''}`,
              response.data.data.token,
              response.data.data.image,
            ),
          );
          dispatch(setProfileData(profileData.data.data));
          navigate('/dashboard/quick_access');
        } else {
          setOpenSnackBar(true);

          setErrorMsg('You are not authorized');
        }
      } catch (error: any) {
        setOpenSnackBar(true);
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.mobile) {
            setErrorMsg(error?.response?.data?.errors?.mobile);
          }
          if (error?.response?.data?.message) {
            setErrorMsg(error?.response?.data?.message);
          }
        } else if (error?.message) {
          setErrorMsg(error?.message);
        }
      }
    },
  });
  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid
        item
        md={5}
        sx={{
          backgroundColor: '#2F4050',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{ width: '80%' }}
          // src='https://i.imgur.com/N8MYRTP.png'
          src={deliveryMan}
          alt='delivery man deliver a parcel to a customer'
        />
        <div style={{ width: '80%' }}>
          <h1 style={{ color: 'white' }}>Welcome! A few click and access to dashboard</h1>
          <p style={{ color: 'white' }}>Mange all your system in one place</p>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center !important',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            maxWidth: '400px',
            maxHeight: '600px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
            <DropShipIcon />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Typography component='h1' variant='h6'>
              Sign In
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              id='mobile'
              label='Mobile'
              name='mobile'
              autoComplete='mobile'
              autoFocus
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value='remember' color='primary' />}
                  label='Remember me'
                />
              </Grid>
              <Grid item>
                <Link to='/password-reset' state={{ mobile: formik.values.mobile }}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2, width: '100px' }}
            >
              Login
            </Button>
            <CustomizedSnackBars
              message={errorMsg}
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              type='error'
            />
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
