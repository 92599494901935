/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const DownloadPdf = (props: { rootElementId: string; downloadFileName: any }) => {
  const { rootElementId, downloadFileName } = props;

  const downloadFileDocument = () => {
    // @ts-ignore
    const input: HTMLElement = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'cm', 'a4');
      pdf.addImage(imgData, 'JPEG', 0, 0, 21, 29.7);
      pdf.save(`${downloadFileName}`);
    });
  };
  return (
    <Box>
      <Button
        onClick={downloadFileDocument}
        variant='outlined'
        startIcon={<FileDownloadOutlinedIcon />}
        sx={{ padding: '5px 6px', marginRight: '1rem' }}
      >
        Download
      </Button>
    </Box>
  );
};
