/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { GeneralForm } from '../../../../../components/GeneralForm';
import { baseUrl } from '../../../../../utils/constants';
import { useAuth } from '../../../../../store/getValues';
import CustomizedSnackBars from '../../../../../components/SnackBarMui';

const formFields = [
  {
    id: 'channel',
    label: 'Channel',
    type: 'customSelectApi',
    options: [{ label: 'name', value: 'id' }],
    fullWidth: true,
    // textFieldType: 'number',
    url: `${baseUrl}/api/v1/shipment/admin/channel/`,
  },
  {
    id: 'delivery_man',
    label: 'Delivery Person',
    type: 'customSelectApi',
    options: [{ value: 'id', label: 'first_name' }],
    fullWidth: true,
    url: `${baseUrl}/api/v1/auth/user/`,
  },
  {
    id: 'clearance_company',
    label: 'Clearance Company',
    type: 'customSelectApi',
    options: [{ value: 'id', label: 'name' }],
    fullWidth: true,
    url: `${baseUrl}/api/v1/partners/mobile/clearance_company/`,
  },
  {
    id: 'delivery_company',
    label: 'Delivery Company',
    type: 'customSelectApi',
    options: [{ value: 'id', label: 'name' }],
    fullWidth: true,
    url: `${baseUrl}/api/v1/partners/mobile/delivery_company/`,
  },
  {
    id: 'status',
    label: 'Shipment Status',
    type: 'customSelect',
    options: [
      { value: 0, label: 'Created' },
      { value: 1, label: 'Received' },
      { value: 2, label: 'IN Transit' },
      { value: 3, label: 'Handed to delivery partner' },
      { value: 4, label: 'Waiting for clearance' },
      { value: 5, label: 'Arrived at destination warehouse' },
      { value: 6, label: 'Out for delivery' },
      { value: 7, label: 'Delivered' },
      { value: 8, label: 'No Response' },
      { value: 9, label: 'Returning' },
      { value: 10, label: 'Canceled' },
      { value: 11, label: 'Returned' },
    ],
    fullWidth: true,
  },
];

const validationSchema = yup.object({
  // shipment: yup.number().required('This Field is required'),
  channel: yup.number().required('This Field is required'),
  delivery_man: yup.string().nullable(),
  clearance_company: yup.string().nullable(),
  delivery_company: yup.string().nullable(),
  status: yup.string().required('This Field is required'),
});

type InitialValues = {
  shipment: string;
  clearance_company: string;
  delivery_company: string;
  delivery_man: 1;
  status: number;
  channel: string;
};

export const ShipmentDeliverAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state !== null;
  const { id } = location.state;
  const auth = useAuth();
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  // const [values, setValues] = useState<InitialValues>({
  //   shipment: '',
  //   clearance_company: '',
  //   delivery_company: '',
  //   delivery_man: '',
  //   status: 0,
  //   channel: '',
  // });

  const formData = useRef({
    validationSchema,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values: any) => {},
    initialValues: {
      shipment: '',
      clearance_company: '',
      delivery_company: '',
      delivery_man: 1,
      status: 0,
      channel: '',
    },
    formFields,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: { data: { data: InitialValues } } = await axios.get(
          `${baseUrl}/api/v1/shipment/admin/shipment/${id}/`,
          {
            headers: { Authorization: `Token ${auth.token}` },
          },
        );
        formData.current = {
          validationSchema,
          onSubmit: async (values: any) => {
            try {
              await axios.post(`${baseUrl}/api/v1/shipment/admin/shipment/${id}/deliver/`, values, {
                headers: {
                  Authorization: `Token ${auth.token}`,
                  'Content-Type': 'multipart/form-data',
                },
              });
              setOpen(true);
              setType('success');
              if (isEdit) {
                setMessage(`Successfully Edit item with id# ${location.state.id}`);
              } else {
                setMessage('Successfully created');
              }
              setTimeout(() => navigate(-1), 1000);
              // Navigate({ to: '/dashboard/partners/interchange_point/list' });
            } catch (error) {
              // @ts-ignore
              const errorObj = error?.response?.data?.errors || {};
              // @ts-ignore
              const errorMsg = error?.response?.data?.message;
              // @ts-ignore
              if (errorObj) {
                for (const property in errorObj) {
                  setOpen(true);
                  setType('error');
                  setMessage(`${property}: ${errorObj[property]}`);
                }
              } else if (errorMsg) {
                setOpen(true);
                setType('error');
                setMessage(errorMsg);
              } else {
                setOpen(true);
                setType('error');
                setMessage('Something went wrong');
              }
            }
          },
          initialValues: {
            channel: response.data.data.channel,
            shipment: response.data.data.shipment,
            clearance_company: response.data.data.clearance_company,
            delivery_company: response.data.data.delivery_company,
            delivery_man: response.data.data.delivery_man,
            status: response.data.data.status,
          },
          formFields,
        };

        setLoadingData(false);
      } catch (error) {
        /* empty */
      }
    };
    fetchData();

    return () => {
      // second;
    };
  }, []);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <CustomizedSnackBars type={type} message={message} open={open} setOpen={setOpen} />
        <Typography paragraph variant='h5' fontWeight='bold'>
          {isEdit ? 'Edit' : 'Add'} Deliver a Shipment
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / Shipment / <b>{isEdit ? 'Edit' : 'Add'} Deliver a Shipment</b>
          </Typography>
        </div>
      </div>
      {loadingData ? (
        <Box
          sx={{
            width: '100%',
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <GeneralForm formData={formData.current} disableLocationState />
      )}
    </div>
  );
};
