import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

const columns = [
  { id: 'id', label: '#', align: 'center' },
  { id: 'parent_company_name', label: 'Parent Company', align: 'left' },
  { id: 'name', label: 'Company Name', align: 'left' },
  { id: 'registration_no', label: 'Registration No', align: 'left' },
  // { id: 'logo', label: 'Logo', align: 'left' },
  { id: 'address', label: 'Address', align: 'left' },
  // { id: 'email', label: 'Email', align: 'left' },
  // { id: 'phone', label: 'Phone', align: 'left' },
  // { id: 'city', label: 'City', align: 'left' },
  // { id: 'state', label: 'State', align: 'left' },
  { id: 'country', label: 'Country', align: 'left', isCountry: true },
  // { id: 'zip_code', label: 'Zip Code', align: 'left' },
  { id: 'is_active', label: 'Is Active', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];
export const CustomersCompaniesList = () => (
  <DashboardTableList
    columns={columns}
    addUrl='/dashboard/customers/companies/add'
    getUrl={`${baseUrl}/api/v1/partners/mobile/customer_company/`}
    deleteUrl={`${baseUrl}/api/v1/partners/mobile/customer_company/`}
    category='Customers'
    name='Companies List'
    searchLabel='name'
    searchName='name'
  />
);
