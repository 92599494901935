import * as yup from 'yup';
import { DashboardForm } from '../../../../components/DashboardForm';
import { baseUrl } from '../../../../utils/constants';

const formFields = [
  { id: 'title', label: 'Headline', type: 'textField', options: [], fullWidth: true },
  {
    id: 'desc',
    label: 'Description',
    type: 'multiLine',
    options: [],
    fullWidth: true,
  },
  {
    id: 'thumbnail',
    label: 'Thumbnail',
    type: 'imageUpload',
    options: [],
    fullWidth: true,
    url: '',
  },

  {
    id: 'page_type',
    label: 'Page Type',
    type: 'radioButton',
    options: [
      { value: 0, label: 'Privacy Policy' },
      { value: 1, label: 'Terms and Condition' },
      { value: 2, label: 'About Us' },
      { value: 3, label: 'General' },
      { value: 4, label: 'Return Policy' },
    ],
    fullWidth: true,
  },
];

const validationSchema = yup.object({
  title: yup.string().required('This field is required'),
  desc: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'multiLine':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    case 'imageUpload':
      initialValues[id] = '';

      break;
    default:
      break;
  }
});

export const TermsAndPoliciesAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category=''
    name='Privacy and Policy'
    validationSchema={validationSchema}
    listUrl='/dashboard/terms_&_policies/list'
    postUrl={`${baseUrl}/api/v1/utility/admin/page/`}
    patchUrl={`${baseUrl}/api/v1/utility/admin/page/`}
  />
);
