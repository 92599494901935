/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import axios from 'axios';
import GoogleMapCustom from '../GoogleMap/GoogleMap';
import { FillAddressData } from './FillAddressData';
import { useCountries } from '../../store/getValues';

export const MapFormik = (props: { id: any; formik: any; options?: any[] }) => {
  const { id, formik, options } = props;
  const countriesData = useCountries();

  const handleSelectLocation = async (latitude: number, longitude: number) => {
    formik.setFieldValue(`${id}latitude`, latitude);
    formik.setFieldValue(`${id}longitude`, longitude);
    if (options && options[0].value === 'editOtherFields') {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`,
      );
      const formattedAddress = response.data.results[0].formatted_address;
      if (formattedAddress) {
        formik.setFieldValue(`${id}address`, formattedAddress);
      }
      const mainLocation = response.data.results[0];
      FillAddressData(formik, id, mainLocation, countriesData);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '40vh',
        }}
      >
        <GoogleMapCustom
          markerLng={formik.values[`${id}longitude`]}
          markerLat={formik.values[`${id}latitude`]}
          onClick={handleSelectLocation}
          zoom={9}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '49%' }}>
          <InputLabel sx={{ color: 'black' }} htmlFor={`${id}latitude`}>
            Latitude
          </InputLabel>
          <TextField
            type='number'
            fullWidth
            id={`${id}latitude`}
            name={`${id}latitude`}
            placeholder='Type Latitude'
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={formik.values[`${id}latitude`]}
            onChange={formik.handleChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={formik.touched[`${id}latitude`] && Boolean(formik.errors[`${id}latitude`])}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            helperText={formik.touched[`${id}latitude`] && formik.errors[`${id}latitude`]}
            disabled
          />
        </Box>
        <Box sx={{ width: '49%' }}>
          <InputLabel sx={{ color: 'black' }} htmlFor={`${id}longitude`}>
            Longitude
          </InputLabel>
          <TextField
            type='number'
            fullWidth
            id={`${id}longitude`}
            name={`${id}longitude`}
            placeholder='Type Longitude'
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={formik.values[`${id}longitude`]}
            onChange={formik.handleChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={formik.touched[`${id}longitude`] && Boolean(formik.errors[`${id}longitude`])}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            helperText={formik.touched[`${id}longitude`] && formik.errors[`${id}longitude`]}
            disabled
          />
        </Box>
      </Box>
    </>
  );
};
