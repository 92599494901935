import Box from '@mui/material/Box';
import pageNoteFoundImage from '../../assets/icons/404.jpg';

const ErrorPage = () => (
  <Box
    sx={{
      width: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'white',
      margin: '1rem auto',
      mt: '4rem',
      borderRadius: '15px',
      padding: '2rem',
    }}
  >
    <img src={pageNoteFoundImage} alt='404' style={{ maxWidth: '90%' }} />
    <p style={{ textAlign: 'center' }}>Sorry, an unexpected error has occurred.</p>
    <p>
      <i>404 page not found</i>
    </p>
  </Box>
);

export default ErrorPage;
