import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import { Route } from './types';

interface Props {
  data: Route;
  expanded: string | false;
  handleChange: any;
  selectedItemProps: string;
  onClick: any;
  selectedSubTitle: string;
}

export const AccordingMui: React.FC<Props> = (props) => {
  const { data, expanded, handleChange, selectedItemProps, onClick, selectedSubTitle } = props;
  const [selectedItem, setSelectedItem] = React.useState<string>('');

  const handleClickOnTitle = () => {
    if (selectedItemProps !== data.route.split('/')[2]) {
      setSelectedItem(data.children[0].route.split('/')[3]);
      onClick();
    }
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    setSelectedItem(selectedSubTitle);

    return () => {};
  }, [selectedSubTitle]);

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        border: 'none',
        marginBottom: '0',
        borderRadius: '0',
        borderLeft: selectedItemProps === data.route.split('/')[2] ? '5px solid #236F7D' : '',
        backgroundColor: expanded === data.route.split('/')[2] ? '#293846' : '#2F4050',
      }}
      expanded={expanded === data.route.split('/')[2]}
      onChange={handleChange(data.route.split('/')[2])}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: expanded === data.route.split('/')[2] ? 'white ' : '#A7B1C2' }}
          />
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
        onClick={handleClickOnTitle}
      >
        <ListItemIcon
          sx={{ color: selectedItemProps === data.route.split('/')[2] ? 'white ' : '#A7B1C2' }}
        >
          {data.icon}
        </ListItemIcon>
        <Typography
          sx={{ color: selectedItemProps === data.route.split('/')[2] ? 'white ' : '#A7B1C2' }}
        >
          {data.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.children.map((elem: Route) => (
          <ListItem key={elem.name} disablePadding>
            <ListItemButton
              onClick={() => {
                setSelectedItem(elem.route.split('/')[3]);
                navigate(elem.route);
              }}
            >
              <ListItemText
                primary={elem.name}
                sx={{
                  color: selectedItem === elem.route.split('/')[3] ? 'white ' : '#A7B1C2',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
