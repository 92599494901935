import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import Box from '@mui/material/Box';
import barcodeImg from '../../assets/icons/barcode.png';

export const BarCodeFieldFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
  textFieldType?: string;
}) => {
  const { id, label, fullWidth, placeholder, formik, textFieldType } = props;

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Box sx={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
          type={textFieldType || 'text'}
          fullWidth={fullWidth}
          id={id}
          name={id}
          placeholder={placeholder || `Type ${label} `}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={formik.values[id]}
          onChange={formik.handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={formik.touched[id] && Boolean(formik.errors[id])}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          helperText={formik.touched[id] && formik.errors[id]}
        />
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'unset' } }}>
          <img src={barcodeImg} alt='barcode' style={{ width: '100px' }} />
        </Box>
      </Box>
    </React.Fragment>
  );
};
