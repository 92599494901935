import SvgIcon from '@mui/material/SvgIcon';

function AddNewUser(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id='person_add'
        xmlns='http://www.w3.org/2000/svg'
        width='23.365'
        height='23.365'
        viewBox='0 0 23.365 23.365'
      >
        <defs>
          <clipPath id='clip-path'>
            <path
              id='Mask'
              d='M23.365,23.365H6.373v-2.92c0-3.836,5.532-5.842,8.5-5.842s8.5,2.006,8.5,5.842v2.919Zm-18.055-7.3H3.186V11.683H0V8.762H3.186V4.381H5.311V8.762H8.5v2.92H5.311v4.379Zm9.559-4.381c-2.343,0-4.249-2.621-4.249-5.842S12.527,0,14.87,0s4.248,2.62,4.248,5.841S17.212,11.683,14.87,11.683Z'
              fill='none'
            />
          </clipPath>
          <clipPath id='clip-path-2'>
            <rect id='White' width='53.104' height='73.016' fill='#fff' />
          </clipPath>
        </defs>
        <g id='Group_24' data-name='Group 24'>
          <path
            id='Mask-2'
            data-name='Mask'
            d='M23.365,23.365H6.373v-2.92c0-3.836,5.532-5.842,8.5-5.842s8.5,2.006,8.5,5.842v2.919Zm-18.055-7.3H3.186V11.683H0V8.762H3.186V4.381H5.311V8.762H8.5v2.92H5.311v4.379Zm9.559-4.381c-2.343,0-4.249-2.621-4.249-5.842S12.527,0,14.87,0s4.248,2.62,4.248,5.841S17.212,11.683,14.87,11.683Z'
            fill='none'
          />
          <g id='Mask_Group_24' data-name='Mask Group 24' clipPath='url(#clip-path)'>
            <g id='White-2' data-name='White' transform='translate(-14.869 -24.825)'>
              <g id='Group_6' data-name='Group 6'>
                <rect id='White-3' data-name='White' width='53.104' height='73.016' fill='#fff' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default AddNewUser;
