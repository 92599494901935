import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { GeneralForm } from '../../../components/GeneralForm';
import CustomizedSnackBars from '../../../components/SnackBarMui';
import { baseUrl } from '../../../utils/constants';
import { useAuth } from '../../../store/getValues';

const formFields = [
  {
    id: 'old_password',
    label: 'Old Password',
    type: 'textField',
    options: [],
    fullWidth: true,
    textFieldType: 'password',
  },
  {
    id: 'password',
    label: 'New Password',
    type: 'textField',
    options: [],
    fullWidth: true,
    textFieldType: 'password',
  },
  {
    id: 'confirm_password',
    label: 'Confirm New Password',
    type: 'textField',
    options: [],
    fullWidth: true,
    textFieldType: 'password',
  },
];

const validationSchema = yup.object({
  old_password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('This field is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('This field is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
      initialValues[id] = '';
      break;

    default:
      break;
  }
});

export const ChangePassword = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const formData = {
    validationSchema,
    onSubmit: async (values: {
      password: string;
      old_password: string;
      confirm_password: string;
    }) => {
      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(`${baseUrl}/api/v1/auth/change/password/`, values, {
          headers: { Authorization: `Token ${auth.token}` },
        });

        navigate('/dashboard/quick_access');
      } catch (error: any) {
        setOpenSnackBar(true);
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.mobile) {
            setErrorMsg(error?.response?.data?.errors?.mobile);
          }
          if (error?.response?.data?.message) {
            setErrorMsg(error?.response?.data?.message);
          }
        }
      }
    },
    initialValues,
    formFields,
  };
  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          Change Password
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / <b>Change Password</b>
          </Typography>
        </div>
      </div>
      <GeneralForm formData={formData} />
      <CustomizedSnackBars
        message={errorMsg}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type='error'
      />
    </div>
  );
};
