import SvgIcon from '@mui/material/SvgIcon';

function PartnersIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20.25'
        height='19.637'
        viewBox='0 0 20.25 19.637'
      >
        <g id='exchanging-svgrepo-com' transform='translate(-10.849 -18.25)' opacity='0.46'>
          <path
            id='Path_8230'
            data-name='Path 8230'
            d='M187.3,23.533a.5.5,0,0,0,.5.5h4.538a.5.5,0,0,0,.5-.5V21.76h6.125V34.466H196.1l1.253-1.253a.493.493,0,0,0-.7-.7l-2.1,2.1a.5.5,0,0,0,0,.7l2.1,2.1a.494.494,0,0,0,.7-.7l-1.253-1.253h3.349a.5.5,0,0,0,.5-.5v-13.7a.5.5,0,0,0-.5-.5h-6.625V19a.5.5,0,0,0-.5-.5H187.8a.5.5,0,0,0-.5.5v4.538Zm.987-4.042h3.55v3.55h-3.55Z'
            transform='translate(-169.101)'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.5'
          />
          <path
            id='Path_8231'
            data-name='Path 8231'
            d='M187.4,187.9v4.538a.5.5,0,0,0,.5.5h4.538a.5.5,0,0,0,.5-.5V187.9a.5.5,0,0,0-.5-.5h-4.542A.5.5,0,0,0,187.4,187.9Zm.987.5h3.55v3.546h-3.55Z'
            transform='translate(-169.197 -162.094)'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.5'
          />
          <path
            id='Path_8232'
            data-name='Path 8232'
            d='M11.59,37.576H18.2v1.773a.5.5,0,0,0,.5.5h4.538a.5.5,0,0,0,.5-.5V34.816a.5.5,0,0,0-.5-.5H18.695a.5.5,0,0,0-.5.5v1.773H12.082V23.883h2.849l-1.253,1.253a.494.494,0,0,0,.7.7l2.1-2.1a.491.491,0,0,0,0-.7l-2.1-2.1a.493.493,0,0,0-.7.7L14.935,22.9H11.59a.492.492,0,0,0-.492.5V37.084A.488.488,0,0,0,11.59,37.576Zm7.6-2.269h3.55v3.55H19.19Z'
            transform='translate(0 -2.207)'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.5'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default PartnersIcon;
