import * as yup from 'yup';

import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

const formFields = [
  {
    id: 'parent_company',
    label: 'Parent Company',
    type: 'customSelectApi',
    options: [{ label: 'name', value: 'id' }],
    fullWidth: true,
    url: `${baseUrl}/api/v1/partners/mobile/customer_company/`,
  },
  {
    id: 'name',
    label: 'Company Name',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'logo', label: 'Logo', type: 'imageUpload', options: [], fullWidth: true },
  { id: 'address', label: 'Address', type: 'textField', options: [], fullWidth: true },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'phone', label: 'Phone', type: 'textField', options: [], fullWidth: true },
  { id: 'city', label: 'City', type: 'textField', options: [], fullWidth: true },
  { id: 'state', label: 'State', type: 'textField', options: [], fullWidth: true },
  { id: 'country', label: 'Country', type: 'countrySelect', options: [], fullWidth: true },
  { id: 'zip_code', label: 'Zip Code', type: 'textField', options: [], fullWidth: true },
  {
    id: 'registration_no',
    label: 'Registration No',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'isActive', label: 'Is Active', type: 'checkBox', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  parent_company: yup.string().nullable(true),
  name: yup.string().required('Company Name is required'),
  address: yup.string().required('Address is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  zip_code: yup.string().required('Zip Code is required'),
  registration_no: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    case 'customSelectApi':
      initialValues[id] = null;
      break;
    case 'masterPricing':
      initialValues.below_5 = '';
      initialValues.five_to_10 = '';
      initialValues.eleven_to_20 = '';
      initialValues.twenty_one_to_50 = '';
      initialValues.fifty_one_to_100 = '';
      initialValues.above_hundred = '';
      break;
    default:
      break;
  }
});

export const CustomersCompanyAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Customer'
    name='Company'
    validationSchema={validationSchema}
    listUrl='/dashboard/customers/companies/list'
    postUrl={`${baseUrl}/api/v1/partners/mobile/customer_company/`}
    patchUrl={`${baseUrl}/api/v1/partners/mobile/customer_company/`}
  />
);
