import { DashboardTableList } from '../../../../components/DashboardTableList';
import { baseUrl } from '../../../../utils/constants';
import { TableColumnInterface } from '../../data';

const columns: TableColumnInterface[] = [
  { id: 'id', label: '#', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'origin_country', label: 'Origin Country', align: 'left', isCountry: true },
  { id: 'destination_country', label: 'Destination Country', align: 'left', isCountry: true },
  { id: 'service_type', label: 'Service Type', align: 'left', isServiceType: true },
  { id: 'is_active', label: 'Is Active', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

export const ChannelList = () => (
  <DashboardTableList
    columns={columns}
    addUrl='/dashboard/channels/channels/add'
    getUrl={`${baseUrl}/api/v1/shipment/admin/channel/`}
    deleteUrl={`${baseUrl}/api/v1/shipment/admin/channel/`}
    category='Channels'
    name='Channel List'
    searchLabel='name'
    searchName='name'
  />
);
