import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAuth, useCountries } from '../../store/getValues';
import { baseUrl } from '../../utils/constants';
import { setCountries } from '../../store/actions/countries';
import { Country } from '../../store/reducers/countries';

export const CountrySelectFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
}) => {
  const { id, label, fullWidth, placeholder, formik } = props;
  const auth = useAuth();

  const [countriesState, setCountriesState] = useState(useCountries());
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(`${baseUrl}/api/v1/auth/country/?limit=500&offset=0`, {
        headers: { Authorization: `Token ${auth.token}` },
      });
      setCountriesState(response.data.data.results);
      dispatch(setCountries(response.data.data.results));
    };
    if (countriesState.length === 0) {
      fetchCountries();
    }

    return () => {
      // cancel request
    };
  }, []);

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        id={id}
        name={id}
        placeholder={placeholder || `Select ${label} `}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={formik.values[id]}
        onChange={formik.handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        helperText={formik.touched[id] && formik.errors[id]}
        displayEmpty
        renderValue={formik.values[id] ? undefined : () => 'Select Country'}
        // renderValue={(selected) => {
        //   if (selected.length === 0) {
        //     return <em>Placeholder</em>;
        //   }

        //   return selected.join(', ');
        // }}
      >
        {/* <MenuItem disabled value='' selected>
          <em>Placeholder</em>
        </MenuItem> */}
        {countriesState.length > 0 &&
          countriesState.map((item: Country) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </React.Fragment>
  );
};
