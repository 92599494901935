import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

export const ShipmentShipmentList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'customer_name', label: 'User', align: 'left' },
      { id: 'awb', label: 'HAWB No', align: 'left' },
      // { id: 'category', label: 'Category', align: 'left' },
      // { id: 'weight', label: 'Weight', align: 'left' },
      { id: 'billing_weight', label: 'Billing Weight', align: 'left' },
      // { id: 'itemQty', label: 'Item Qty', align: 'left' },
      { id: 'cost_per_kg', label: 'Cost per Kg', align: 'left' },
      { id: 'vat_amount', label: 'VAt', align: 'left' },
      { id: 'total', label: 'Total Amt', align: 'left' },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    // addUrl='/dashboard/shipment/shipment/add'
    getUrl={`${baseUrl}/api/v1/shipment/admin/shipment/`}
    deleteUrl={`${baseUrl}/api/v1/shipment/admin/shipment/`}
    category='Shipment'
    name='Shipment List'
    searchLabel='Shipment by AWB'
    searchName='awb'
  />
);
