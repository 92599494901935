import * as yup from 'yup';

import { DashboardForm } from '../../../../components/DashboardForm';
import { baseUrl } from '../../../../utils/constants';

const formFields = [
  { id: 'name', label: 'Name', type: 'textField', options: [], fullWidth: true },
  {
    id: 'origin_country',
    label: 'Origin Country',
    type: 'countrySelect',
    options: [],
    fullWidth: true,
  },
  {
    id: 'destination_country',
    label: 'Destination Country',
    type: 'countrySelect',
    options: [],
    fullWidth: true,
  },
  {
    id: 'service_type',
    label: 'Service Type',
    type: 'radioButton',
    options: [
      { value: 0, label: 'Standard' },
      { value: 1, label: 'Express' },
    ],
    fullWidth: true,
  },
  {
    id: 'is_active',
    label: 'Is Active',
    type: 'checkBox',
    options: [],
    fullWidth: false,
    isServiceType: true,
  },
];

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  origin_country: yup.string().required('Origin Country is required'),
  destination_country: yup.string().required('Destination Country is required'),
  service_type: yup.string().required('Service Type is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }, index: number) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    case 'radioButton':
      // eslint-disable-next-line prefer-destructuring
      initialValues[id] = formFields[index].options[0].value || '';
      break;
    default:
      break;
  }
});

export const ChannelAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Channels'
    name='Channel'
    validationSchema={validationSchema}
    listUrl='/dashboard/channels/channels/list'
    postUrl={`${baseUrl}/api/v1/shipment/admin/channel/`}
    patchUrl={`${baseUrl}/api/v1/shipment/admin/channel/`}
  />
);
