import * as yup from 'yup';

import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

const formFields = [
  { id: 'name', label: 'Name', type: 'textField', options: [], fullWidth: true },
  {
    id: 'manager',
    label: 'Manager',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'phone', label: 'Phone', type: 'textField', options: [], fullWidth: true },
  { id: 'address', label: 'Address', type: 'textField', options: [], fullWidth: true },
  { id: '', label: 'Map', type: 'mapField', options: [], fullWidth: true },
  { id: 'city', label: 'City', type: 'textField', options: [], fullWidth: true },
  { id: 'state', label: 'State', type: 'textField', options: [], fullWidth: true },
  { id: 'country', label: 'Country', type: 'countrySelect', options: [], fullWidth: true },
  { id: 'zip_code', label: 'Zip Code', type: 'textField', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  name: yup.string().required('This field is required'),
  manager: yup.string().required('This field is required'),
  email: yup.string().required('This field is required'),
  phone: yup.string().required('This field is required'),
  address: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  country: yup.string().required('This field is required'),
  zip_code: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'mapField':
      initialValues.lat = 3.1191;
      initialValues.lng = 101.65;
      break;
    default:
      break;
  }
});

export const WarehouseAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Administration'
    name='Warehouse'
    validationSchema={validationSchema}
    listUrl='/dashboard/administration/warehouses/list'
    postUrl={`${baseUrl}/api/v1/shipment/admin/warehouse/`}
    patchUrl={`${baseUrl}/api/v1/shipment/admin/warehouse/`}
  />
);
