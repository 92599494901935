import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { baseUrl } from '../../../../utils/constants';
import { SelectFieldForShipment } from '../SelectFieldForShipment';

const validationSchema = yup.object({
  awb: yup.string().required('This Field is required'),
});

const initialValues = { awb: '' };

export const ShipmentTrackSelect = () => {
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    navigate('/dashboard/shipment/track/view', {
      state: values,
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <SelectFieldForShipment
      formik={formik}
      title='Shipment Tracking'
      category='Shipment'
      formFields={[
        {
          id: 'awb',
          label: 'Shipment AWB',
          url: `${baseUrl}/api/v1/shipment/admin/shipment/`,
          type: 'customSelectApi',
          fullWidth: true,
          options: [{ label: 'awb', value: 'awb' }],
          textFieldType: 'awb',
        },
      ]}
    />
  );
};
