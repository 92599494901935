/* eslint-disable @typescript-eslint/ban-ts-comment */
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
// import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../store/getValues';
import { Country } from '../../store/reducers/countries';

export const SelectWithSearchFromApiFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
  url?: string;
  fieldType?: string;
  options: { label: string; value: string }[];
}) => {
  const { id, label, fullWidth, placeholder, formik, url, fieldType, options } = props;
  const auth = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [optionValues, setOptionValues] = useState([]);
  //   const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (typeof url === 'string') {
        const response = await axios.get(url, {
          headers: { Authorization: `Token ${auth.token}` },
        });
        setOptionValues(
          response.data.data.results.map((item: Country) => ({
            // @ts-ignore
            label: typeof fieldType !== 'undefined' ? item[fieldType] : item.name,
            // @ts-ignore
            formikValue: item[options[0].value],
            // @ts-ignore
            value: typeof fieldType !== 'undefined' ? item[fieldType] : item.name,
          })),
        );
      }
    };
    if (optionValues.length === 0) {
      fetchData();
    }

    return () => {
      // cancel request
    };
  }, [auth.token, url]);

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      {
        <Autocomplete
          fullWidth={fullWidth}
          id={id}
          // name={id}
          placeholder={placeholder || `Type ${label} `}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={inputValue}
          // @ts-ignore
          onChange={(
            event: any,
            newValue: { label: string; value: string; formikValue: string },
          ) => {
            setInputValue(newValue.label);
            formik.setFieldValue(id, newValue.formikValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={optionValues}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={formik.touched[id] && Boolean(formik.errors[id])}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          helperText={formik.touched[id] && formik.errors[id]}
        />
      }
      {/* {state.length > 0 &&
          state.map((item: Country) => (
            <MenuItem value={item.id} key={item.id}>
              {
                // @ts-ignore
                typeof fieldType !== 'undefined' ? item[fieldType] : item.name
              }
            </MenuItem>
          ))} */}
      {/* </Autocomplete> */}
    </React.Fragment>
  );
};
