/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Link from '@mui/material/Link';

import './index.css';
import TicketTable from './TicketTable';
import CustomizedSnackBars from '../../../../components/SnackBarMui';
import { baseUrl } from '../../../../utils/constants';
import { useAuth } from '../../../../store/getValues';
// import { GeneralForm } from '../../../../components/GeneralForm';

// const data = {
//   status: 'open',
//   id: '#MC00001339',
//   text: 'Need update on the consignment heading to New York with Consignment ID EXCR',
//   info: [
//     {
//       requestor: 'Ali Murad',
//       userType: 'AUTHORIZED USER',
//       department: 'Support',
//       submitted: 'Saturday, January 29th,2022(14:52)',
//       lastUpdated: '10 Min Ago',
//       status: 'open',
//       priority: 'Medium',
//     },
//   ],
//   thread: [
//     {
//       sender: 'system',
//       senderType: 'OPERATOR',
//       msg: 'Can you please check if everything is ok or not',
//       attachment: '0',
//     },
//     {
//       sender: 'Ali Murad',
//       senderType: 'AUTHORIZED USER',
//       attachment: '0',
//       msg: 'Hello I am totally disappointed about your service. I do not understand why my parcel was delay about due time.Please check and fixed this issue urgently. ',
//     },
//   ],
// };
interface DataApi {
  body: string;
  attachment: [{ id: number; owner: number; doc_url: string }];
  created_at: string;
  id: number;
  priority: number;
  status: number;
  subject: string;
  ticket_id: string;
  updated_at: string;
  user: { first_name: string; last_name: string; is_staff: true };
  replies: [
    {
      attachment: number;
      attachment_url: string;
      create_at: string;
      email: string;
      id: number;
      message: string;
      name: string;
      ticket: number;
      updated_at: string;
      user: { first_name: string; last_name: string; is_staff: true };
    },
  ];
}
export const ViewTicket = () => {
  const location = useLocation();
  const { id } = location.state || { id: '' };
  const auth = useAuth();
  const [dataApi, setData] = React.useState<DataApi | undefined>(undefined);
  const [replies, setReplies] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/v1/support/admin/ticket/${id}/`, {
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setData(response.data.data);
        setReplies(response.data.data.replies);
      } catch (error) {
        /* empty */
      }
    };
    fetchData();
    return () => {};
  }, []);

  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  const [open, setOpen] = useState(false);

  let initialValues = { name: '', email: '', message: '', attachment: '' };

  const validationSchema = yup.object({
    email: yup.string().required('This field is required'),
    name: yup.string().required('This field is required'),
    message: yup.string().required('This field is required'),
    attachment: yup.mixed(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        await axios.post(`${baseUrl}/api/v1/support/admin/ticket/${id}/reply/`, values, {
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setType('success');
        setMessage('Reply Sent');
        setExpanded(false);
        setOpen(true);
        initialValues = { name: '', email: '', message: '', attachment: '' };
        const response = await axios.get(`${baseUrl}/api/v1/support/admin/ticket/${id}/`, {
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        // setData(response.data);
        setReplies(response.data.data.replies);
        formik.resetForm();
      } catch (error) {
        // @ts-ignore
        const errorObj = error?.response?.data?.errors || {};
        // @ts-ignore
        const errorMsg = error?.response?.data?.message;
        // @ts-ignore
        if (errorObj) {
          for (const property in errorObj) {
            setOpen(true);
            setType('error');
            setMessage(`${property}: ${errorObj[property]}`);
          }
        } else if (errorMsg) {
          setOpen(true);
          setType('error');
          setMessage(errorMsg);
        } else {
          setOpen(true);
          setType('error');
          setMessage('Something went wrong');
        }
      }
    },
  });

  const handleUploadFile = async (e: { target: { files: any } }) => {
    const currentFile = e.target.files[0];
    let doc_type = 0;
    if (e.target.files[0]) {
      const fileType = currentFile.type.split('/')[0];
      switch (fileType) {
        case 'image':
          doc_type = 0;
          break;
        case 'video':
          doc_type = 1;
          break;
        case 'text':
        case 'application/pdf':
          doc_type = 2;
          break;
        default:
          doc_type = 3;
          break;
      }
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/auth/documents/upload/`,
        { document: e.target.files[0], doc_type },
        {
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      // formik.setFieldValue(id, [...formik.values[id], response.data.data.id]);
      formik.setFieldValue('attachment', response.data.data.id);
      setType('success');
      setMessage('File uploaded');
      // setExpanded(false);
      setOpen(true);
    } catch (error) {
      /* empty */
    }
  };
  return (
    <div>
      {dataApi && (
        <div>
          <CustomizedSnackBars message={message} open={open} setOpen={setOpen} type={type} />
          <div className='dashboard-page-main-container'>
            <div className='dashboard-page-title'>
              <Typography paragraph variant='h5' fontWeight='bold'>
                View Tickets
              </Typography>
              <div className='dashboard-page-nav dashboard-page-nav-with-add'>
                <Typography paragraph variant='body2'>
                  Dashboard / Support / <b>Open Tickets</b>
                </Typography>

                <Button
                  variant='contained'
                  startIcon={<FileDownloadOutlinedIcon />}
                  sx={{ padding: '5px 25px', marginRight: '1rem' }}
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
            {/* Header  */}
            <Box
              sx={{
                margin: '1rem 2rem',
                backgroundColor: 'white',
                pt: '1rem',
                pb: '1rem',
                display: 'flex',
                gap: '2rem',
                borderRadius: '15px',
                boxShadow: '0 0 6px -2px grey',
              }}
              className='quick-access-tables'
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: { xs: 'center', sm: 'center', md: 'unset' },
                  width: { xs: '100%', sm: '100%', md: 'unset' },
                }}
              >
                <Typography sx={{ color: 'gray', fontSize: '14px', textAlign: 'center' }}>
                  {`# ${dataApi.ticket_id}`}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    color: 'white',
                    backgroundColor: '#0F146D',
                    width: '100%',
                    textAlign: 'center',
                    padding: '.5rem 4rem',
                  }}
                >
                  {dataApi.status === 0 ? 'open' : 'close'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyItems: 'center',
                  height: '100%',
                  gap: '0.5rem',
                }}
              >
                <Typography variant='h6'>{dataApi.subject}</Typography>
                <Typography>{dataApi.body}</Typography>
              </Box>
            </Box>
            {/* ticket information */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
                margin: '1rem 2rem',
              }}
            >
              <Typography variant='h6'>Ticket Information</Typography>
              <Button
                variant='contained'
                sx={{ padding: '5px 25px' }}
                onClick={() => setExpanded(true)}
                className='no-print'
              >
                Reply
              </Button>
            </Box>
            <TicketTable
              rows={[
                {
                  requestor: `${dataApi.user.first_name} ${dataApi.user.last_name}`,
                  userType: dataApi.user.is_staff === true ? 'staff' : 'user',
                  department: 'none',
                  submitted: dataApi.created_at.split('T')[0],
                  lastUpdated: dataApi.updated_at.split('T')[0],
                  status: dataApi.status === 0 ? 'Open' : 'Close',
                  priority:
                    dataApi.priority === 0 ? 'Low' : dataApi.priority == 1 ? 'Medium' : 'High',
                },
              ]}
            />
            {dataApi.status === 0 && (
              <Box
                className='no-print'
                sx={{
                  margin: '1rem 2rem',
                  backgroundColor: '#D6E4FF',
                  padding: '1.5rem 1rem',
                  display: 'flex',
                  gap: '2rem',
                  borderRadius: '15px',
                  boxShadow: '0 0 6px -2px #D6E4FF',
                  color: '#0088FF',
                }}
              >
                This ticket is open. You may reply to this ticket
              </Box>
            )}
            {dataApi.attachment.length > 0 && (
              <Box
                sx={{
                  margin: '1rem 2rem',
                  backgroundColor: 'white',
                  pt: '1rem',
                  pb: '1rem',
                  pl: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  borderRadius: '15px',
                  boxShadow: '0 0 6px -2px grey',
                }}
              >
                <Typography>Attachments</Typography>
                {dataApi.attachment.map((item: { id: number; owner: number; doc_url: string }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                      alignItems: 'center',
                    }}
                  >
                    <FileDownloadOutlinedIcon />
                    <Link href={item.doc_url} download target='_blank' rel='noreferrer'>
                      {item.doc_url.split('/').splice(-2)}
                    </Link>
                  </Box>
                ))}
              </Box>
            )}
            {/* reply */}
            <Box sx={{ borderRadius: '10px', margin: '1rem 2rem' }} className='no-print'>
              <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  sx={{ borderRadius: '10px' }}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <EditIcon />
                    <Typography>Reply</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <div style={{ borderTop: '1px solid gray', paddingTop: '1rem' }}>
                    <GeneralForm
                      formData={{ validationSchema, onSubmit, initialValues, formFields }}
                    /> */}
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ borderTop: '1px solid gray', paddingTop: '1rem' }}
                  >
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <InputLabel sx={{ color: 'black' }} htmlFor='name'>
                          Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id='name'
                          name='name'
                          placeholder=''
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <InputLabel sx={{ color: 'black' }} htmlFor='email'>
                          Email
                        </InputLabel>
                        <TextField
                          fullWidth
                          id='email'
                          name='email'
                          type='email'
                          placeholder=''
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Box>
                    </Box>
                    <br />
                    <InputLabel sx={{ color: 'black' }} htmlFor='message'>
                      Message
                    </InputLabel>
                    <TextField
                      sx={{ mb: '1rem' }}
                      fullWidth
                      id='message'
                      name='message'
                      multiline
                      rows={4}
                      placeholder=''
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      helperText={formik.touched.message && formik.errors.message}
                    />
                    <br />
                    <Box>
                      <label className='ticket-upload-button' htmlFor='file-upload'>
                        Attachment:
                      </label>
                      <input
                        accept='image/*'
                        id='file-upload'
                        multiple
                        type='file'
                        onChange={handleUploadFile}
                      />
                    </Box>
                    <br />
                    <Button
                      type='submit'
                      variant='contained'
                      size='large'
                      sx={{ marginRight: '2rem' }}
                    >
                      Submit
                    </Button>
                    <Button variant='outlined' size='large' onClick={() => setExpanded(false)}>
                      Cancel
                    </Button>
                  </form>
                  {/* </div> */}
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* thread */}
            {replies &&
              replies?.length > 0 &&
              replies?.map((reply: any) => (
                <Box sx={{ margin: '2rem', backgroundColor: 'white', padding: '1rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      borderBottom: '1px solid #F2F2F2',
                      pb: '5px',
                    }}
                  >
                    <img src='https://imgur.com/n0cxdqj.png' alt='icon' />
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    >
                      <Typography variant='h6'>{`${reply.user.first_name} ${reply.user.last_name}`}</Typography>
                      <Typography> {reply.user.is_staff ? 'staff' : 'user'}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ p: '1rem', borderBottom: '1px solid #F2F2F2' }}>{reply.message}</Box>
                  {
                    reply.attachment > 0 && (
                      <Box
                        sx={{
                          pt: '1rem',
                          // pb: '1rem',
                          pl: '1rem',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                          // borderRadius: '15px',
                          // boxShadow: '0 0 6px -2px grey',
                        }}
                      >
                        <Typography>Attachments</Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '0.5rem',
                            alignItems: 'center',
                          }}
                        >
                          <FileDownloadOutlinedIcon />
                          <Link
                            href={reply.attachment_url}
                            download
                            target='_blank'
                            rel='noreferrer'
                          >
                            {reply.attachment_url.split('/').splice(-2)}
                          </Link>
                        </Box>
                      </Box>
                    )
                    // (
                    //   <Box
                    //     sx={{
                    //       p: '1rem',
                    //       borderBottom: '1px solid #F2F2F2',
                    //       display: 'flex',
                    //       flexDirection: 'row',
                    //       gap: '0.5rem',
                    //       alignItems: 'center',
                    //     }}
                    //   >
                    //     Attachments ({reply.attachment > 0 ? reply.attachment : 0})
                    //   </Box>
                    // )
                  }
                </Box>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
