/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Typography from '@mui/material/Typography';
// import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { useState, useEffect } from 'react';

import './index.css';
import { useAuth } from '../../store/getValues';
import { GeneralForm } from '../GeneralForm';
import CustomizedSnackBars from '../SnackBarMui';

export const DashboardForm = (props: {
  formFields: any;
  validationSchema: any;
  initialValues: any;
  patchUrl: string;
  postUrl: string;
  name: string;
  category: string;
  listUrl: string;
  getUrl?: string;
  disableLocationState?: boolean;
}) => {
  const {
    formFields,
    validationSchema,
    initialValues,
    patchUrl,
    postUrl,
    name,
    category,
    listUrl,
    getUrl,
    disableLocationState,
  } = props;

  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  const auth: any = useAuth();
  const location = useLocation();
  const isEdit = location.state !== null;
  const { id } = location?.state || -1;
  const [valuesState, setValuesState] = useState(null);
  const formData = {
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        if (isEdit) {
          await axios.patch(`${patchUrl}${location.state.id}/`, values, {
            headers: {
              Authorization: `Token ${auth.token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await axios.post(postUrl, values, {
            headers: {
              Authorization: `Token ${auth.token}`,
              // 'Content-Type': 'multipart/form-data',
            },
          });
        }
        setOpen(true);
        setType('success');
        if (isEdit) {
          setMessage(`Successfully Edit item with id# ${location.state.id}`);
        } else {
          setMessage('Successfully created');
        }
        setTimeout(() => navigate(listUrl), 1000);
        // Navigate({ to: '/dashboard/partners/interchange_point/list' });
      } catch (error) {
        // @ts-ignore
        const errorObj = error?.response?.data?.errors || {};
        // @ts-ignore
        const errorMsg = error?.response?.data?.message;
        // @ts-ignore
        if (errorObj) {
          for (const property in errorObj) {
            setOpen(true);
            setType('error');
            setMessage(`${property}: ${errorObj[property]}`);
          }
        } else if (errorMsg) {
          setOpen(true);
          setType('error');
          setMessage(errorMsg);
        } else {
          setOpen(true);
          setType('error');
          setMessage('Something went wrong');
        }
      }
    },
    initialValues,
    formFields,
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${getUrl + id}/`, {
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setValuesState(response.data.data);
    };
    if (name === 'User') {
      fetchData();
    }

    return () => {};
  }, []);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          {isEdit ? 'Edit' : 'Add'} {name}
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard {category ? `/ ${category}` : ''} /{' '}
            <b>
              {' '}
              {isEdit ? 'Edit' : 'Add'} {name}
            </b>
          </Typography>
        </div>
      </div>
      <GeneralForm
        formData={formData}
        values={valuesState}
        disableLocationState={disableLocationState}
      />
      <CustomizedSnackBars type={type} message={message} open={open} setOpen={setOpen} />
    </div>
  );
};
