import { DashboardTableList } from '../../../../components/DashboardTableList';
import { baseUrl } from '../../../../utils/constants';

export const FaqsList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'left' },
      { id: 'question', label: 'Question', align: 'left' },
      { id: 'answer', label: 'Answer', align: 'left' },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/FAQs/add'
    getUrl={`${baseUrl}/api/v1/utility/admin/faq/`}
    deleteUrl={`${baseUrl}/api/v1/utility/admin/faq/`}
    category=''
    name='FAQs'
  />
);
