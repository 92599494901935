/* eslint-disable @typescript-eslint/ban-ts-comment */
interface Action {
  type: string;
  payload: {
    userName: string;
    isAuth: boolean;
    token: string;
    profileImage: string;
  };
}
const initialState =
  typeof localStorage.getItem('Auth') === 'string'
    ? // @ts-ignore
      JSON.parse(localStorage.getItem('Auth'))
    : { userName: '', isAuth: false, token: '', profileImage: '' };
// eslint-disable-next-line @typescript-eslint/default-param-last
export const auth = (state = initialState, action: Action) => {
  const AuthData = {
    userName: action?.payload?.userName || '',
    isAuth: true,
    token: action?.payload?.token || '',
    profileImage: action?.payload?.profileImage || '',
  };
  switch (action.type) {
    case 'SIGN_IN':
      localStorage.setItem('Auth', JSON.stringify(AuthData));
      return AuthData;

    case 'SIGN_OUT':
      localStorage.removeItem('Auth');
      return { userName: '', isAuth: false, token: '' };

    default:
      return state;
  }
};
