/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import axios from 'axios';
import React from 'react';
import { useAuth } from '../../store/getValues';
import { baseUrl } from '../../utils/constants';

export const UploadMultipleFilesFormik = (props: { id: any; formik: any }) => {
  const { id, formik } = props;
  const auth = useAuth();

  const handleUpload = async (e: { target: { files: any } }) => {
    const currentFile = e.target.files[0];
    let doc_type = 0;
    if (e.target.files[0]) {
      const fileType = currentFile.type.split('/')[0];
      switch (fileType) {
        case 'image':
          doc_type = 0;
          break;
        case 'video':
          doc_type = 1;
          break;
        case 'text':
        case 'application/pdf':
          doc_type = 2;
          break;
        default:
          doc_type = 3;
          break;
      }
    }

    const response = await axios.post(
      `${baseUrl}/api/v1/auth/documents/upload/`,
      { document: e.target.files[0], doc_type },
      {
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    formik.setFieldValue(id, [...formik.values[id], response.data.data.id]);
  };
  return (
    <React.Fragment key={id}>
      <input
        // style={{ display: 'none' }}
        id={id}
        type='file'
        name={id}
        multiple
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // value={formik.values[id]}
        onChange={handleUpload}
      />
      {/* <label htmlFor={id}>
        <Button variant='contained' color='primary' component='span'>
          {label}
        </Button>
      </label> */}
      {/* <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>

      <Button label='My Label'>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id={id}
          name={id}
          multiple
          type='file' // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={formik.values[id]}
          onChange={formik.handleChange}
        />
      </Button> */}
    </React.Fragment>
  );
};
