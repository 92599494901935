/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../index.css';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AwbTable from './AwbTable';
import { useAuth } from '../../../../../store/getValues';
import { baseUrl, BILLING_TYPE, PAYMENT_STATUS } from '../../../../../utils/constants';
import { DownloadPdf } from './DownloadPdf';

// const data = {
//   id: '198',
//   status: 'paid',
//   date: '21/09/2022',
//   paymentMethod: 'Cash',
//   hawbNo: '123456A',
//   hawbRef: '225566A',
//   sender: {
//     name: 'Mohammed Alamin',
//     phone: '+60 12534 123456',
//     email: 'alamin@gmail.com',
//     address: 'Waye Enterprise, 1097 Mount View',
//     country: 'Malaysia',
//   },
//   receiver: {
//     name: 'Mohammed Alamin',
//     phone: '+60 12534 123456',
//     email: 'alamin@gmail.com',
//     address: 'Waye Enterprise, 1097 Mount View',
//     country: 'Malaysia',
//   },
//   itemInvoice: [
//     {
//       description: 'MacBook pro 8GB',
//       qty: 6,
//       unitPrice: 1000,
//       weight: 5,
//       billingWeight: 5,
//       charge: 0,
//       totalPrice: 6000,
//     },
//     {
//       description: 'MacBook pro 14 16GB',
//       qty: 6,
//       unitPrice: 1600,
//       weight: 5,
//       billingWeight: 5,
//       charge: 0,
//       totalPrice: 6 * 1600,
//     },
//   ],
//   summary: {
//     subtotal: 10200,
//     vat: 1450,
//     discount: 300,
//     packageCharge: 0,
//     fragileCharge: 0,
//     total: 11350,
//   },
// };

export const ShipmentAWBView = () => {
  const location = useLocation();
  const [state, setState] = useState<any>();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/shipment/admin/shipment/${location.state.awb}/`,
          {
            headers: { Authorization: `Token ${auth.token}` },
          },
        );
        setState(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();

    return () => {
      // cancel request
    };
  }, []);
  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          AWB Invoice
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / Shipment / <b>AWB Bill Invoice</b>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Button
              variant='contained'
              startIcon={<LocalPrintshopIcon />}
              sx={{ padding: '5px 25px', marginRight: '1rem' }}
              onClick={() => {
                window.print();
              }}
            >
              Print
            </Button>
            <DownloadPdf rootElementId='awb-invoice-page' downloadFileName='awb-invoice' />
          </Box>
        </div>
      </div>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : state ? (
        <div id='awb-invoice-page'>
          {/* Invoice header */}
          <Box
            sx={{ backgroundColor: 'white', margin: '1rem 2rem', padding: '1rem' }}
            className='print'
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <img src='https://imgur.com/n0cxdqj.png' alt='icon' />
              <Typography variant='h6'> DELIVERY SYSTEM</Typography>
            </Box>
            {/* invoice paid  */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#EBEBEB',
                padding: '1rem 2rem',
                alignItems: 'center',
                mt: '1rem',
                mb: '1.5rem',
              }}
            >
              <Typography variant='body1' sx={{ fontSize: '20px' }}>
                Invoice # {state?.id}
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  fontSize: '20px',
                  backgroundColor: '#0F146D',
                  color: 'white',
                  padding: '0.5rem  2rem',
                }}
              >
                {
                  // @ts-ignore
                  PAYMENT_STATUS[state?.status]
                }
              </Typography>
            </Box>
            {/* fist section  */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '100%', sm: '100%', md: '30%' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '.5rem',
                  }}
                >
                  <Typography>Invoice Date :</Typography>
                  <Typography sx={{ color: 'gray' }}>{state?.created_at.split('T')[0]}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '.5rem',
                  }}
                >
                  <Typography>Payment Method :</Typography>
                  <Typography sx={{ color: 'gray' }}>
                    {
                      // @ts-ignore
                      BILLING_TYPE[state?.billing_type]
                    }
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '100%', sm: '100%', md: '30%' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '.5rem',
                  }}
                >
                  <Typography>HAWB No. :</Typography>
                  <Typography sx={{ color: 'gray' }}>{state?.awb}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '.5rem',
                  }}
                >
                  <Typography>HAWB Ref. :</Typography>
                  <Typography sx={{ color: 'gray' }}>{state?.ref}</Typography>
                </Box>
              </Box>
            </Box>
            {/* invoice sender receiver */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                mt: '1rem',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Box sx={{ mt: '1rem' }}>
                <Typography sx={{ mb: '0.5rem' }}>Sender&apos;s Name & Address: </Typography>
                <Typography sx={{ color: 'gray' }}>
                  {`${state.sender_first_name} ${state.sender_last_name}`}
                </Typography>
                <Typography sx={{ color: 'gray' }}>{state.sender_mobile} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.sender_email} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.sender_address} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.sender_country_name} </Typography>
              </Box>
              <Box sx={{ mt: '1rem' }}>
                <Typography sx={{ mb: '0.5rem' }}>Receiver&apos;s Name & Address: </Typography>
                <Typography sx={{ color: 'gray' }}>
                  {`${state.receiver_first_name} ${state.receiver_last_name}`}{' '}
                </Typography>
                <Typography sx={{ color: 'gray' }}>{state.receiver_mobile} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.receiver_email} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.receiver_address} </Typography>
                <Typography sx={{ color: 'gray' }}>{state.receiver_country_name} </Typography>
              </Box>
            </Box>
            {/* table  */}
            <AwbTable rows={state.shipment_items} />

            {/* sender auth and sign and summary  */}
            <Box
              sx={{
                display: 'flex',
                mt: '2rem',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography sx={{ mb: '1rem' }}>
                    Sender&apos;s Authorization & Signature
                  </Typography>
                  <Typography sx={{ fontSize: '12px', mb: '1rem' }}>
                    I/We agree that Delivery Courier Standard Teams Apply to this shipment and limit
                    Team Courier Lability. The Warsaw convention may also apply. All transactions
                    are subject to our Standard Trading Conditions.
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '16px', paddingBottom: '1rem' }}>
                    User Name & Signature
                  </Typography>
                </Box>
              </Box>
              {/* summary */}
              <Box sx={{ minWidth: '250px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <Typography>Sub total</Typography>
                  <Typography>{state.subtotal}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <Typography>VAT</Typography>
                  <Typography>{state.vat_amount}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <Typography>Discount</Typography>
                  <Typography>{state.discount}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <Typography>Packaging Charge</Typography>
                  <Typography>{state.packaging_charge}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <Typography>Fragile Charge</Typography>
                  <Typography>{state.fragile_charge}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '0.5rem',
                    backgroundColor: '#EBEBEB',
                    padding: '1rem 0.5rem',
                  }}
                >
                  <Typography>Invoice Total</Typography>
                  <Typography>{state.total}</Typography>
                </Box>
              </Box>
            </Box>
            {/* footer  */}
            <Box sx={{ mt: '2rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                }}
              >
                <p style={{ color: 'gray', fontSize: '10px' }}>Collected By ( Name & Signature)</p>
                <p style={{ color: 'gray', fontSize: '10px' }}>Date & Time </p>
                <p style={{ color: 'gray', fontSize: '10px' }}>Delivery By ( Name & Signature)</p>
                <p style={{ color: 'gray', fontSize: '10px' }}>Date & Time</p>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  backgroundColor: '#C4C4C4',
                  mt: '0',
                  mb: '1rem',
                }}
              />
              <Typography sx={{ textAlign: 'center' }}>
                We always welcome your suggestions or complements related our service please mail to
                info@email.com
              </Typography>
              <Typography sx={{ textAlign: 'center' }}>
                MATERIAL COST ( CASH ON DELIVERY) CLAMS NOT ACCEPTED AFTER 7 DAYS FROM THE
                COLLECTION DATE.
              </Typography>
            </Box>
          </Box>
        </div>
      ) : (
        'nodata'
      )}
    </div>
  );
};
