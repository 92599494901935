/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState } from 'react';
import { useAuth } from '../../store/getValues';
import { baseUrl } from '../../utils/constants';
import CustomizedSnackBars from '../SnackBarMui';

import { TextFieldFormik } from './TextFieldFormik';

export const CalculationFormik = (props: { formik: any }) => {
  const { formik } = props;
  const auth = useAuth();
  // const [data, setData] = useState({
  //   cost_per_kg: undefined,
  //   fragile_charge: undefined,
  //   packaging_charge: undefined,
  //   price: undefined,
  //   subtotal: undefined,
  //   vat_amount: undefined,
  //   vat_percentage: undefined,
  //   weight: undefined,
  // });
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  const [message, setMessage] = useState('');

  const handleCalculate = async () => {
    if (!formik.isValid) {
      setMessage('Please fill all Fields');
      setOpen(true);
      formik.validateForm();
    } else {
      try {
        const response = await axios.post(
          `${baseUrl}/api/v1/shipment/mobile/shipment/price/`,
          {
            customer: formik.values.customer,
            service_type: formik.values.service_type,
            sender_country: formik.values.sender_country,
            receiver_country: formik.values.receiver_country,
            dimen_w: formik.values.dimen_w,
            dimen_h: formik.values.dimen_h,
            dimen_l: formik.values.dimen_l,
            weight: formik.values.weight,
            packaging_type: formik.values.packaging_type,
            is_fragile: formik.values.is_fragile,
          },
          {
            headers: {
              Authorization: `Token ${auth.token}`,
              // 'Content-Type': 'multipart/form-data',
            },
          },
        );
        const responseData = await response.data.data;
        formik.setFieldValue('cost_per_kg', responseData.cost_per_kg);
        formik.setFieldValue('fragile_charge', responseData.fragile_charge);
        formik.setFieldValue('packaging_charge', responseData.packaging_charge);
        formik.setFieldValue('subtotal', responseData.subtotal);
        formik.setFieldValue('vat_amount', responseData.vat_amount);
        formik.setFieldValue('vat_percentage', responseData.vat_percentage);
        formik.setFieldValue('billing_weight', responseData.weight);
        formik.setFieldValue(
          'total',
          responseData.vat_amount +
            responseData.price +
            responseData.packaging_charge +
            responseData.fragile_charge,
        );
      } catch (error) {
        // @ts-ignore
        const errorObj = error?.response?.data?.errors || {};
        // @ts-ignore
        const errorMsg = error?.response?.data?.message;
        // @ts-ignore
        if (errorObj) {
          // eslint-disable-next-line guard-for-in
          for (const property in errorObj) {
            setOpen(true);
            setType('error');
            setMessage(`${property}: ${errorObj[property]}`);
          }
        } else if (errorMsg) {
          setOpen(true);
          setType('error');
          setMessage(errorMsg);
        } else {
          setOpen(true);
          setType('error');
          setMessage('Something went wrong');
        }
      }
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <CustomizedSnackBars open={open} type={type} setOpen={setOpen} message={message} />
      <TextFieldFormik
        id='packaging_charge'
        label='Packaging cost'
        fullWidth
        formik={formik}
        disabled
      />
      <TextFieldFormik
        id='fragile_charge'
        label='Fragile Handling Charge'
        fullWidth
        formik={formik}
        disabled
      />
      <TextFieldFormik
        id='billing_weight'
        label='Billing Weight'
        fullWidth
        formik={formik}
        disabled
        textFieldType='number'
      />
      <TextFieldFormik
        id='cost_per_kg'
        label='Charge per Kg'
        fullWidth
        formik={formik}
        disabled
        textFieldType='number'
      />{' '}
      <TextFieldFormik
        id='subtotal'
        label='Subtotal Amount (MYR)'
        fullWidth
        formik={formik}
        disabled
        textFieldType='number'
      />
      <TextFieldFormik
        id='vat_amount'
        label='VAT %'
        fullWidth
        formik={formik}
        disabled
        textFieldType='number'
      />{' '}
      <TextFieldFormik
        id='total'
        label='Billing Amount (MYR)'
        fullWidth
        formik={formik}
        disabled
        textFieldType='number'
      />
      <Button variant='contained' sx={{ alignSelf: 'flex-start' }} onClick={handleCalculate}>
        {' '}
        Calculate Now
      </Button>
    </Box>
  );
};
