import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(() => ({
  border: '1px solid gray',
  borderRadius: '1em 0 0 1em',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: '1px solid gray',
  backgroundColor: 'white',
}));

interface Row {
  requestor: string;
  userType: string;
  department: string;
  submitted: string;
  lastUpdated: string;
  status: string;
  priority: string;
  name?: string;
}

export default function TicketTable(props: { rows: Row[] }) {
  const { rows } = props;
  return (
    <TableContainer component={Paper} sx={{ margin: '2rem', maxWidth: '95%' }}>
      <Table aria-label='customized table' sx={{}}>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center'>Requestor</StyledTableCell>
            <StyledTableCell align='center'>Department</StyledTableCell>
            <StyledTableCell align='center'>Submitted</StyledTableCell>
            <StyledTableCell align='center'>Last Updated</StyledTableCell>
            <StyledTableCell align='center'>Status</StyledTableCell>
            <StyledTableCell align='center'>Priority</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: Row) => (
            <StyledTableRow key={row.requestor}>
              <StyledTableCell component='th' scope='row' align='center'>
                {row.requestor}
                <br />
                {row.userType}
              </StyledTableCell>
              <StyledTableCell align='center'>{row.department}</StyledTableCell>
              <StyledTableCell align='center'>{row.submitted}</StyledTableCell>
              <StyledTableCell align='center'>{row.lastUpdated}</StyledTableCell>
              <StyledTableCell align='center'>{row.status}</StyledTableCell>
              <StyledTableCell align='center'>{row.priority}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
