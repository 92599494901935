/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../../store/getValues';

export const SelectFromApiFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
  url?: string;
  options?: any[];
}) => {
  const { id, label, fullWidth, placeholder, formik, url, options } = props;
  const auth = useAuth();
  const [state, setState] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (typeof url === 'string') {
        const response = await axios.get(url, {
          headers: { Authorization: `Token ${auth.token}` },
        });
        setState(
          response.data.data.results.map((item: any) => ({
            // @ts-ignore
            value: options[0]?.value ? item[options[0].value] : item.id,
            // @ts-ignore
            label: options[0]?.label ? item[options[0].label] : item.id,
            // @ts-ignore
            targetValue: options[1]?.value ? item[options[1]?.value] : '',
          })),
        );
      }
    };

    if (state.length === 0) {
      fetchData();
    }

    return () => {
      // cancel request
    };
  }, []);

  const handleChange = (e: any) => {
    formik.setFieldValue(id, e.target.value);
  };

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        id={id}
        name={id}
        placeholder={placeholder || ''}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={formik.values[id]}
        onChange={formik.handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        helperText={formik.touched[id] && formik.errors[id]}
        displayEmpty
        renderValue={
          formik.values[id]
            ? undefined
            : formik.values[id] === 0
            ? undefined
            : () => `Select ${label} `
        }
      >
        {state?.length > 0 ? (
          state.map((item: any) => (
            // @ts-ignore
            <MenuItem
              value={item.value}
              key={item.value + item.label}
              onClick={(e) => {
                handleChange(e);
                // @ts-ignore
                if (options[1]?.label) {
                  // @ts-ignore
                  formik.setFieldValue(options[1].label, item.targetValue);
                }
              }}
            >
              {
                // @ts-ignore
                item.label
              }
            </MenuItem>
          ))
        ) : (
          <MenuItem value='' disabled>
            No Available Options
          </MenuItem>
        )}
        {state?.length > 0 && <MenuItem value=''>None</MenuItem>}
      </Select>
    </React.Fragment>
  );
};
