import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LineChart } from './LineChart';
import { BarChart } from './BarChart';
import { baseUrl } from '../../../utils/constants';
import { useAuth } from '../../../store/getValues';

export const Reports = () => {
  const [paymentByCustomerData, setPaymentByCustomerData] = useState<any>([]);
  const [shipmentReport, setShipmentReport] = useState<any>([]);
  const [customerStatistics, setCustomerStatistics] = useState<any>([]);
  const auth = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      const endPoints = [
        `${baseUrl}/api/v1/reports/admin/payment_by_customer/`,
        `${baseUrl}/api/v1/reports/admin/customer_statistics/`,
        `${baseUrl}/api/v1/reports/admin/shipment_report`,
      ];
      const response = await Promise.all(
        endPoints.map((url) =>
          axios.get(url, {
            headers: { Authorization: `Token ${auth.token}` },
          }),
        ),
      );
      setPaymentByCustomerData(response[0].data.data);
      setCustomerStatistics(response[1].data.data);
      setShipmentReport(response[2].data.data);
    };
    fetchData();
    return () => {};
  }, []);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          Reports
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / <b>Reports</b>
          </Typography>
        </div>
      </div>
      <Box
        sx={{
          margin: '1rem 2rem',
          display: 'flex',
          gap: '1rem',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            p: '1rem',
            flexGrow: '1',
            maxWidth: { xs: '100%', sm: '100%', md: '48%' },
          }}
        >
          <LineChart
            rawData={paymentByCustomerData}
            type='paymentByCustomers'
            name='Payment By Customers'
          />
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            p: '1rem',
            flexGrow: '1',
            maxWidth: { xs: '100%', sm: '100%', md: '48%' },
          }}
        >
          <LineChart rawData={shipmentReport} type='shipmentReport' name='Shipment Report' />
        </Box>
      </Box>
      <Box sx={{ margin: '1rem 2rem', minHeight: '300px', p: 1 }} className='quick-access-tables'>
        <BarChart rawData={customerStatistics} />
      </Box>
    </div>
  );
};
