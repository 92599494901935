import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { GeneralForm } from '../../../components/GeneralForm';
import CustomizedSnackBars from '../../../components/SnackBarMui';
import { setProfileData } from '../../../store/actions/profileData';
import { useAuth, useProfileData } from '../../../store/getValues';
import { baseUrl } from '../../../utils/constants';

const validationSchema = yup.object({
  first_name: yup.string().required('This field is required'),
  last_name: yup.string().required('This field is required'),
  bio: yup.string().required('This field is required'),
});

export const AdminProfile = () => {
  const auth = useAuth();
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');
  const profileData = useProfileData();
  const dispatch = useDispatch();

  const initialValues: any = profileData;

  const formFields = [
    {
      id: 'first_name',
      label: 'First Name',
      type: 'textField',
      options: [],
      fullWidth: true,
      textFieldType: 'first name',
    },
    {
      id: 'last_name',
      label: 'Last Name',
      type: 'textField',
      options: [],
      fullWidth: true,
      textFieldType: 'last name',
    },
    {
      id: 'bio',
      label: 'Bio',
      type: 'textField',
      options: [],
      fullWidth: true,
    },
    {
      id: 'image',
      label: 'Profile Image',
      type: 'imageUpload',
      options: [],
      fullWidth: true,
      url: profileData.image_url,
    },
  ];

  const formData = {
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/v1/auth/profile/`,
          { ...values },
          {
            headers: {
              Authorization: `Token ${auth.token}`,
              // 'Content-Type': 'multipart/form-data',
            },
          },
        );
        setType('success');
        setOpenSnackBar(true);
        setErrorMsg('Profile updated');
        dispatch(setProfileData(response.data.data));
      } catch (error: any) {
        setType('error');
        setOpenSnackBar(true);
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.mobile) {
            setErrorMsg(error?.response?.data?.errors?.mobile);
          }
          if (error?.response?.data?.message) {
            setErrorMsg(error?.response?.data?.message);
          }
        } else if (error?.message) {
          setErrorMsg(error?.message);
        } else {
          setErrorMsg('Something went wrong');
        }
      }
    },
    initialValues,
    formFields,
  };

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          Admin Profile
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / <b>Admin Profile</b>
          </Typography>
        </div>
      </div>
      <GeneralForm formData={formData} />
      <CustomizedSnackBars
        message={errorMsg}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type={type}
      />
    </div>
  );
};
