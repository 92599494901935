import SvgIcon from '@mui/material/SvgIcon';

function EarningsIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id='profits-analytics-svgrepo-com'
        xmlns='http://www.w3.org/2000/svg'
        width='24.745'
        height='23.741'
        viewBox='0 0 26.745 26.741'
      >
        <g id='Group_6566' data-name='Group 6566' transform='translate(0 19.515)'>
          <g id='Group_6565' data-name='Group 6565'>
            <path
              id='Path_8199'
              data-name='Path 8199'
              d='M4.518,286.545H.744a.743.743,0,0,0-.744.744v5.737a.743.743,0,0,0,.744.744H4.518a.743.743,0,0,0,.744-.744v-5.737A.74.74,0,0,0,4.518,286.545Zm-3.029,5.738v-4.254H3.774v4.254Z'
              transform='translate(0 -286.545)'
              fill='#fff'
            />
          </g>
        </g>
        <g id='Group_6568' data-name='Group 6568' transform='translate(7.164 13.905)'>
          <g id='Group_6567' data-name='Group 6567'>
            <path
              id='Path_8200'
              data-name='Path 8200'
              d='M109.7,204.186h-3.774a.743.743,0,0,0-.744.744v11.347a.743.743,0,0,0,.744.744H109.7a.743.743,0,0,0,.744-.744V204.926A.739.739,0,0,0,109.7,204.186Zm-3.025,11.347V205.67h2.285v9.863Z'
              transform='translate(-105.18 -204.186)'
              fill='#fff'
            />
          </g>
        </g>
        <g id='Group_6570' data-name='Group 6570' transform='translate(14.324 8.296)'>
          <g id='Group_6569' data-name='Group 6569'>
            <path
              id='Path_8201'
              data-name='Path 8201'
              d='M214.813,121.826h-3.774a.743.743,0,0,0-.744.744v16.957a.743.743,0,0,0,.744.744h3.774a.743.743,0,0,0,.744-.744V122.566A.745.745,0,0,0,214.813,121.826Zm-.744,16.957h-2.285V123.31h2.285Z'
              transform='translate(-210.295 -121.826)'
              fill='#fff'
            />
          </g>
        </g>
        <g id='Group_6572' data-name='Group 6572' transform='translate(21.483 2.677)'>
          <g id='Group_6571' data-name='Group 6571'>
            <path
              id='Path_8202'
              data-name='Path 8202'
              d='M319.928,39.337h-3.774a.743.743,0,0,0-.744.744V62.648a.743.743,0,0,0,.744.744h3.774a.743.743,0,0,0,.744-.744V40.086A.753.753,0,0,0,319.928,39.337Zm-.744,22.575H316.9V40.83h2.285Z'
              transform='translate(-315.41 -39.337)'
              fill='#fff'
            />
          </g>
        </g>
        <g id='Group_6574' data-name='Group 6574' transform='translate(0.086 0)'>
          <g id='Group_6573' data-name='Group 6573' transform='translate(0 0)'>
            <path
              id='Path_8203'
              data-name='Path 8203'
              d='M16.379.032H12.5a.744.744,0,1,0,0,1.488h2.092L1.479,14.62a.744.744,0,0,0,0,1.052.891.891,0,0,0,1.052,0l13.1-13.1V4.66a.744.744,0,0,0,1.488,0V.776A.743.743,0,0,0,16.379.032Z'
              transform='translate(-1.261 -0.032)'
              fill='#fff'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default EarningsIcon;
