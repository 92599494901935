/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable camelcase */
export const FillAddressData = (formik: any, id: string, mainLocation: any, countriesData: any) => {
  const location = mainLocation.formatted_address;
  if (location) {
    formik.setFieldValue(`${id}address`, location);
  }
  // get city
  let city = '';
  let state = '';
  let country = '';
  let zip_code = '';
  let countryId = 0;
  if (mainLocation) {
    // eslint-disable-next-line array-callback-return
    mainLocation.address_components.map((item: any) => {
      if (
        // item.types[0] === 'postal_town' ||
        // item.types[0] === 'sublocality_level_1' ||
        item.types[0] === 'locality'
      ) {
        city = item.short_name;
      } else if (item.types[0] === 'administrative_area_level_1') {
        state = item.short_name.toLowerCase();
      } else if (item.types[0] === 'country') {
        country = item.short_name;
      } else if (item.types[0] === 'postal_code') {
        zip_code = item.short_name;
      }
    });
  }
  if (city) {
    formik.setFieldValue(`${id}city`, city);
  } else {
    formik.setFieldValue(`${id}city`, '');
  }
  if (state) {
    formik.setFieldValue(`${id}state`, state);
  } else {
    formik.setFieldValue(`${id}state`, '');
  }
  if (zip_code) {
    formik.setFieldValue(`${id}zip_code`, zip_code);
  } else {
    formik.setFieldValue(`${id}zip_code`, '');
  }
  if (country) {
    // eslint-disable-next-line array-callback-return, @typescript-eslint/no-shadow
    countriesData.map((countryData: { code: string; id: number }) => {
      // @ts-ignore
      if (countryData.code === country) {
        countryId = countryData.id;
      }
    });
    formik.setFieldValue(`${id}country`, countryId);
  } else {
    formik.setFieldValue(`${id}country`, undefined);
  }
};
