import * as yup from 'yup';

import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

const formFields = [
  { id: 'company_name', label: 'Company Name', type: 'textField', options: [], fullWidth: true },
  // {
  //   id: 'businessType',
  //   label: 'Business Type',
  //   type: 'radioButton',
  //   options: [
  //     { label: 'B2C', value: 'b2c' },
  //     { label: 'B2B', value: 'b2b' },
  //   ],
  //   fullWidth: true,
  // },
  // {
  //   id: 'customerType',
  //   label: 'Customer Type',
  //   type: 'radioButton',
  //   options: [
  //     { label: 'Direct', value: 'direct' },
  //     { label: 'Online', value: 'online' },
  //     { label: 'Agent', value: 'agent' },
  //     { label: 'Franchise', value: 'franchise' },
  //     { label: 'Partner', value: 'partner' },
  //     { label: 'Branch', value: 'branch' },
  //     { label: 'IPX', value: 'ipx' },
  //   ],
  //   fullWidth: true,
  // },
  // { id: 'customerCode', label: 'Customer Code', type: 'textField', options: [], fullWidth: true },

  // { id: 'siteName', label: 'Site Name', type: 'textField', options: [], fullWidth: true },
  { id: 'mobile', label: 'Mobile', type: 'textField', options: [], fullWidth: true },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'first_name', label: 'First Name', type: 'textField', options: [], fullWidth: true },
  { id: 'last_name', label: 'Last Name', type: 'textField', options: [], fullWidth: true },
  // { id: 'address', label: 'Address', type: 'textField', options: [], fullWidth: true },
  // { id: 'city', label: 'City', type: 'textField', options: [], fullWidth: true },
  // { id: 'state', label: 'State', type: 'textField', options: [], fullWidth: true },
  { id: 'country', label: 'Country', type: 'countrySelect', options: [], fullWidth: true },
  // { id: 'zipCode', label: 'Zip Code', type: 'textField', options: [], fullWidth: true },
  // { id: 'pricing', label: 'Pricing', type: 'masterPricing', options: [], fullWidth: true },
  {
    id: 'password',
    label: 'Customer Password',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  // { id: 'is_b2b', label: 'Business to Business', type: 'checkBox', options: [], fullWidth: true },
  // { id: 'is_b2c', label: 'Business to Customer', type: 'checkBox', options: [], fullWidth: true },
  { id: 'is_verified', label: 'Is Verified', type: 'checkBox', options: [], fullWidth: true },
  { id: 'is_approved', label: 'Is Approved', type: 'checkBox', options: [], fullWidth: true },
  { id: 'is_staff', label: 'Is Staff', type: 'checkBox', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  is_approved: yup.boolean(),
  is_verified: yup.boolean(),
  is_staff: yup.boolean(),
  company_name: yup.string().required('Company Name is required'),
  // businessType: yup.string().required('Business Type is required'),
  // customerType: yup.string().required('Customer Type is required'),
  // customerCode: yup.string().required('Customer Code is required'),
  password: yup.string().required('Customer Password is required'),
  // siteName: yup
  //   .string()
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     'Enter correct url!',
  //   )
  //   .required('Site Name is required'),
  mobile: yup.string().required('mobile is required'),
  email: yup.string().email('Must be a valid email').required('mobile is required'),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  // address: yup.string().required('Address is required'),
  // city: yup.string().required('City is required'),
  // state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  // zipCode: yup.string().required('Zip Code is required'),
  // below_5: yup.string().required('This field is required'),
  // five_to_10: yup.string().required('This field is required'),
  // eleven_to_20: yup.string().required('This field is required'),
  // twenty_one_to_50: yup.string().required('This field is required'),
  // fifty_one_to_100: yup.string().required('This field is required'),
  // above_hundred: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    // case 'radioButton':
    //   initialValues[id] = formFields[index].options[0].value;
    // break;
    case 'masterPricing':
      initialValues.below_5 = '';
      initialValues.five_to_10 = '';
      initialValues.eleven_to_20 = '';
      initialValues.twenty_one_to_50 = '';
      initialValues.fifty_one_to_100 = '';
      initialValues.above_hundred = '';
      break;
    default:
      break;
  }
});

export const CustomersAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Customer'
    name='User'
    validationSchema={validationSchema}
    listUrl='/dashboard/customers/customers/list'
    postUrl={`${baseUrl}/api/v1/auth/user/`}
    patchUrl={`${baseUrl}/api/v1/auth/user/`}
    getUrl={`${baseUrl}/api/v1/auth/user/`}
    disableLocationState
  />
);
