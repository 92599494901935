import * as yup from 'yup';

import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

export interface AdminAndStaffRowInterface {
  id?: string | number;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  password: string;
  is_verified: boolean;
  is_approved: boolean;
  // userType: string;
  is_staff: boolean;
  // isSuperuser: boolean;
  action: string;
}
const formFields = [
  { id: 'first_name', label: 'First Name', type: 'textField', options: [], fullWidth: true },
  { id: 'last_name', label: 'Last Name', type: 'textField', options: [], fullWidth: true },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'country', label: 'Country', type: 'countrySelect', options: [], fullWidth: true },
  { id: 'mobile', label: 'Phone', type: 'textField', options: [], fullWidth: true },
  {
    id: 'password',
    label: 'Password',
    type: 'textField',
    options: [],
    fullWidth: true,
    // textFieldType: 'password',
  },
  { id: 'is_verified', label: 'Is Verified', type: 'checkBox', options: [], fullWidth: true },
  { id: 'is_approved', label: 'Is Approved', type: 'checkBox', options: [], fullWidth: true },
  // { id: 'userType', label: 'User Type', type: 'textField', options: [], fullWidth: true },
  { id: 'is_staff', label: 'Is Staff', type: 'checkBox', options: [], fullWidth: true },
  // { id: 'isSuperuser', label: 'Is Superuser', type: 'checkBox', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  first_name: yup.string().required('This field is required'),
  last_name: yup.string().required('This field is required'),
  email: yup.string().email().required('This field is required'),
  country: yup.string().required('This field is required'),
  mobile: yup.string().required('This field is required'),
  password: yup.string().required('This field is required'),
  // userType: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    default:
      break;
  }
});

export const AdminAndStaffAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Administration'
    name='Admin and Staff'
    validationSchema={validationSchema}
    listUrl='/dashboard/administration/admin_&_staff/list'
    postUrl={`${baseUrl}/api/v1/auth/user/`}
    patchUrl={`${baseUrl}/api/v1/auth/user/`}
  />
);
