import * as yup from 'yup';

import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

const formFields = [
  { id: 'name', label: 'Company Name', type: 'textField', options: [], fullWidth: true },
  {
    id: 'registration_no',
    label: 'Registration No.',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'address', label: 'Address', type: 'textField', options: [], fullWidth: true },
  { id: 'phone', label: 'phone', type: 'textField', options: [], fullWidth: true },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'country', label: 'Country', type: 'countrySelect', options: [], fullWidth: true },
  { id: 'state', label: 'State', type: 'textField', options: [], fullWidth: true },
  { id: 'zip_code', label: 'Zip Code', type: 'textField', options: [], fullWidth: true },
  { id: 'is_active', label: 'Is Active', type: 'checkBox', options: [], fullWidth: false },
];

const validationSchema = yup.object({
  name: yup.string().required('Point Name is required'),
  registration_no: yup.string().required('Contact Person is required'),
  address: yup.string().required('Address is required'),
  phone: yup.string().required('Phone is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  zip_code: yup.string().required('Zip Code is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'countrySelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    default:
      break;
  }
});

export const ClearanceCompanyAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Partners'
    name='Clearance Company'
    validationSchema={validationSchema}
    listUrl='/dashboard/partners/clearance_company/list'
    postUrl={`${baseUrl}/api/v1/partners/mobile/clearance_company/`}
    patchUrl={`${baseUrl}/api/v1/partners/mobile/clearance_company/`}
  />
);
