import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const DimensionFormik = (props: {
  id: any;
  //   label: string;
  //   fullWidth: boolean;
  //   placeholder?: string;
  formik: any;
}) => {
  const { id, formik } = props;

  return (
    <React.Fragment key={id}>
      <Typography sx={{ mb: '1rem', mt: 0, pt: 0, fontSize: '20px' }}> Billing Info</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '2rem',
          alignItems: 'center',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
          },
        }}
      >
        <Typography sx={{ mb: '0', mt: 0, pt: 0, fontSize: '18px' }}> Dimension</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
          <InputLabel sx={{ color: 'black', fontSize: '19px', width: '22px' }} htmlFor='dimen_w'>
            W
          </InputLabel>
          <TextField
            type='number'
            fullWidth
            id='dimen_w'
            name='dimen_w'
            placeholder=''
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={formik.values?.dimen_w}
            onChange={formik.handleChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={formik.touched?.dimen_w && Boolean(formik.errors?.dimen_w)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            helperText={formik.touched?.dimen_w && formik.errors?.dimen_w}
          />
          <Typography sx={{}} variant='body2'>
            cm
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
          <InputLabel
            sx={{ color: 'black', fontSize: '19px', padding: 0, width: '22px' }}
            htmlFor='dimen_h'
          >
            H
          </InputLabel>
          <TextField
            type='number'
            fullWidth
            id='dimen_h'
            name='dimen_h'
            placeholder=''
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={formik.values.dimen_h}
            onChange={formik.handleChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={formik.touched?.dimen_h && Boolean(formik.errors?.dimen_h)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            helperText={formik.touched?.dimen_h && formik.errors?.dimen_h}
          />
          <Typography sx={{}} variant='body2'>
            cm
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
          <InputLabel sx={{ color: 'black', fontSize: '19px', width: '22px' }} htmlFor='dimen_l'>
            L
          </InputLabel>
          <TextField
            type='number'
            fullWidth
            id='dimen_l'
            name='dimen_l'
            placeholder=''
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={formik.values?.dimen_l}
            onChange={formik.handleChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={formik.touched?.dimen_l && Boolean(formik.errors?.dimen_l)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            helperText={formik.touched?.dimen_l && formik.errors?.dimen_l}
          />
          <Typography sx={{}} variant='body2'>
            cm
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
