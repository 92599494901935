import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AllFields, FormField } from '../../../components/GeneralForm/AllFields';

export const SelectFieldForShipment = (props: {
  formik: any;
  title: string;
  category: string;
  formFields: FormField[];
}) => {
  const { formik, title, category, formFields } = props;

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          {title}
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / {category} / <b>{title}</b>
          </Typography>
        </div>
      </div>

      <Box sx={{ margin: '1rem 2rem' }} className='quick-access-tables'>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Box
            sx={{
              margin: '1rem 2rem',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
            }}
            className='quick-access-tables'
          >
            <AllFields formFields={formFields} formik={formik} />
            <Button
              color='primary'
              variant='contained'
              fullWidth
              type='submit'
              sx={{ padding: '0.3rem 3rem', mt: '3rem', mb: '1rem' }}
              autoFocus
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};
