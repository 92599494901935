import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

export const WarehouseList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'name', label: 'Name', align: 'left' },
      { id: 'manager', label: 'Manager', align: 'left' },
      { id: 'address', label: 'Address', align: 'left' },
      { id: 'phone', label: 'Phone', align: 'left' },
      { id: 'email', label: 'Email', align: 'left' },
      { id: 'country', label: 'Country', align: 'left', isCountry: true },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/administration/warehouses/add'
    getUrl={`${baseUrl}/api/v1/shipment/admin/warehouse/`}
    deleteUrl={`${baseUrl}/api/v1/shipment/admin/warehouse/`}
    category='Administration'
    name='Warehouse List'
  />
);
