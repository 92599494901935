import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AdminAndStaffAdd } from './pages/Dashboard/Administration/AdminAndStaff/Add';
import { AdminAndStaffList } from './pages/Dashboard/Administration/AdminAndStaff/List';
import { AdminAndStaffSetting } from './pages/Dashboard/Administration/Setting';
import { WarehouseAdd } from './pages/Dashboard/Administration/Warehouse/Add';
import { WarehouseList } from './pages/Dashboard/Administration/Warehouse/List';
import { ChangePassword } from './pages/Dashboard/ChangePassword';
import { ChannelAdd } from './pages/Dashboard/Channels/Add';
import { ChannelList } from './pages/Dashboard/Channels/List';
import { CustomersCompanyAdd } from './pages/Dashboard/Customers/Company/Add';
import { CustomersCompaniesList } from './pages/Dashboard/Customers/Company/List';
import { CustomersAdd } from './pages/Dashboard/Customers/User/Add';
import { CustomersList } from './pages/Dashboard/Customers/User/List';
import { FaqsAdd } from './pages/Dashboard/FAQS/Add';
import { FaqsList } from './pages/Dashboard/FAQS/List';
import { DashboardLayout } from './pages/Dashboard/Layout';
import { MasterPricingAdd } from './pages/Dashboard/MasterPricing/Add';
import { MasterPricingList } from './pages/Dashboard/MasterPricing/List';
import { ClearanceCompanyAdd } from './pages/Dashboard/Partners/clearanceCompany/Add';
import { ClearanceCompanyList } from './pages/Dashboard/Partners/clearanceCompany/List';
import { DeliveryCompanyAdd } from './pages/Dashboard/Partners/deliveryCompany/Add';
import { DeliveryCompanyList } from './pages/Dashboard/Partners/deliveryCompany/List';
import { InterchangePointAdd } from './pages/Dashboard/Partners/InterchangePoint/Add';
import { InterchangePointList } from './pages/Dashboard/Partners/InterchangePoint/List';
import { PaymentAdd } from './pages/Dashboard/Payment/Add';
import { PaymentList } from './pages/Dashboard/Payment/List';
import { AdminProfile } from './pages/Dashboard/Profile';
import { QuickAccess } from './pages/Dashboard/QuickAccess';
import { Reports } from './pages/Dashboard/Report';
import { AwbList } from './pages/Dashboard/Shipment/Awb/List';
import { ShipmentAWBView } from './pages/Dashboard/Shipment/Awb/View';
import { ShipmentBookAdd } from './pages/Dashboard/Shipment/Book/Add';
import { ShipmentDeliverAdd } from './pages/Dashboard/Shipment/Deliver/Add';
import { ShipmentDeliverList } from './pages/Dashboard/Shipment/Deliver/List';
import { ShipmentProcessAdd } from './pages/Dashboard/Shipment/Process/Add';
import { ShipmentProcessList } from './pages/Dashboard/Shipment/Process/List';
import { ShipmentReceiveAdd } from './pages/Dashboard/Shipment/Receive/Add';
import { ShipmentReceiveList } from './pages/Dashboard/Shipment/Receive/List';
import { ShipmentShipmentList } from './pages/Dashboard/Shipment/Shipment/List';
import { ShipmentTrackSelect } from './pages/Dashboard/Shipment/Track';
import { ShipmentTrackingView } from './pages/Dashboard/Shipment/Track/View';
import { TermsAndPoliciesAdd } from './pages/Dashboard/TermsAndPolicies/Add';
import { TermsAndPoliciesList } from './pages/Dashboard/TermsAndPolicies/List';
import { TicketAdd } from './pages/Dashboard/Ticket/Add';
import { CloseTicketsList } from './pages/Dashboard/Ticket/Close/List';
import { OpenTicketsList } from './pages/Dashboard/Ticket/Open/List';
import { ViewTicket } from './pages/Dashboard/Ticket/View';
import ErrorPage from './pages/Error';
import ForgetPassword from './pages/ForgetPassword';
import LoginPage from './pages/LoginPage';

export const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<LoginPage />} index />
    <Route path='/password-reset' element={<ForgetPassword />} />
    <Route
      path='/dashboard'
      element={
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      }
    >
      <Route path='/dashboard/quick_access' element={<QuickAccess />} />
      <Route path='/dashboard/profile' element={<AdminProfile />} />
      <Route path='/dashboard/change-password' element={<ChangePassword />} />
      {/* Partner */}
      <Route path='/dashboard/partners/interchange_point/list' element={<InterchangePointList />} />
      <Route path='/dashboard/partners/interchange_point/add' element={<InterchangePointAdd />} />
      <Route path='/dashboard/partners/clearance_company/list' element={<ClearanceCompanyList />} />
      <Route path='/dashboard/partners/clearance_company/add' element={<ClearanceCompanyAdd />} />
      <Route path='/dashboard/partners/delivery_company/list' element={<DeliveryCompanyList />} />
      <Route path='/dashboard/partners/delivery_company/add' element={<DeliveryCompanyAdd />} />
      {/* Channels */}
      <Route path='/dashboard/channels/channels/list' element={<ChannelList />} />
      <Route path='/dashboard/channels/channels/Add' element={<ChannelAdd />} />
      {/* MasterPricing */}
      <Route path='/dashboard/master_pricing/master_pricing/list' element={<MasterPricingList />} />
      <Route path='/dashboard/master_pricing/master_pricing/Add' element={<MasterPricingAdd />} />
      {/* Customers */}
      <Route path='/dashboard/customers/customers/list' element={<CustomersList />} />
      <Route path='/dashboard/customers/customers/add' element={<CustomersAdd />} />
      <Route path='/dashboard/customers/companies/list' element={<CustomersCompaniesList />} />
      <Route path='/dashboard/customers/companies/add' element={<CustomersCompanyAdd />} />
      {/* Shipment */}
      <Route path='/dashboard/shipment/shipment/list' element={<ShipmentShipmentList />} />
      <Route path='/dashboard/shipment/book/add' element={<ShipmentBookAdd />} />
      <Route path='/dashboard/shipment/receive/list' element={<ShipmentReceiveList />} />
      <Route path='/dashboard/shipment/receive/add' element={<ShipmentReceiveAdd />} />
      <Route path='/dashboard/shipment/deliver/list' element={<ShipmentDeliverList />} />
      <Route path='/dashboard/shipment/deliver/add' element={<ShipmentDeliverAdd />} />
      <Route path='/dashboard/shipment/process/list' element={<ShipmentProcessList />} />
      <Route path='/dashboard/shipment/process/add' element={<ShipmentProcessAdd />} />
      <Route path='/dashboard/shipment/track/view' element={<ShipmentTrackingView />} />
      <Route path='/dashboard/shipment/track/select' element={<ShipmentTrackSelect />} />
      <Route path='/dashboard/shipment/AWB/list' element={<AwbList />} />
      <Route path='/dashboard/shipment/AWB/view' element={<ShipmentAWBView />} />
      {/* Payment */}
      <Route path='/dashboard/payment/payment/list' element={<PaymentList />} />
      <Route path='/dashboard/payment/payment/add' element={<PaymentAdd />} />
      {/* Support  */}
      <Route path='/dashboard/support/open_ticket/list' element={<OpenTicketsList />} />
      <Route path='/dashboard/support/open_ticket/add' element={<TicketAdd />} />
      <Route path='/dashboard/support/close_ticket/list' element={<CloseTicketsList />} />
      <Route path='/dashboard/support/add_open_ticket/view' element={<ViewTicket />} />
      {/* Administration  */}
      <Route path='/dashboard/administration/warehouses/list' element={<WarehouseList />} />
      <Route path='/dashboard/administration/warehouses/add' element={<WarehouseAdd />} />
      <Route path='/dashboard/administration/admin_&_staff/list' element={<AdminAndStaffList />} />
      <Route path='/dashboard/administration/admin_&_staff/add' element={<AdminAndStaffAdd />} />
      <Route path='/dashboard/administration/global_settings' element={<AdminAndStaffSetting />} />
      {/* FAQs */}
      <Route path='/dashboard/FAQs/list' element={<FaqsList />} />
      <Route path='/dashboard/FAQs/add' element={<FaqsAdd />} />
      {/* Terms and Policies */}
      <Route path='/dashboard/terms_&_policies/list' element={<TermsAndPoliciesList />} />
      <Route path='/dashboard/terms_&_policies/add' element={<TermsAndPoliciesAdd />} />
      {/* Reports  */}
      <Route path='/dashboard/reports' element={<Reports />} />
      {/* Not Found */}
      <Route path='/dashboard/*' element={<ErrorPage />} />
    </Route>
    <Route path='*' element={<ErrorPage />} />
  </Routes>
);
