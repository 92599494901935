import * as yup from 'yup';

import { DashboardForm } from '../../../../components/DashboardForm';
import { baseUrl } from '../../../../utils/constants';

const formFields = [
  {
    id: 'channel',
    label: 'Channel',
    type: 'customSelectApi',
    options: [{ label: 'name', value: 'id' }],
    fullWidth: true,
    // textFieldType: 'number',
    url: `${baseUrl}/api/v1/shipment/admin/channel/`,
  },
  {
    id: 'pricing',
    label: 'Pricing',
    type: 'masterPricing',
    options: [],
    fullWidth: true,
  },
];

const validationSchema = yup.object({
  channel: yup.string().required('Channel Name is required'),
  below_5: yup.string().required('This field is required'),
  five_to_10: yup.string().required('This field is required'),
  eleven_to_20: yup.string().required('This field is required'),
  twenty_one_to_50: yup.string().required('This field is required'),
  fifty_one_to_100: yup.string().required('This field is required'),
  above_hundred: yup.string().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'channelSelect':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;

      break;
    case 'masterPricing':
      initialValues.below_5 = '';
      initialValues.five_to_10 = '';
      initialValues.eleven_to_20 = '';
      initialValues.twenty_one_to_50 = '';
      initialValues.fifty_one_to_100 = '';
      initialValues.above_hundred = '';
      break;
    default:
      break;
  }
});

export const MasterPricingAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Master Pricing'
    name='Master Pricing'
    validationSchema={validationSchema}
    listUrl='/dashboard/master_pricing/master_pricing/list'
    postUrl={`${baseUrl}/api/v1/utility/admin/pricing/`}
    patchUrl={`${baseUrl}/api/v1/utility/admin/pricing/`}
  />
);
