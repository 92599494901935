import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { FC, ReactElement } from 'react';

interface Props {
  name: string;
  route: string;
  color: string;
  icon: ReactElement;
}

export const QuickAccessShortcutCard: FC<Props> = (props) => {
  const { name, route, icon, color } = props;
  const navigate = useNavigate();
  return (
    <Card onClick={() => navigate(route)} className='quick-access-shortcut-card'>
      <Avatar sx={{ m: 1, bgcolor: color }}>{icon}</Avatar>
      <Typography paragraph variant='body2'>
        {name}
      </Typography>
    </Card>
  );
};
