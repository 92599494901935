import SvgIcon from '@mui/material/SvgIcon';

function TicketsIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id='Group_6575'
        data-name='Group 6575'
        xmlns='http://www.w3.org/2000/svg'
        width='26.269'
        height='24.614'
        viewBox='0 0 26.269 28.614'
      >
        <path
          id='Path_8204'
          data-name='Path 8204'
          d='M66.949,300.217a.6.6,0,0,0-.443-.717.608.608,0,0,0-.415.051L41.626,312.38a1.741,1.741,0,0,0-.727,2.357,1.7,1.7,0,0,0,.61.661l5.446,3.443a.3.3,0,0,0,.359-.033l14.52-12.978a.3.3,0,0,1,.429.405l-11.6,14.557a.3.3,0,0,0,.051.419l.023.019,7.682,4.859a1.74,1.74,0,0,0,2.4-.54,1.938,1.938,0,0,0,.219-.526l5.916-24.806Z'
          transform='translate(-40.695 -299.484)'
          fill='#fff'
        />
        <path
          id='Path_8205'
          data-name='Path 8205'
          d='M42.822,304.432a.3.3,0,0,0-.415.093l-.047.159v4.281a1.2,1.2,0,0,0,1.2,1.2,1.177,1.177,0,0,0,.843-.354L46.81,307.4a.3.3,0,0,0,0-.424l-.051-.037-3.936-2.506Z'
          transform='translate(-34.602 -281.549)'
          fill='#fff'
        />
      </svg>
    </SvgIcon>
  );
}

export default TicketsIcon;
