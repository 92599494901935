import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// eslint-disable-next-line no-undef
export function ProtectedRoute({ children }: { children: JSX.Element }) {
  const auth = useSelector((state: { auth: { userName: string; isAuth: boolean } }) => state.auth);
  const location = useLocation();

  if (!auth.isAuth) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return children;
}
