import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { FillAddressData } from './FillAddressData';
import { useCountries } from '../../store/getValues';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting?: StructuredFormatting | null;
  place_id: string;
}

export function PlacesAutocomplete(props: {
  id: any;
  label: string;
  placeholder?: string;
  formik: any;
  textFieldType?: string;
  fullWidth?: boolean;
}) {
  const { id, label, placeholder, formik, fullWidth } = props;
  const countriesData = useCountries();

  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );
  const setLatLon = async (place: string) => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`,
    );
    const { lat, lng } = response.data.results[0].geometry.location;
    if (lat && lng) {
      const str = id;
      const newStr = str.slice(0, -7);
      formik.setFieldValue(`${newStr}latitude`, lat);
      formik.setFieldValue(`${newStr}longitude`, lng);
      const response1 = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`,
      );
      const formattedAddress = response1.data.results[0].formatted_address;
      if (formattedAddress) {
        formik.setFieldValue(`${id}address`, formattedAddress);
      }
      const mainLocation = response1.data.results[0];
      FillAddressData(formik, newStr, mainLocation, countriesData);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  React.useEffect(() => {
    if (formik.values[id]) {
      setValue({ description: formik.values[id], structured_formatting: null });
    }
  }, [formik.values[id]]);

  return (
    <>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Autocomplete
        id={id}
        fullWidth={fullWidth || true}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={placeholder || `Type ${label} `}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          formik.setFieldValue(id, newValue?.description);

          if (newValue) {
            setLatLon(newValue.description);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder || `Type ${label} `} fullWidth />
        )}
        renderOption={(props1, option) => {
          if (option.structured_formatting) {
            const matches = option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props1}>
                <Grid container alignItems='center'>
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        component='span'
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant='body2' color='text.secondary'>
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }

          return (
            <li {...props1}>
              <Grid container alignItems='center'>
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography>{option.description}</Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
