/* eslint-disable no-unused-vars */
import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '../../assets/icons/EditIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import { TableColumnInterface } from '../../pages/Dashboard/data';
import CustomizedSnackBars from '../SnackBarMui';
import { useAuth, useCountries } from '../../store/getValues';
import { baseUrl } from '../../utils/constants';
import { setCountries } from '../../store/actions/countries';
import { Country } from '../../store/reducers/countries';

interface EnhancedTableHeadProps {
  columns: TableColumnInterface[];
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { columns } = props;
  return (
    <TableHead sx={{ bgcolor: '#F5F5F6' }}>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} sx={{ border: '1px solid #E3E6EA' }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  value: string | undefined;
  onClick: (value: string | undefined) => void;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  tableName: string;
  searchLabel?: string;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { value, onClick, setSearchValue, tableName, searchLabel } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: { sm: 'column', md: 'row' },
      }}
    >
      {searchLabel && (
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 100%',
            justifyItems: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <TextField
            id='outlined-basic'
            label={`Search ${searchLabel || 'Here'}`}
            variant='standard'
            sx={{ minWidth: '120px', width: { sm: '120px', md: '400px' }, mr: 1 }}
            value={value}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Button
            variant='contained'
            sx={{ height: '40px', borderRadius: 0 }}
            onClick={() => onClick(value)}
          >
            Search
          </Button>
        </Box>
      )}
      <Typography
        variant='h6'
        id='tableTitle'
        component='div'
        sx={{
          minWidth: '200px',
          fontSize: '17px',
          textAlign: 'right',
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      >
        {tableName}
      </Typography>
    </Toolbar>
  );
}

const serviceType = [
  { label: 'Standard', value: 0 },
  { label: 'Express', value: 1 },
];
const TicketStatus = [
  { label: 'Open', value: 0 },
  { label: 'Close', value: 1 },
];
const paymentStatus = [
  { label: 'Pending', value: 0 },
  { label: 'Success', value: 1 },
  { label: 'Cancelled', value: 2 },
  { label: 'Failed', value: 3 },
];
interface Props {
  tableName: string;
  rows: any[];
  columns: any[];
  editUrl: string;
  deleteUrl: string;
  count: number;
  limit: number;
  setLimit: (x: number) => void;
  offset: number;
  setOffset: (x: number) => void;
  viewUrl?: string;
  searchLabel?: string;
  searchUrl?: string;
  searchName?: string;
}

export default function EnhancedTable(props: Props) {
  const {
    rows,
    columns,
    tableName,
    editUrl,
    deleteUrl,
    count,
    limit,
    setLimit,
    offset,
    setOffset,
    viewUrl,
    searchLabel,
    searchUrl,
    searchName,
  } = props;
  // const [selected, setSelected] = React.useState<readonly string[]>([]);
  const auth = useAuth();
  const [countriesState, setCountriesState] = React.useState(useCountries());
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(`${baseUrl}/api/v1/auth/country/?limit=500&offset=0`, {
        headers: { Authorization: `Token ${auth.token}` },
      });
      setCountriesState(response.data.data.results);
      dispatch(setCountries(response.data.data.results));
    };
    if (countriesState.length === 0) {
      fetchCountries();
    }

    return () => {
      // cancel request
    };
  }, []);
  const [rowData, setRowData] = React.useState(rows);
  const [page, setPage] = React.useState(offset / limit);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);
  const [searchValue, setSearchValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const defaultLabelDisplayedRows = ({ from, to, count }) =>
  //   `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSearch = async (value: string | undefined) => {
    if (value) {
      setSearchValue(value);
      if (searchLabel) {
        if (searchName === 'paymentList') {
          let response;
          if (Number.isInteger(+searchValue)) {
            response = await axios.get(`${searchUrl}?shipment=${searchValue}`, {
              headers: { Authorization: `Token ${auth.token}` },
            });
          } else {
            response = await axios.get(`${searchUrl}?awb=${searchValue}`, {
              headers: { Authorization: `Token ${auth.token}` },
            });
          }
          if (response.data.data.count > 0) {
            setRowData(response.data.data.results);
          } else {
            setErrorMsg('No items found');
            setOpenSnackBar(true);
            setRowData(rows);
          }
        } else {
          const response = await axios.get(`${searchUrl}?${searchName}=${searchValue}`, {
            headers: { Authorization: `Token ${auth.token}` },
          });
          if (response.data.data.count > 0) {
            setRowData(response.data.data.results);
          } else {
            setErrorMsg('No items found');
            setOpenSnackBar(true);
            setRowData(rows);
          }
        }
      }
    }
    // handle search to render data here {by using useEffect}
  };
  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}/`, {
        headers: { Authorization: `Token ${auth.token}` },
      });
      setErrorMsg(`Item ${id} Deleted`);
      setOpenSnackBar(true);
      setLimit(limit - 1);
      // setRowsPerPage(rowsPerPage - 1);
    } catch (error) {
      setErrorMsg('Something went wrong');
      setOpenSnackBar(true);
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <CustomizedSnackBars
        message={errorMsg}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type='error'
      />
      <Paper sx={{ width: '100%', mb: 2, paddingLeft: '2rem', paddingRight: '2rem' }}>
        <EnhancedTableToolbar
          value={searchValue}
          onClick={handleSearch}
          setSearchValue={setSearchValue}
          tableName={tableName}
          searchLabel={searchLabel}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <EnhancedTableHead columns={columns} />
            <TableBody>
              {rowData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  // const id = index + 1 + rowsPerPage * page;

                  <TableRow hover tabIndex={-1} key={JSON.stringify(row)}>
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      columns.map((column: TableColumnInterface) => {
                        // if (column.id === 'number') {
                        //   if (row.number === undefined) {
                        //     return (
                        //       <TableCell
                        //         component='th'
                        //         scope='row'
                        //         sx={{ border: '1px solid #E3E6EA' }}
                        //         align='center'
                        //         key={JSON.stringify(row) + JSON.stringify(column)}
                        //       >
                        //         {id}
                        //       </TableCell>
                        //     );
                        //   }
                        //   return (
                        //     <TableCell
                        //       component='th'
                        //       scope='row'
                        //       sx={{ border: '1px solid #E3E6EA' }}
                        //       key={JSON.stringify(row) + JSON.stringify(column)}
                        //     >
                        //       {
                        //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //         // @ts-ignore
                        //         row[column.id]
                        //       }
                        //     </TableCell>
                        //   );
                        // }
                        if (column.id === 'action') {
                          return (
                            <TableCell
                              key={JSON.stringify(row) + JSON.stringify(column)}
                              sx={{
                                border: '1px solid #E3E6EA',
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: '5px' }}>
                                {editUrl && (
                                  <EditIcon
                                    sx={{
                                      backgroundColor: '#EDF2F6',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyItems: 'center',
                                      paddingLeft: '8px',
                                      paddingRight: '0',
                                      width: '32px',
                                      paddingTop: '8px',
                                      height: '32px',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      navigate(editUrl, {
                                        state: row,
                                      })
                                    }
                                  />
                                )}
                                <DeleteIcon
                                  onClick={() => handleDelete(row.id)}
                                  sx={{
                                    backgroundColor: '#FAE5E5',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    paddingLeft: '8px',
                                    paddingRight: '0',
                                    width: '32px',
                                    paddingTop: '0px',
                                    height: '32px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Box>
                            </TableCell>
                          );
                        }
                        if (column.id === 'actionView') {
                          return (
                            <TableCell
                              key={JSON.stringify(row) + JSON.stringify(column)}
                              sx={{
                                border: '1px solid #E3E6EA',
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: '5px' }}>
                                {typeof viewUrl === 'string' && (
                                  <InfoOutlinedIcon
                                    sx={{
                                      backgroundColor: '#EDF2F6',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyItems: 'center',
                                      paddingLeft: '8px',
                                      paddingRight: '0',
                                      width: '30px',
                                      padding: '5px',
                                      height: '30px',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      navigate(viewUrl, {
                                        state: row,
                                      })
                                    }
                                  />
                                )}
                                {editUrl && (
                                  <EditIcon
                                    sx={{
                                      backgroundColor: '#EDF2F6',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyItems: 'center',
                                      paddingLeft: '8px',
                                      paddingRight: '0',
                                      width: '32px',
                                      paddingTop: '8px',
                                      height: '32px',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      navigate(editUrl, {
                                        state: row,
                                      })
                                    }
                                  />
                                )}
                                <DeleteIcon
                                  onClick={() => handleDelete(row.id)}
                                  sx={{
                                    backgroundColor: '#FAE5E5',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    paddingLeft: '8px',
                                    paddingRight: '0',
                                    width: '32px',
                                    paddingTop: '0px',
                                    height: '32px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Box>
                            </TableCell>
                          );
                        }
                        if (column.isCountry) {
                          return (
                            <TableCell
                              sx={{ border: '1px solid #E3E6EA' }}
                              key={JSON.stringify(row) + JSON.stringify(column)}
                            >
                              {
                                countriesState.find((elem: Country) => elem.id === row[column.id])
                                  ?.name
                              }
                            </TableCell>
                          );
                        }
                        if (column.isServiceType) {
                          return (
                            <TableCell
                              sx={{ border: '1px solid #E3E6EA' }}
                              key={JSON.stringify(row) + JSON.stringify(column)}
                            >
                              {
                                serviceType.find(
                                  (elem: { value: number; label: string }) =>
                                    elem.value === row[column.id],
                                )?.label
                              }
                            </TableCell>
                          );
                        }
                        if (column.isPaymentStatus) {
                          return (
                            <TableCell
                              sx={{ border: '1px solid #E3E6EA' }}
                              key={JSON.stringify(row) + JSON.stringify(column)}
                            >
                              {
                                paymentStatus.find(
                                  (elem: { value: number; label: string }) =>
                                    elem.value === row[column.id],
                                )?.label
                              }
                            </TableCell>
                          );
                        }
                        if (column.isTicketStatus) {
                          return (
                            <TableCell
                              sx={{ border: '1px solid #E3E6EA' }}
                              key={JSON.stringify(row) + JSON.stringify(column)}
                            >
                              {
                                TicketStatus.find(
                                  (elem: { value: number; label: string }) =>
                                    elem.value === row[column.id],
                                )?.label
                              }
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{ border: '1px solid #E3E6EA' }}
                            key={JSON.stringify(row) + JSON.stringify(column)}
                          >
                            {typeof row[column.id] === 'boolean'
                              ? `${row[column.id]}`
                              : row[column.id]}
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                ))}

              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
