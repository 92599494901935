/* eslint-disable @typescript-eslint/ban-ts-comment */
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState, MouseEvent, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DropDownIcon from '../../assets/icons/DropDownIcon';
import { signOut } from '../../store/actions/auth';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ChangePasswordIcon } from '../../assets/icons/change-password.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { useProfileData } from '../../store/getValues';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
export const AppBarMui: FC<Props> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: { userName: string; isAuth: boolean } }) => state.auth);
  const profileData = useProfileData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [avatarSrc, setAvatarSrc] = useState('https://imgur.com/81m9r3Q.png');

  useEffect(() => {
    // @ts-ignore
    if (profileData?.image_url) {
      // @ts-ignore
      setAvatarSrc(profileData.image_url);
    }

    return () => {};
  }, [profileData]);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    dispatch(signOut(''));
    navigate('/');
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    if (windowDimensions.width < 600) {
      setOpen(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [setOpen, windowDimensions]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position='fixed' color='secondary' open={open}>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }} />
          <div className='app-bar-profile-section'>
            <div className='app-bar-profile-photo-title'>
              <img src={avatarSrc} alt='profile' />
              <Box>
                <Typography variant='body1' component='p'>
                  {auth.userName}
                </Typography>
                <Typography variant='body1' component='p' color='GrayText'>
                  Admin
                </Typography>
              </Box>
            </div>
            <IconButton
              size='small'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
              className='app-bar-drop-down-btn'
            >
              <DropDownIcon />
            </IconButton>

            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              keepMounted
              // transformOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ mt: '19px' }}
            >
              <MenuItem
                onClick={() => {
                  navigate('/dashboard/profile');
                }}
                sx={{ borderBottom: '1px solid #E2E8F0' }}
              >
                <UserIcon style={{ marginRight: '1rem' }} />
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/dashboard/change-password');
                }}
                sx={{ borderBottom: '1px solid #E2E8F0' }}
              >
                <ChangePasswordIcon style={{ marginRight: '1rem' }} />
                Change Password
              </MenuItem>
              <MenuItem onClick={handleSignOut}>
                <LogoutIcon style={{ marginRight: '1rem' }} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </CustomAppBar>
    </Box>
  );
};
