import SvgIcon from '@mui/material/SvgIcon';

function PaymentIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='19.394'
        viewBox='0 0 19.363 13.394'
      >
        <path
          id='money-back'
          d='M2.263,2.247v8.774H9.625c.008-.022.017-.044.027-.066l.014-.035.52-1.261h-5.1a1.319,1.319,0,0,0-1.3-1.391V4.8A1.292,1.292,0,0,0,5.039,3.5h9.6A1.322,1.322,0,0,0,15.944,4.89V5.956c.084-.058.158-.12.243-.181a3.973,3.973,0,0,0,1.275-1.651l-.013-1.878Zm8.8,9.239L13.022,6.74l.76,1.655c2.4-.473,4.631-2.031,5.23-4.154,1.261,3.349-1.081,6.257-3.717,7.457l.778,1.7Zm.55-5.813A2,2,0,1,0,10.715,8.4l.9-2.178a1.478,1.478,0,0,1,.131-.247,1.871,1.871,0,0,0-.131-.315ZM0,0V8.174H1.045V2.22A.964.964,0,0,1,2.08,1.24H14.388L14.379,0Z'
          fill='#a7b1c2'
          fillRule='evenodd'
        />
      </svg>
    </SvgIcon>
  );
}
export default PaymentIcon;
