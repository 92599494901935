/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import './index.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
// import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { baseUrl } from '../../utils/constants';
import { useAuth } from '../../store/getValues';

export const ImageUpload = (props: { id: any; label: any; formik: any; url?: string }) => {
  const { id, label, formik, url } = props;
  const [images, setImages] = useState(url ? [{ data_url: url }] : []);
  const auth = useAuth();
  useEffect(() => {
    if (!formik.values[id]) {
      setImages([]);
    }

    return () => {
      setImages([]);
    };
  }, []);

  const onChange = async (imageList: any) => {
    // data for submit
    setImages(imageList);
    if (imageList.length > 0) {
      const response = await axios.post(
        `${baseUrl}/api/v1/auth/documents/upload/`,
        {
          document: imageList[0].file,
          doc_type: 0,
        },
        {
          headers: { Authorization: `Token ${auth.token}`, 'Content-Type': 'multipart/form-data' },
        },
      );
      formik.setFieldValue(id, response.data.data.id);
    }
  };
  return (
    <Box key={id}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}>
        <InputLabel sx={{ color: 'black' }} htmlFor={id}>
          {label}
        </InputLabel>
        <Typography variant='body2' sx={{ color: '#A9A9A9', fontSize: '12px' }}>
          600X600 or 350X350 make sure image is less than 500kb
        </Typography>
      </Box>

      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey='data_url'
        maxFileSize={500000}
        resolutionType='less'
        resolutionWidth={601}
        resolutionHeight={601}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className='upload__image-wrapper'>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className='image-item'>
                <img src={image.data_url} alt='' width='100' />
                <div className='image-item__btn-wrapper'>
                  <Button onClick={() => onImageUpdate(index)} type='button'>
                    Update
                  </Button>
                  {/* <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => onImageRemove(index)}
                    type='button'
                    color='error'
                  /> */}
                </div>
              </div>
            ))}
            <Button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              type='button'
              startIcon={<PhotoCamera />}
              variant='contained'
              color='secondary'
              sx={{ boxShadow: 'none', padding: '5px 30px', mt: '10px' }}
              {...dragProps}
            >
              Upload
            </Button>
            {errors && (
              <div>
                {errors.acceptType && (
                  <span className='photo-upload-error'>Your selected file type is not allow</span>
                )}
                {errors.maxFileSize && (
                  <span className='photo-upload-error'>Selected file size exceed maxFileSize</span>
                )}
                {errors.resolution && (
                  <span className='photo-upload-error'>
                    Selected file is not match your desired resolution
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </Box>
  );
};
