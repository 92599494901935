import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#888888',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Row {
  name: string;
  qty: number;
  unit_price: number;
  total: number;
}
export default function AwbTable(props: { rows: Row[] }) {
  const { rows } = props;
  const emptyRows = rows.length < 3 ? Math.max(0, 3 - rows.length) : 0;

  return (
    <TableContainer component={Paper} sx={{ mt: '2rem' }}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left'>Description of Item</StyledTableCell>
            <StyledTableCell align='center'>Product Quantity</StyledTableCell>
            <StyledTableCell align='center'>Unit Price</StyledTableCell>
            {/* <StyledTableCell align='center'>Weight</StyledTableCell> */}
            {/* <StyledTableCell align='center'>Billing Weight</StyledTableCell> */}
            {/* <StyledTableCell align='center'>Charge(Kg)</StyledTableCell> */}
            <StyledTableCell align='center'>Total Price</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: Row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component='th' scope='row' align='left'>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align='center'>{row.qty}</StyledTableCell>
              <StyledTableCell align='center'>{row.unit_price}</StyledTableCell>
              {/* <StyledTableCell align='center'>{row.weight}</StyledTableCell> */}
              {/* <StyledTableCell align='center'>{row.billingWeight}</StyledTableCell> */}
              {/* <StyledTableCell align='center'>{row.charge}</StyledTableCell> */}
              <StyledTableCell align='center'>{row.total}</StyledTableCell>
            </StyledTableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
                backgroundColor: '#F5F5F5',
              }}
            >
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
