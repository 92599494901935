/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';

import { Map } from '../../../../../components/GoogleMap';
import '../index.css';
import { useAuth } from '../../../../../store/getValues';
import { baseUrl, SHIPMENT_STATUS } from '../../../../../utils/constants';

export const ShipmentTrackingView = () => {
  const location = useLocation();
  // get awb from api
  const { awb } = location.state;
  const auth = useAuth();
  const [trackData, setTrackData] = useState<any>(null);
  const [shipmentData, setShipmentData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/shipment/mobile/shipment/track-by-awb/${awb}/`,
          {
            headers: { Authorization: `Token ${auth.token}` },
          },
        );

        setTrackData(response.data.data);
        //
        const shipmentDataResponse = await axios.get(
          `${baseUrl}/api/v1/shipment/admin/shipment/${response.data.data.id}/`,
          {
            headers: { Authorization: `Token ${auth.token}` },
          },
        );
        setShipmentData(response.data.data);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };
    fetchData();

    return () => {
      // second
    };
  }, [awb]);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          Shipment Tracking
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / Shipment / <b>Shipment Tracking</b>
          </Typography>
        </div>
      </div>
      {shipmentData && trackData && (
        <div>
          {/* Shipment information */}
          <Box sx={{ backgroundColor: 'white', margin: '1rem 2rem', padding: '1rem' }}>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
              SHIPMENT INFORMATION
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                mb: '2rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  gap: { xs: '0.5rem', sm: '0.5rem', md: '2rem' },
                }}
              >
                <Box>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Shipment ID:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Event Status:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Timestamp:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Latitude:*
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.id || 'unknown'}
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {
                      // @ts-ignore
                      SHIPMENT_STATUS[shipmentData?.status] || 'unknown'
                    }
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.created_at.split('T')[0] || 'unknown'}
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.receiver_latitude || 'unknown'}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: { xs: 1, sm: 1, md: 0.85 },
                  gap: { xs: '1.4rem', sm: '1.4rem', md: '2rem' },
                }}
              >
                <Box>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Notes:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    ADMIN NOTE:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Location:*
                  </Typography>
                  <Typography variant='body1' sx={{ mt: '0.5rem', fontWeight: 'bold' }}>
                    Longitude:*
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.customer_note || 'unknown'}
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {shipmentData?.admin_note || 'none'}
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.receiver_country_name || 'unknown'}
                  </Typography>
                  <Typography sx={{ mt: '0.5rem', color: 'gray' }}>
                    {trackData?.receiver_longitude || 'unknown'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* MAP */}
            <Map
              markerLat={trackData?.receiver_latitude}
              markerLng={trackData?.receiver_longitude}
            />
          </Box>

          <Box sx={{ backgroundColor: 'white', margin: '1rem 2rem', padding: '1rem' }}>
            <Typography variant='body1' sx={{ fontWeight: 'bold', mb: '2rem' }}>
              SHIPMENT TRACKING
            </Typography>
            <Box sx={{ width: '300px', marginLeft: { xs: 'unset', sm: 'unset', md: '2rem' } }}>
              {trackData?.events.length > 0
                ? trackData?.events.map(
                    (
                      elem: {
                        updated_at: string;
                        status: number;
                        desc: string;
                        id: number;
                      },
                      index: number,
                    ) => (
                      <Box
                        sx={{
                          display: 'flex',
                          marginBottom: '1rem',
                          minHeight: '64px',
                          maxHeight: '64px',
                        }}
                        key={elem.id}
                      >
                        <Box sx={{ minWidth: '73px', maxWidth: '73px' }}>
                          <div className='shipment-tracking-info-text'>
                            {elem.updated_at.split('T')[1].substring(0, 8)}
                          </div>
                          <div className='shipment-tracking-info-text'>
                            {elem.updated_at.split('T')[0]}
                          </div>
                        </Box>
                        <Box
                          sx={{
                            minWidth: '20px',
                            maxWidth: '20px',
                            minHeight: '20px',
                            maxHeight: '20px',
                            borderRadius: '50%',
                            zIndex: 100,
                            marginRight: '0.5rem',
                            backgroundColor:
                              elem.status === 0
                                ? '#5D00FF'
                                : elem.status === 7 || elem.status === 1
                                ? '#E1E1E1'
                                : '#00FF2B',
                          }}
                          className={
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            index === trackData?.events.length - 1
                              ? ''
                              : elem.status === 7 || elem.status === 1
                              ? 'shipment-vertical-solid-line'
                              : 'shipment-vertical-dashed-line'
                          }
                        />
                        <Box>
                          <div className='shipment-tracking-info-text'>{elem.desc}</div>
                          <div className='shipment-tracking-info-text'>
                            {
                              // @ts-ignore
                              SHIPMENT_STATUS[elem.status]
                            }
                          </div>
                        </Box>
                      </Box>
                    ),
                  )
                : 'No tracking is available, please try again later.'}
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
};
