import Drawer from '@mui/material/Drawer';
import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import './index.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccordingMui } from './AccordingMui';
import { DrawerHeader } from './DrawerHeader';
import { Props } from './types';
import { routes } from './drawersRoutes';
import logoIcon from '../../assets/icons/DropShipLogoSmall.svg';

export const DashboardDrawer: FC<Props> = ({ open, setOpen, drawerWidth }) => {
  // get full year
  const date = new Date();
  const year = date.getFullYear();

  const navigate = useNavigate();

  const handleDrawerClose: () => void = () => {
    setOpen(false);
  };

  // handle open and close according
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeAccording =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const locationFromReactRouter = useLocation();
  const [location, setLocation] = React.useState(['dashboard', 'quick_access']);

  React.useEffect(() => {
    const locationArray = locationFromReactRouter.pathname.split('/');

    setLocation([locationArray[2], locationArray.length > 3 ? locationArray[3] : '']);
    if (location[0] !== locationArray[2]) {
      setExpanded(locationArray[2]);
    }
    return () => {};
  }, [locationFromReactRouter]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <DrawerHeader onClick={handleDrawerClose} role='button'>
        <div className='drawer-header-icon-text'>
          <img src={logoIcon} alt='icon' />
          {/* <Typography component='body' variant='body1' sx={{ fontWeight: 'bold' }}>
            Dropship
          </Typography> */}
        </div>
      </DrawerHeader>
      <Divider />

      <List sx={{ backgroundColor: '#2F4050', borderRadius: '0' }}>
        {routes.map((elem) => {
          if (elem.children.length === 0) {
            return (
              <ListItem
                key={elem.name}
                disablePadding
                sx={{
                  borderLeft: location[0] === elem.route.split('/')[2] ? '5px solid #236F7D' : '',
                }}
                onClick={() => {
                  navigate(elem.route);
                  setExpanded(false);
                }}
              >
                <ListItemButton onClick={() => {}}>
                  <ListItemIcon
                    // sx={{ color: location[0] === elem.route.split('/')[2] ? 'white ' : '#A7B1C2 ' }}
                    sx={{
                      color: '#A7B1C2',
                    }}
                  >
                    {elem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={elem.name}
                    sx={{
                      color: location[0] === elem.route.split('/')[2] ? 'white ' : '#A7B1C2 ',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          }

          return (
            <AccordingMui
              key={elem.name}
              data={elem}
              expanded={expanded}
              handleChange={handleChangeAccording}
              selectedItemProps={location[0]}
              onClick={() => {
                if (elem.route.split('/')[2] !== location[0]) {
                  navigate(elem.route);
                }
              }}
              selectedSubTitle={location[1]}
            />
          );
        })}
      </List>
      <div className='dashboard-copyright'>
        <Typography sx={{ color: '#A7B1C2' }}>Copyright@{year}</Typography>
        <Typography sx={{ color: '#A7B1C2', fontWeight: 'Bold ' }}>Dropship</Typography>
      </div>
    </Drawer>
  );
};
