import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

const columns = [
  { id: 'id', label: '#', align: 'center' },
  { id: 'name', label: 'Point Name', align: 'left' },
  { id: 'registration_no', label: 'Registration No', align: 'left' },
  { id: 'address', label: 'Address', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'state', label: 'State', align: 'left' },
  { id: 'country', label: 'Country', align: 'left', isCountry: true },
  { id: 'zip_code', label: 'Zip Code', align: 'left' },
  { id: 'is_active', label: 'Is Active', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];
export const ClearanceCompanyList = () => (
  <DashboardTableList
    addUrl='/dashboard/partners/clearance_company/add'
    category='Partners'
    name='Clearance Company List'
    columns={columns}
    getUrl={`${baseUrl}/api/v1/partners/mobile/clearance_company/`}
    deleteUrl={`${baseUrl}/api/v1/partners/mobile/clearance_company/`}
    searchLabel='name'
    searchName='name'
  />
);
