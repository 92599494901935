/* eslint-disable no-nested-ternary */
import * as yup from 'yup';
import { DashboardForm } from '../../../../../components/DashboardForm';
import { baseUrl } from '../../../../../utils/constants';

const formFields = [
  {
    id: 'customer',
    label: 'User',
    type: 'customSelectApi',
    options: [{ value: 'id', label: 'first_name' }],
    fullWidth: true,
    url: `${baseUrl}/api/v1/auth/user/`,
    textFieldType: 'first_name',
  },
  {
    id: 'categories',
    label: 'Categories',
    type: 'radioButton',
    options: [
      { label: 'Standard', value: 0 },
      { label: 'Express', value: 1 },
    ],
    fullWidth: true,
  },
  { id: 'awb', label: 'HAWB#', type: 'textFieldGenerate', options: [], fullWidth: true },
  { id: 'ref', label: 'Ref#', type: 'textField', options: [], fullWidth: true },
  { id: 'senderInfo', label: 'Sender-Info', type: 'separator', options: [], fullWidth: true },
  {
    id: 'sender_first_name',
    label: 'First Name',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'sender_last_name', label: 'Last Name', type: 'textField', options: [], fullWidth: true },
  { id: 'sender_mobile', label: 'Mobile', type: 'textField', options: [], fullWidth: true },
  { id: 'sender_email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  {
    id: 'sender_',
    label: 'Location',
    type: 'mapField',
    options: [{ label: 'sender_', value: 'editOtherFields' }],
    fullWidth: true,
  },
  {
    id: 'sender_address',
    label: 'Address',
    type: 'addressAutocomplete',
    options: [],
    fullWidth: true,
  },
  { id: 'sender_city', label: 'City', type: 'textField', options: [], fullWidth: true },
  { id: 'sender_state', label: 'State', type: 'textField', options: [], fullWidth: true },
  {
    id: 'sender_country',
    label: 'Country',
    type: 'countrySelect',
    options: [],
    fullWidth: true,
  },
  { id: 'sender_zip_code', label: 'Zip Code', type: 'textField', options: [], fullWidth: true },
  { id: 'receiverInfo', label: 'Receiver-Info', type: 'separator', options: [], fullWidth: true },
  {
    id: 'receiver_first_name',
    label: 'First Name',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  {
    id: 'receiver_last_name',
    label: 'Last Name',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'receiver_mobile', label: 'Mobile', type: 'textField', options: [], fullWidth: true },
  { id: 'receiver_email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  {
    id: 'receiver_',
    label: 'Location',
    type: 'mapField',
    options: [{ label: 'sender_', value: 'editOtherFields' }],
    fullWidth: true,
  },
  {
    id: 'receiver_address',
    label: 'Address',
    type: 'addressAutocomplete',
    options: [],
    fullWidth: true,
  },
  { id: 'receiver_city', label: 'City', type: 'textField', options: [], fullWidth: true },
  { id: 'receiver_state', label: 'State', type: 'textField', options: [], fullWidth: true },
  {
    id: 'receiver_country',
    label: 'Country',
    type: 'countrySelect',
    options: [],
    fullWidth: true,
  },
  {
    id: 'receiver_zip_code',
    label: 'Zip Code',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 's1', label: '', type: 'separator', options: [], fullWidth: true },
  {
    id: 'customer_note',
    label: 'Customer Remarks (Optional)',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 's2', label: '', type: 'separator', options: [], fullWidth: true },
  {
    id: 'admin_note',
    label: 'Admin Remarks (Optional)',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  {
    id: 's3',
    label: '',
    type: 'separator',
    options: [],
    fullWidth: true,
  },
  { id: 'shipment_items', label: 'Item-info', type: 'itemInfo', options: [], fullWidth: true },
  {
    id: 's4',
    label: '',
    type: 'separator',
    options: [],
    fullWidth: true,
  },
  {
    id: 'dimension',
    label: 'dimension',
    type: 'dimension',
    options: [],
    fullWidth: true,
  },
  {
    id: 'weight',
    label: 'Weight',
    type: 'textField',
    textFieldType: 'number',

    options: [],
    fullWidth: true,
  },
  {
    id: 'packaging_type',
    label: 'Packaging Wrapping',
    type: 'radioButton',
    options: [
      { label: 'Bubble Wrap', value: 0 },
      { label: 'Packet', value: 1 },
      { label: 'Box', value: 2 },
      { label: 'None', value: 3 },
    ],
    fullWidth: true,
  },
  {
    id: 'package_type',
    label: 'Package Type',
    type: 'radioButton',
    options: [
      { label: 'Parcel', value: 0 },
      { label: 'Document', value: 1 },
    ],
    fullWidth: true,
  },
  {
    id: 'is_fragile',
    label: 'Is Fragile Needed',
    type: 'checkBox',
    options: [],
    fullWidth: true,
  },
  {
    id: 'billing_type',
    label: 'Billing',
    type: 'radioButton',
    options: [
      { label: 'Cash', value: 0 },
      { label: 'Online', value: 1 },
    ],
    fullWidth: true,
  },
  {
    id: 'service_type',
    label: 'Service Type',
    type: 'radioButton',
    options: [
      { label: 'Standard', value: 0 },
      { label: 'Express', value: 1 },
    ],
    fullWidth: true,
  },
  {
    id: 'calculation section',
    label: 'Calculation',
    type: 'separator',
    options: [],
    fullWidth: true,
  },
  { id: '', label: '', type: 'calculation', options: [], fullWidth: true },
  { id: 'finishLine', label: '', type: 'separator', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  customer: yup.number().required('This Field is required'),
  categories: yup.number().required('This Field is required'),
  awb: yup.string().required('This Field is required'),
  ref: yup.string().required('This Field is required'),
  sender_first_name: yup.string().required('This Field is required'),
  sender_last_name: yup.string().required('This Field is required'),
  sender_mobile: yup.string().required('This Field is required'),
  sender_email: yup.string().email().required('This Field is required'),
  sender_address: yup.string().required('This Field is required'),
  sender_longitude: yup.string().required('This Field is required'),
  sender_latitude: yup.string().required('This Field is required'),
  sender_city: yup.string().required('This Field is required'),
  sender_state: yup.string().required('This Field is required'),
  sender_country: yup.string().required('This Field is required'),
  sender_zip_code: yup.string().required('This Field is required'),
  receiver_first_name: yup.string().required('This Field is required'),
  receiver_last_name: yup.string().required('This Field is required'),
  receiver_mobile: yup.string().required('This Field is required'),
  receiver_email: yup.string().email().required('This Field is required'),
  receiver_address: yup.string().required('This Field is required'),
  receiver_latitude: yup.string().required('This Field is required'),
  receiver_longitude: yup.string().required('This Field is required'),
  receiver_city: yup.string().required('This Field is required'),
  receiver_state: yup.string().required('This Field is required'),
  receiver_country: yup.string().required('This Field is required'),
  receiver_zip_code: yup.string().required('This Field is required'),
  customer_note: yup.string(),
  admin_note: yup.string(),
  shipment_items: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This Field is required'),
      unit_price: yup
        .number()
        .min(0.01, 'Must be more than 0')
        .positive('Must be more than 0')
        .required('This Field is required'),
      qty: yup
        .number()
        .min(0.01, 'Must be more than 0')
        .positive('Must be more than 0')
        .required('This Field is required'),
      total: yup
        .number()
        .min(0.01, 'Must be more than 0')
        .positive('Must be more than 0')
        .required('This Field is required'),
    }),
  ),
  dimen_w: yup.number().when('weight', {
    is: 0,
    then: yup.number().min(0.01, 'Must be more than 0').positive('Must be more than 0'),
  }),
  dimen_h: yup.number(),
  dimen_l: yup.number(),
  weight: yup.number().when('dimen_l', {
    is: 0,
    then: yup.number().min(0.01, 'Must be more than 0').positive('Must be more than 0'),
  }),
  package_type: yup.string().required('This Field is required'),
  packaging_type: yup.string().required('This Field is required'),
  billing_type: yup.string().required('This Field is required'),
});

const initialValues: any = {
  charge_per_kg: undefined,
  fragile_charge: undefined,
  packaging_charge: undefined,
  total: undefined,
  subtotal: undefined,
  vat_amount: undefined,
  vat_percentage: undefined,
  billing_weight: undefined,
};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type, textFieldType }, index) => {
  switch (type) {
    case 'textField':
    case 'textFieldGenerate':
      if (textFieldType === 'number') {
        initialValues[id] = 0;
      } else {
        initialValues[id] = '';
      }
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    case 'countrySelect':
      initialValues[id] = 132;
      break;
    case 'customSelectApi':
      initialValues[id] = 1;
      break;
    case 'radioButton':
      // eslint-disable-next-line prefer-destructuring
      initialValues[id] = formFields[index].options[0].value
        ? formFields[index].options[0].value
        : formFields[index].options[0].value === 0
        ? 0
        : '';
      break;
    case 'itemInfo':
      initialValues[id] = [{ name: '', unit_price: 0, qty: 0, total: 0 }];
      break;
    case 'dimension':
      initialValues.dimen_w = 0;
      initialValues.dimen_l = 0;
      initialValues.dimen_h = 0;
      break;
    case 'mapField':
      initialValues[`${id}latitude`] = 3.1191101980865756;
      initialValues[`${id}longitude`] = 101.65452943787395;
      break;

    default:
      break;
  }
});

export const ShipmentBookAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Shipment'
    name='Book a Shipment'
    validationSchema={validationSchema}
    listUrl='/dashboard/shipment/shipment/list'
    postUrl={`${baseUrl}/api/v1/shipment/admin/shipment/`}
    patchUrl={`${baseUrl}/api/v1/shipment/admin/shipment/`}
  />
);
