import * as yup from 'yup';
import { DashboardForm } from '../../../../components/DashboardForm';
import { baseUrl } from '../../../../utils/constants';

const formFields = [
  { id: 'question', label: 'Question', type: 'textField', options: [], fullWidth: true },
  {
    id: 'answer',
    label: 'Answer',
    type: 'multiLine',
    options: [],
    fullWidth: true,
  },
  {
    id: 'position',
    label: 'position',
    type: 'customSelect',
    options: [
      { label: '0', value: 0 },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
    ],
    textFieldType: 'number',
    numberStep: 1,
    fullWidth: true,
  },
  { id: 'is_active', label: 'Is Active', type: 'checkBox', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  question: yup.string().required('This field is required'),
  answer: yup.string().required('This field is required'),
  position: yup
    .number()
    .integer('Only Integer Numbers are accepted')
    .required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }) => {
  switch (type) {
    case 'textField':
    case 'multiLine':
      initialValues[id] = '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    case 'customSelect':
      initialValues[id] = 0;
      break;
    default:
      break;
  }
});

export const FaqsAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category=''
    name='FAQ'
    validationSchema={validationSchema}
    listUrl='/dashboard/FAQs/list'
    postUrl={`${baseUrl}/api/v1/utility/admin/faq/`}
    patchUrl={`${baseUrl}/api/v1/utility/admin/faq/`}
  />
);
