import SvgIcon from '@mui/material/SvgIcon';

function DeleteIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='22' height='24' viewBox='0 0 22 14.807'>
        <g id='trash-2' transform='translate(-2.4 -1.4)'>
          <path
            id='Path_249'
            data-name='Path 249'
            d='M3,6H15.246'
            transform='translate(0 -1.279)'
            fill='none'
            stroke='#d32728'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.2'
          />
          <path
            id='Path_250'
            data-name='Path 250'
            d='M14.525,4.721v9.525a1.361,1.361,0,0,1-1.361,1.361h-6.8A1.361,1.361,0,0,1,5,14.246V4.721m2.041,0V3.361A1.361,1.361,0,0,1,8.4,2h2.721a1.361,1.361,0,0,1,1.361,1.361V4.721'
            transform='translate(-0.639)'
            fill='none'
            stroke='#d32728'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.2'
          />
          <line
            id='Line_268'
            data-name='Line 268'
            y2='4.082'
            transform='translate(7.762 8.123)'
            fill='none'
            stroke='#d32728'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.2'
          />
          <line
            id='Line_269'
            data-name='Line 269'
            y2='4.082'
            transform='translate(10.484 8.123)'
            fill='none'
            stroke='#d32728'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.2'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default DeleteIcon;
