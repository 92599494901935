import { DashboardTableList } from '../../../../components/DashboardTableList';
import { TableColumnInterface } from '../../data';
import { baseUrl } from '../../../../utils/constants';

const columns: TableColumnInterface[] = [
  { id: 'id', label: '#', align: 'center' },
  { id: 'channel_name', label: 'Channel Name', align: 'left' },
  { id: 'below_5', label: 'Below 5 kg', align: 'left' },
  { id: 'five_to_10', label: '5 - 10', align: 'left' },
  { id: 'eleven_to_20', label: '11 - 20', align: 'left' },
  { id: 'twenty_one_to_50', label: '21 - 50', align: 'left' },
  { id: 'fifty_one_to_100', label: '51 - 100 ', align: 'left' },
  { id: 'above_hundred', label: '100 - ꝏ', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

export const MasterPricingList = () => (
  <DashboardTableList
    columns={columns}
    addUrl='/dashboard/master_pricing/master_pricing/add'
    getUrl={`${baseUrl}/api/v1/utility/admin/pricing/`}
    deleteUrl={`${baseUrl}/api/v1/utility/admin/pricing/`}
    labelUrl={`${baseUrl}/api/v1/shipment/admin/channel/`}
    category='Master Pricing'
    name='Master Pricing List'
  />
);
