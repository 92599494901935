import * as yup from 'yup';

import { DashboardForm } from '../../../../components/DashboardForm';
import { baseUrl } from '../../../../utils/constants';

const formFields = [
  {
    id: 'shipment',
    label: 'Shipment AWB',
    type: 'customSelectApi',
    options: [
      { label: 'awb', value: 'id' },
      { label: 'amount', value: 'total' },
    ],
    fullWidth: true,
    url: `${baseUrl}/api/v1/shipment/admin/shipment/?is_paid=false`,
  },
  {
    id: 'payment_method',
    label: 'Payment Method',
    type: 'radioButton',
    options: [
      { value: 0, label: 'Paypal' },
      { value: 1, label: 'BillPlz' },
      { value: 2, label: 'Cash' },
    ],
    fullWidth: true,
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'textField',
    options: [],
    fullWidth: true,
    textFieldType: 'number',
    disabled: true,
  },
  { id: 'isPaid', label: 'Is Paid', type: 'checkBox', options: [], fullWidth: true },
];

const validationSchema = yup.object({
  shipment: yup.string().required('This field is required'),
  amount: yup.string().required('This field is required'),
  payment_method: yup.number().required('This field is required'),
});

const initialValues: any = {};
// eslint-disable-next-line array-callback-return
formFields.map(({ id, type }, index: number) => {
  switch (type) {
    case 'textField':
      initialValues[id] = '';
      break;
    case 'radioButton':
      // eslint-disable-next-line prefer-destructuring
      initialValues[id] = formFields[index].options[0].value || '';
      break;
    case 'checkBox':
      initialValues[id] = false;
      break;
    default:
      break;
  }
});

export const PaymentAdd = () => (
  <DashboardForm
    formFields={formFields}
    initialValues={initialValues}
    category='Payment'
    name='Payment'
    validationSchema={validationSchema}
    listUrl='/dashboard/payment/payment/list'
    postUrl={`${baseUrl}/api/v1/utility/admin/payment/`}
    patchUrl={`${baseUrl}/api/v1/utility/admin/payment/`}
  />
);
