import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { OptionalObjectSchema } from 'yup/lib/object';
import { useNavigate, useLocation } from 'react-router';

import { AllFields, FormField } from './AllFields';

interface Props {
  formData: {
    validationSchema: OptionalObjectSchema<any>;
    onSubmit: (values: any) => void;
    initialValues: any;
    formFields: FormField[];
    validateOnChange?: boolean;
  };
  disableLocationState?: boolean;
  values?: any;
}

export const GeneralForm: FC<Props> = (props) => {
  const { formData, disableLocationState, values } = props;
  const { validationSchema, formFields, onSubmit } = formData;
  let { initialValues, validateOnChange } = formData;
  const navigate = useNavigate();
  const location = useLocation();
  if (location.state) {
    if (disableLocationState !== true) {
      initialValues = location.state;
      if (location.state.thumbnail_url) {
        // eslint-disable-next-line array-callback-return
        formFields.map((formField, index: number) => {
          if (formField.id === 'thumbnail') {
            formFields[index].url = location.state.thumbnail_url;
          }
        });
      }
    }
  }
  if (validateOnChange === undefined) {
    validateOnChange = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              margin: '1rem 2rem',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
            }}
            className='quick-access-tables'
          >
            <AllFields formFields={formFields} formik={formik} values={values} />

            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                color='primary'
                variant='outlined'
                fullWidth
                onClick={() => navigate(-1)}
                sx={{ padding: '0.3rem 3rem' }}
              >
                Back
              </Button>

              <Button
                color='primary'
                variant='contained'
                fullWidth
                type='submit'
                sx={{ padding: '0.3rem 3rem' }}
                autoFocus
              >
                {location.state ? 'Edit' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
