import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { baseUrl } from '../../../../../utils/constants';
import { SelectFieldForShipment } from '../../SelectFieldForShipment';

const validationSchema = yup.object({
  id: yup.string().required('This Field is required'),
});

const initialValues = { id: '' };

export const ShipmentProcessList = () => {
  const navigate = useNavigate();
  const onSubmit = (values: any) => {
    navigate('/dashboard/shipment/process/add', {
      state: values,
    });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <SelectFieldForShipment
      formik={formik}
      title='Receive Shipment List'
      category='Shipment'
      formFields={[
        {
          id: 'id',
          label: 'Shipment AWB',
          url: `${baseUrl}/api/v1/shipment/admin/shipment/?status=1`,
          placeholder: 'Select Shipment AWB',
          type: 'customSelectWithSearchFromApi',
          fullWidth: true,
          options: [{ label: 'awb', value: 'id' }],
          textFieldType: 'awb',
        },
      ]}
    />
  );
};
