import SvgIcon from '@mui/material/SvgIcon';

function EditIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <g id='Group_253' data-name='Group 253' transform='translate(-1284.956 -1847.456)'>
          <g id='edit' transform='translate(1283.556 1846.177)'>
            <path
              id='Path_103'
              data-name='Path 103'
              d='M8.291,4H3.4A1.4,1.4,0,0,0,2,5.4v9.786a1.4,1.4,0,0,0,1.4,1.4h9.786a1.4,1.4,0,0,0,1.4-1.4V10.291'
              transform='translate(0 -0.638)'
              fill='none'
              stroke='#0f146d'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.2'
            />
            <path
              id='Path_104'
              data-name='Path 104'
              d='M15.34,2.313a1.483,1.483,0,0,1,2.1,2.1L10.8,11.051l-2.8.7.7-2.8Z'
              transform='translate(-1.806 0)'
              fill='none'
              stroke='#0f146d'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.2'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default EditIcon;
