/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../store/getValues';
// import { baseUrl } from '../../utils/constants';
import TableMui from '../TableMui';
// import { InterchangePointListData } from '../../../data';

import '../DashboardForm/index.css';

export const DashboardTableList = (props: {
  columns: any;
  getUrl: string;
  deleteUrl: string;
  name: string;
  category: string;
  addUrl?: string;
  viewUrl?: string;
  editUrl?: string;
  labelUrl?: string;
  searchLabel?: string;
  searchUrl?: string;
  searchName?: string;
}) => {
  const {
    columns,
    getUrl,
    deleteUrl,
    name,
    category,
    addUrl,
    viewUrl,
    editUrl,
    labelUrl,
    searchLabel,
    searchUrl,
    searchName,
  } = props;
  //   const columns = [
  //     { id: 'id', label: '#', align: 'center' },
  //     { id: 'name', label: 'Point Name', align: 'left' },
  //     { id: 'contact_person', label: 'Contact Person', align: 'left' },
  //     { id: 'address', label: 'Address', align: 'left' },
  //     { id: 'phone', label: 'Phone', align: 'left' },
  //     { id: 'email', label: 'Email', align: 'left' },
  //     { id: 'state', label: 'State', align: 'left' },
  //     { id: 'country', label: 'Country', align: 'left' },
  //     { id: 'zip_code', label: 'Zip Code', align: 'left' },
  //     { id: 'is_active', label: 'Is Active', align: 'left' },
  //     { id: 'action', label: 'Action', align: 'left' },
  //   ];
  const navigate = useNavigate();
  const auth = useAuth();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let response;

      if (name === 'Awb List') {
        response = await axios.get(`${getUrl}`, {
          headers: { Authorization: `Token ${auth.token}` },
        });
      } else {
        response = await axios.get(`${getUrl}?limit=${limit}&offset=${offset}`, {
          headers: { Authorization: `Token ${auth.token}` },
        });
      }
      const data = response.data.data.results;
      // handle label for Master Pricing list to display companies
      if (labelUrl) {
        if (name === 'Master Pricing List') {
          const labelResponse = await axios.get(`${labelUrl}`, {
            headers: { Authorization: `Token ${auth.token}` },
          });
          const labelData = labelResponse.data.data.results;
          const labelObject = {};
          labelData.map((item: { id: number; name: string }) => {
            // @ts-ignore
            labelObject[item.id] = item.name;
          });
          data.map((elem: { id: string }, index: number) => {
            // @ts-ignore
            data[index].channel_name = labelObject[data[index].channel] || data[index].channel;
          });
        }
      }
      // handle label for companies to show company parent name
      if (name === 'Companies List') {
        const labelData = data;
        const labelObject = {};
        labelData.map((item: { id: number; name: string }) => {
          // @ts-ignore
          labelObject[item.id] = item.name;
        });
        data.map((elem: { id: string }, index: number) => {
          // @ts-ignore
          data[index].parent_company_name =
            // @ts-ignore
            labelObject[data[index].parent_company] || '';
        });
      }
      setCount(response.data.data.count);
      setRows(data);
      setLoading(true);
      setLoading(false);

      return response;
    };
    fetchData();
    return () => {
      // cancel request
    };
  }, [auth.token, getUrl, limit, offset]);

  return (
    <div className='dashboard-page-main-container'>
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          {name}
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard {category ? `/ ${category}` : ''} / <b>{name}</b>
          </Typography>
          {addUrl && (
            <Button
              variant='contained'
              startIcon={<AddToPhotosIcon />}
              sx={{ padding: '5px 25px', marginRight: '1rem' }}
              onClick={() => {
                navigate(addUrl);
              }}
            >
              Add New
            </Button>
          )}
        </div>
      </div>

      <Box sx={{ margin: '1rem 2rem' }} className='quick-access-tables'>
        {loading ? (
          <Box
            sx={{
              width: '100%',
              height: '60vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : rows?.length > 0 ? (
          <TableMui
            tableName={name}
            rows={rows}
            columns={columns}
            editUrl={addUrl || editUrl || ''}
            deleteUrl={deleteUrl}
            count={count}
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            viewUrl={viewUrl || ''}
            searchLabel={searchLabel}
            searchUrl={searchUrl || getUrl}
            searchName={searchName}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '60vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            No Data
          </Box>
        )}
      </Box>
    </div>
  );
};
