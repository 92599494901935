import SvgIcon from '@mui/material/SvgIcon';

function ChannelsIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id='Component_69_36'
        data-name='Component 69 – 36'
        xmlns='http://www.w3.org/2000/svg'
        width='22.362'
        height='23.584'
        viewBox='0 0 22.362 23.584'
      >
        <path
          id='Path_8244'
          data-name='Path 8244'
          d='M669.571,171.63a6.809,6.809,0,0,1,0,.723,1.8,1.8,0,0,0,1.331,2.28,4.131,4.131,0,0,1,1.878,5.854.722.722,0,0,0,.144,1.061c.678.648,1.3,1.36,1.739,1.834a12.473,12.473,0,0,1,2.61-.542,3.021,3.021,0,0,1,2.853,3.4,3.134,3.134,0,0,1-3.625,2.714,3.054,3.054,0,0,1-2.474-3.7,1.141,1.141,0,0,0-.387-1.273c-.691-.639-1.326-1.338-2.022-2.05a4.578,4.578,0,0,1-5.317-.016c-.787.81-1.592,1.585-2.319,2.428-.162.188-.106.625-.05.93a3.1,3.1,0,1,1-5.3-1.521,3.151,3.151,0,0,1,4.191-.314l2.41-2.406a14.472,14.472,0,0,1-.576-2.629,4.156,4.156,0,0,1,3.184-4.154c.385-.111.533-.25.517-.647-.028-.667-.007-1.337-.007-1.987-1.963-.875-2.77-2.121-2.432-3.706a3.1,3.1,0,0,1,6.065-.064C672.355,169.475,671.587,170.69,669.571,171.63Zm-.584,3.755a3.049,3.049,0,0,0-3.138,3.069,3.111,3.111,0,0,0,6.222-.018A3.05,3.05,0,0,0,668.988,175.385Zm-6.256,10.5a1.816,1.816,0,0,0-1.841-1.835,1.858,1.858,0,0,0-1.871,1.827,1.9,1.9,0,0,0,1.9,1.891A1.86,1.86,0,0,0,662.731,185.883Zm12.456.042a1.86,1.86,0,0,0,1.855,1.84,1.9,1.9,0,0,0,1.859-1.858,1.861,1.861,0,0,0-1.914-1.86A1.818,1.818,0,0,0,675.187,185.925ZM670.808,168.5a1.85,1.85,0,0,0-3.7-.03,1.85,1.85,0,1,0,3.7.03Z'
          transform='translate(-657.782 -165.418)'
          fill='#a7b1c2'
        />
      </svg>
      ;
    </SvgIcon>
  );
}

export default ChannelsIcon;
