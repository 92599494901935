import SvgIcon from '@mui/material/SvgIcon';

function DropDownIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M12,15a1,1,0,0,1-.71-.29l-4-4A1,1,0,0,1,8.71,9.29L12,12.59l3.29-3.29a1,1,0,0,1,1.41,1.41l-4,4A1,1,0,0,1,12,15Z' />
      </svg>
    </SvgIcon>
  );
}

export default DropDownIcon;
