import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React from 'react';
import { useAuth } from '../../store/getValues';
import { baseUrl } from '../../utils/constants';

export const TextFieldGenerateFormik = (props: {
  id: any;
  label: string;
  placeholder?: string;
  formik: any;
}) => {
  const { id, label, placeholder, formik } = props;

  const auth = useAuth();

  const generateKey = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/shipment/mobile/shipment/awb/`, {
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const { awb } = response.data.data;
      formik.setFieldValue(id, awb);
    } catch (error: any) {
      formik.setFieldTouched(id);
      formik.setFieldError(id, error.response.data.message || 'Error trying to get AWB');
    }
  };
  return (
    <React.Fragment key={id}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem', width: '100%' }}>
        <InputLabel sx={{ color: 'black' }} htmlFor={id}>
          {label}
        </InputLabel>
        <TextField
          fullWidth={false}
          id={id}
          name={id}
          placeholder={placeholder || 'Press Generate Button to get AWB Value'}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={formik.values[id]}
          disabled
          // onChange={formik.handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={formik.touched[id] && Boolean(formik.errors[id])}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          helperText={formik.touched[id] && formik.errors[id]}
          sx={{
            flexGrow: 1,
          }}
        />
        <Button onClick={generateKey} variant='contained' sx={{ padding: '13px 20px' }}>
          Generate
        </Button>
      </Box>
    </React.Fragment>
  );
};
