import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

export const AdminAndStaffList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'first_name', label: 'First Name', align: 'left' },
      { id: 'last_name', label: 'Last Name', align: 'left' },
      { id: 'email', label: 'Email', align: 'left' },
      { id: 'mobile', label: 'Phone', align: 'left' },
      { id: 'is_verified', label: 'Is Verified', align: 'left' },
      { id: 'is_approved', label: 'Is Approved', align: 'left' },
      // { id: 'user_type', label: 'User Type', align: 'left' },
      { id: 'is_staff', label: 'Is Staff', align: 'left' },
      // { id: 'isSuperuser', label: 'Is Superuser', align: 'left' },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/administration/admin_&_staff/add'
    getUrl={`${baseUrl}/api/v1/auth/user/`}
    deleteUrl={`${baseUrl}/api/v1/auth/user/`}
    category='Administration'
    name='Admin And Staff List'
  />
);
