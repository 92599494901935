/* eslint-disable @typescript-eslint/ban-ts-comment */
import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const SelectFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
  options: { value: number; label: string }[];
}) => {
  const { id, label, fullWidth, placeholder, formik, options } = props;

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        id={id}
        name={id}
        placeholder={placeholder || ''}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={formik.values[id]}
        onChange={formik.handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        helperText={formik.touched[id] && formik.errors[id]}
        displayEmpty
        renderValue={
          // eslint-disable-next-line no-nested-ternary
          formik.values[id]
            ? undefined
            : formik.values[id] === 0
            ? undefined
            : () => `Select ${label} `
        }
      >
        {options.length > 0 &&
          options.map((item: { value: number | string; label: string }) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        {options?.length > 0 && <MenuItem value=''>None</MenuItem>}
      </Select>
    </React.Fragment>
  );
};
