import { Outlet } from 'react-router';
import Box from '@mui/material/Box';

import { useState } from 'react';
import { AppBarMui } from '../../components/AppBarMui';
import { DashboardDrawer } from '../../components/DashboardDrawer';
import { Main } from './Main';
import { DrawerHeader } from '../../components/DashboardDrawer/DrawerHeader';

export const DashboardLayout = () => {
  const drawerWidth = 240;
  const [open, setOpen] = useState(true);
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBarMui open={open} setOpen={setOpen} />
      <DashboardDrawer open={open} setOpen={setOpen} drawerWidth={drawerWidth} />
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};
