import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ReactComponent as DropShipIcon } from '../../assets/icons/DropShipLogo.svg';
import { ForgetPasswordForm } from './ForgetPasswordForm';
import { OtpForm } from './OtpForm';

function ForgetPassword() {
  const location = useLocation();
  const [step, setStep] = useState('one');
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    if (location?.state?.mobile) {
      setMobile(location?.state?.mobile);
    }
    return () => {};
  }, [location.state.mobile]);

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid
        item
        md={5}
        sx={{
          backgroundColor: '#2F4050',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{ width: '80%' }}
          src='https://i.imgur.com/N8MYRTP.png'
          alt='delivery man deliver a parcel to a customer'
        />
        <div style={{ width: '80%' }}>
          <h1 style={{ color: 'white' }}>Welcome! A few click and access to dashboard</h1>
          <p style={{ color: 'white' }}>Mange all your system in one place</p>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center !important',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            maxWidth: '400px',
            maxHeight: '600px',
            gap: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
            <DropShipIcon />
          </Box>
          <Typography component='h1' variant='h6'>
            Password Reset
          </Typography>
          {step === 'one' && (
            <ForgetPasswordForm setStep={setStep} mobile={mobile} setMobile={setMobile} />
          )}
          {step === 'two' && <OtpForm mobile={mobile} />}
        </Card>
      </Grid>
    </Grid>
  );
}

export default ForgetPassword;
