/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';

const Marker = () => <RoomIcon color='error' />;

export const Map = (props: { zoom?: any; markerLat?: any; markerLng?: any; onClick?: any }) => {
  const { zoom, markerLat, markerLng, onClick } = props;
  //   const disableOnClickProps = typeof disableOnClick === 'boolean' ? disableOnClick : false;
  const [lat, setLat] = useState(+markerLat || undefined);
  const [lng, setLng] = useState(+markerLng || undefined);
  const defaultProps = {
    center: {
      lat: 3.1191101980865756,
      lng: 101.65452943787395,
    },
    zoom: 9,
  };

  const handleOnClick: (e: { lat: number; lng: number }) => void = (e) => {
    if (onClick) {
      setLat(e.lat);
      setLng(e.lng);
      onClick(e.lat, e.lng);
    }
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '40vh', width: '100%' }}>
      <GoogleMapReact
        // @ts-ignore
        bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY }}
        defaultCenter={markerLat ? { lat: +markerLat, lng: +markerLng } : defaultProps.center}
        defaultZoom={zoom || defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onClick={handleOnClick}
      >
        <Marker
          // @ts-ignore
          lat={lat || defaultProps.lat}
          // @ts-ignore
          lng={lng || defaultProps.lng}
        />
      </GoogleMapReact>
    </div>
  );
};
