import { DashboardTableList } from '../../../../components/DashboardTableList';
import { baseUrl } from '../../../../utils/constants';

export const TermsAndPoliciesList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'title', label: 'Headline', align: 'left' },
      { id: 'desc', label: 'Description', align: 'left' },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/terms_&_policies/add'
    getUrl={`${baseUrl}/api/v1/utility/admin/page/`}
    deleteUrl={`${baseUrl}/api/v1/utility/admin/page/`}
    category=''
    name='Privacy and Policies'
  />
);
