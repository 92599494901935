import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// Marker, InfoWindow
import React, { useState } from 'react';

const GoogleMapCustom = (props: { zoom: any; markerLat: any; markerLng: any; onClick: any }) => {
  const { zoom, markerLat, markerLng, onClick } = props;
  //   const disableOnClickProps = typeof disableOnClick === 'boolean' ? disableOnClick : false;
  const [lat, setLat] = useState(+markerLat || undefined);
  const [lng, setLng] = useState(+markerLng || undefined);
  const defaultProps = {
    center: {
      lat: 3.1191101980865756,
      lng: 101.65452943787395,
    },
    zoom: 9,
  };

  const handleOnClick: (e: any) => void = (e) => {
    if (onClick) {
      setLat(e.latLng.lat());
      setLng(e.latLng.lng());
      onClick(e.latLng.lat(), e.latLng.lng());
    }
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY!,
    libraries: ['places'],
  });

  const mapRef = React.useRef<any | null>(null);

  const onLoad = (map: any): void => {
    mapRef.current = map;
  };

  const umMount = (): void => {
    mapRef.current = null;
  };

  if (!isLoaded) return <div>map is not loaded</div>;
  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '40vh' }}
        onLoad={onLoad}
        onUnmount={umMount}
        options={{
          zoomControl: true,
        }}
        zoom={zoom || defaultProps.zoom}
        center={markerLat ? { lat: +markerLat, lng: +markerLng } : defaultProps.center}
        onClick={handleOnClick}
      >
        <Marker
          position={{
            lat: lat || defaultProps.center.lat,
            lng: lng || defaultProps.center.lng,
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default GoogleMapCustom;
