/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { AllFields } from '../../../../components/GeneralForm/AllFields';
import { baseUrl } from '../../../../utils/constants';
import { useAuth } from '../../../../store/getValues';
import CustomizedSnackBars from '../../../../components/SnackBarMui';

const globalSettingFields = [
  { id: 'site_name', label: 'Site Name', type: 'textField', options: [], fullWidth: true },
  {
    id: 'website_url',
    label: 'Website URL',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'email', label: 'Email', type: 'textField', options: [], fullWidth: true },
  { id: 'phone', label: 'Phone', type: 'textField', options: [], fullWidth: true },
  { id: 'address', label: 'Address', type: 'textField', options: [], fullWidth: true },
  {
    id: 'shipping_fee',
    label: 'shipping_fee',
    type: 'textField',
    options: [],
    fullWidth: true,
    textFieldType: 'number',
  },
  {
    id: 'short_desc',
    label: 'Short Description',
    type: 'multiLine',
    options: [4],
    fullWidth: true,
  },
];

const paymentSettingFields = [
  {
    id: 'billplz_signature',
    label: 'Billplz Secrete',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'billplz_token', label: 'Billplz Token', type: 'textField', options: [], fullWidth: true },
  {
    id: 'billplz_collection',
    label: 'Billplz Collection Id',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  {
    id: 'billplz_password',
    label: 'Billplz Password',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 's1', label: '', type: 'separator', options: [], fullWidth: true },
  {
    id: 'paypal_client_secret',
    label: 'Paypal Secret',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  {
    id: 'paypal_client_id',
    label: 'Paypal Client Id',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 's2', label: '', type: 'separator', options: [], fullWidth: true },
  { id: 'twilio_token', label: 'Twillio Secret', type: 'textField', options: [], fullWidth: true },
  { id: 'twilio_account', label: 'Twillio Sid', type: 'textField', options: [], fullWidth: true },
  { id: 'twilio_number', label: 'Twillio Phone', type: 'textField', options: [], fullWidth: true },
];

const socialSettingFields = [
  {
    id: 'facebook',
    label: 'facebook',
    type: 'textField',
    options: [],
    fullWidth: true,
  },
  { id: 'twitter', label: 'twitter', type: 'textField', options: [], fullWidth: true },
  { id: 'linkedin', label: 'linkedin', type: 'textField', options: [], fullWidth: true },
  { id: 'instagram', label: 'instagram', type: 'textField', options: [], fullWidth: true },
  { id: 'youtube', label: 'youtube', type: 'textField', options: [], fullWidth: true },
  { id: 'logo', label: 'logo', type: 'imageUpload', options: [], fullWidth: true },
];
export const AdminAndStaffSetting = () => {
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'info'>('error');

  const formik = useFormik({
    initialValues: {
      site_name: '',
      website_url: '',
      email: '',
      phone: '',
      address: '',
      short_desc: '',
      shipping_fee: '',
      billplz_signature: '',
      billplz_token: '',
      billplz_collection: '',
      billplz_password: '',
      paypal_client_secret: '',
      paypal_client_id: '',
      twilio_token: '',
      twilio_account: '',
      twilio_number: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      youtube: '',
      logo: '',
    },
    validationSchema: yup.object({
      site_name: yup.string().required('This field is required'),
      website_url: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!',
        )
        .required('This field is required'),
      email: yup.string().email().required('This field is required'),
      phone: yup.string().required('This field is required'),
      address: yup.string().required('This field is required'),
      short_desc: yup.string().required('This field is required'),
      billplz_signature: yup.string().required('This field is required'),
      billplz_token: yup.string().required('This field is required'),
      billplz_collection: yup.string().required('This field is required'),
      billplz_password: yup.string().required('This field is required'),
      paypal_client_secret: yup.string().required('This field is required'),
      paypal_client_id: yup.string().required('This field is required'),
      twilio_token: yup.string().required('This field is required'),
      twilio_account: yup.string().required('This field is required'),
      twilio_number: yup.string().required('This field is required'),
      facebook: yup.string().required('This field is required'),
      twitter: yup.string().required('This field is required'),
      linkedin: yup.string().required('This field is required'),
      instagram: yup.string().required('This field is required'),
      youtube: yup.string().required('This field is required'),
    }),
    onSubmit: async (values: any) => {
      try {
        await axios.post(`${baseUrl}/api/v1/utility/admin/global-settings/`, values, {
          headers: {
            Authorization: `Token ${auth.token}`,
            // 'Content-Type': 'multipart/form-data',
          },
        });
        setOpen(true);
        setType('success');
        setMessage('Successfully Updated');
        // Navigate({ to: '/dashboard/partners/interchange_point/list' });
      } catch (error) {
        // @ts-ignore
        const errorObj = error?.response?.data?.errors || {};
        // @ts-ignore
        const errorMsg = error?.response?.data?.message;
        // @ts-ignore
        if (errorObj) {
          for (const property in errorObj) {
            setOpen(true);
            setType('error');
            setMessage(`${property}: ${errorObj[property]}`);
          }
        } else if (errorMsg) {
          setOpen(true);
          setType('error');
          setMessage(errorMsg);
        } else {
          setOpen(true);
          setType('error');
          setMessage('Something went wrong');
        }
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);
      const response = await axios.get(`${baseUrl}/api/v1/utility/admin/global-settings/`, {
        headers: { Authorization: `Token ${auth.token}` },
      });
      formik.setValues({ ...response.data.data, billplz_password: '' });
      return response;
    };
    fetchData();

    return () => {};
  }, []);

  return (
    <div className='dashboard-page-main-container'>
      <CustomizedSnackBars open={open} type={type} setOpen={setOpen} message={message} />
      <div className='dashboard-page-title'>
        <Typography paragraph variant='h5' fontWeight='bold'>
          Global Settings
        </Typography>
        <div className='dashboard-page-nav dashboard-page-nav-with-add'>
          <Typography paragraph variant='body2'>
            Dashboard / Administration / <b>Global Settings</b>
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            margin: '1rem 2rem',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
          }}
          className='quick-access-tables'
        >
          <Typography paragraph variant='h6'>
            Global Settings Details
          </Typography>
          <AllFields formFields={globalSettingFields} formik={formik} />
        </Box>

        <Box
          sx={{
            margin: '1rem 2rem',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
          }}
          className='quick-access-tables'
        >
          <Typography paragraph variant='h6'>
            Payment Gateway Details
          </Typography>
          <AllFields formFields={paymentSettingFields} formik={formik} />
        </Box>

        <Box
          sx={{
            margin: '1rem 2rem',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
          }}
          className='quick-access-tables'
        >
          <Typography paragraph variant='h6'>
            Logo & Social Media Urls
          </Typography>
          <AllFields formFields={socialSettingFields} formik={formik} />
          <Button
            color='primary'
            variant='contained'
            type='submit'
            sx={{ padding: '0.3rem 3rem' }}
            autoFocus
          >
            Save Changes
          </Button>
        </Box>
      </form>
    </div>
  );
};
