import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useState } from 'react';
import { baseUrl } from '../../utils/constants';
import CustomizedSnackBars from '../../components/SnackBarMui';

const validationSchema = yup.object({
  mobile: yup.string().required('This Field is required'),
});

export const ForgetPasswordForm = (props: { setStep: any; setMobile: any; mobile: string }) => {
  const { setStep, setMobile, mobile } = props;
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const formik = useFormik({
    initialValues: {
      mobile: mobile || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.post(`${baseUrl}/api/v1/auth/forget/password/`, {
          mobile: values.mobile,
        });
        setMobile(values.mobile);
        setStep('two');
      } catch (error: any) {
        setOpenSnackBar(true);
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.mobile) {
            setErrorMsg(error?.response?.data?.errors?.mobile);
          }
          if (error?.response?.data?.message) {
            setErrorMsg(error?.response?.data?.message);
          }
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography component='p' variant='body2'>
        Forgotten your password? Enter your email address, and we&apos;ll send you an email allowing
        you to reset it.
      </Typography>
      <TextField
        margin='normal'
        required
        fullWidth
        id='mobile'
        label='Mobile'
        name='mobile'
        autoComplete='mobile'
        autoFocus
        value={formik.values.mobile}
        onChange={formik.handleChange}
        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
        helperText={formik.touched.mobile && formik.errors.mobile}
      />

      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs />
        <Grid item />
      </Grid>
      <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
        Reset Password
      </Button>
      <CustomizedSnackBars
        message={errorMsg}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        type='error'
      />
    </form>
  );
};
