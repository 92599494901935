import { DashboardTableList } from '../../../../components/DashboardTableList';
import { baseUrl } from '../../../../utils/constants';

const columns = [
  { id: 'id', label: '#', align: 'center' },
  { id: 'shipment', label: 'Shipment ID', align: 'left' },
  { id: 'shipment_awb', label: 'Shipment AWB', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'left' },
  { id: 'status', label: 'Status', align: 'left', isPaymentStatus: true },
  { id: 'action', label: 'Action', align: 'left' },
];

export const PaymentList = () => (
  <DashboardTableList
    columns={columns}
    addUrl='/dashboard/payment/payment/add'
    getUrl={`${baseUrl}/api/v1/utility/admin/payment/`}
    deleteUrl={`${baseUrl}/api/v1/utility/admin/payment/`}
    category=''
    name='Payment List'
    searchName='paymentList'
    searchLabel='Payment by Shipment ID or Shipment AWB'
  />
);
