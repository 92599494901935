import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';

const columns = [
  { id: 'number', label: '#', align: 'center' },
  { id: 'subject', label: 'Subject', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'lastUpdate', label: 'Last Update', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

export const CloseTicketsList = () => (
  <DashboardTableList
    columns={columns}
    addUrl='/dashboard/support/open_ticket/add'
    getUrl={`${baseUrl}/api/v1/support/admin/ticket/?priority=0&status=0&`}
    deleteUrl={`${baseUrl}/api/v1/support/admin/ticket/?priority=0&status=0&`}
    category='Partners'
    name='Interchange Point List'
  />
);
