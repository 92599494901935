import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { QuickAccessShortcutCard } from './QuickAccessShortcutCard';
import './index.css';
import { shortcuts } from './quickAccessShortcut';

export const QuickAccess = () => (
  <div className='dashboard-page-main-container'>
    <div className='dashboard-page-title'>
      <div className='dashboard-page-nav'>
        <HomeIcon />
        <Typography paragraph variant='body2'>
          / QUICK ACCESS
        </Typography>
      </div>
      <Typography paragraph variant='h5' fontWeight='bold'>
        QUICK ACCESS
      </Typography>
    </div>
    <Box sx={{ margin: '1rem 2rem' }} className='quick-access-shortcut-cards'>
      {shortcuts.length > 0 &&
        shortcuts.map((elem) => (
          <QuickAccessShortcutCard
            name={elem.name}
            icon={elem.icon}
            route={elem.route}
            color={elem.color}
            key={elem.name}
          />
        ))}
    </Box>
    <Box sx={{ margin: '1rem 2rem' }} className='quick-access-tables' />
  </div>
);
