import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React from 'react';

export const MultiLineFormik = (props: {
  id: any;
  label: string;
  fullWidth: boolean;
  placeholder?: string;
  formik: any;
  textFieldType?: string;
}) => {
  const { id, label, fullWidth, placeholder, formik, textFieldType } = props;

  return (
    <React.Fragment key={id}>
      <InputLabel sx={{ color: 'black' }} htmlFor={id}>
        {label}
      </InputLabel>
      <TextField
        type={textFieldType || 'text'}
        fullWidth={fullWidth}
        id={id}
        name={id}
        placeholder={placeholder || `Type ${label} `}
        multiline
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rows={3}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={formik.values[id]}
        onChange={formik.handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={formik.touched[id] && Boolean(formik.errors[id])}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        helperText={formik.touched[id] && formik.errors[id]}
      />
    </React.Fragment>
  );
};
