import { DashboardTableList } from '../../../../../components/DashboardTableList';
import { baseUrl } from '../../../../../utils/constants';
// import { TableColumnInterface } from '../../../data';

// const columns: TableColumnInterface[] = [
//   { id: 'number', label: '#', align: 'center' },
//   { id: 'company_name', label: 'Company Name', align: 'left' },
//   { id: 'businessType', label: 'Business Type', align: 'left' },
//   { id: 'customerType', label: 'Customer Type', align: 'left' },
//   { id: 'phone', label: 'Phone', align: 'left' },
//   { id: 'customerName', label: 'Customer Name', align: 'left' },
//   { id: 'address', label: 'Address', align: 'left' },
//   { id: 'city', label: 'City', align: 'left' },
//   { id: 'state', label: 'State', align: 'left' },
//   { id: 'country', label: 'Country', align: 'left' },
//   { id: 'zip_code', label: 'Zip Code', align: 'left' },
//   { id: 'action', label: 'Action', align: 'left' },
// ];

export const CustomersList = () => (
  <DashboardTableList
    columns={[
      { id: 'id', label: '#', align: 'center' },
      { id: 'first_name', label: 'First Name', align: 'left' },
      { id: 'last_name', label: 'Last Name', align: 'left' },
      { id: 'email', label: 'Email', align: 'left' },
      { id: 'mobile', label: 'Phone', align: 'left' },
      { id: 'is_verified', label: 'Is Verified', align: 'left' },
      { id: 'is_approved', label: 'Es Approved', align: 'left' },
      { id: 'is_staff', label: 'IS Staff', align: 'left' },
      { id: 'action', label: 'Action', align: 'left' },
    ]}
    addUrl='/dashboard/customers/customers/add'
    getUrl={`${baseUrl}/api/v1/auth/user/`}
    deleteUrl={`${baseUrl}/api/v1/auth/user/`}
    category='Customers'
    name='Customers List'
    searchLabel='name'
    searchName='search'
  />
);
