export interface Country {
  [x: string]: any;
  code: string;
  created_at: string;
  flag: string;
  id: number;
  is_active: boolean;
  name: string;
  phone_code: string;
  updated_at: string;
  awb: string;
  amount: string;
}

interface Action {
  type: string;
  payload: Country[];
}

const initialState: Country[] = [];

// eslint-disable-next-line @typescript-eslint/default-param-last
export const countries = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return [...action.payload];

    default:
      return state;
  }
};
