import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const tableData = [
  { id: 'below_5', label: 'Below 5 kg' },
  { id: 'five_to_10', label: '5 - 10' },
  { id: 'eleven_to_20', label: '11 - 20' },
  { id: 'twenty_one_to_50', label: '21 - 50' },
  { id: 'fifty_one_to_100', label: '51 - 100 ' },
  { id: 'above_hundred', label: '100 - ꝏ' },
];

export const MasterPricingFormik = (props: {
  label: string;
  placeholder?: string;
  formik: any;
}) => {
  const { label, formik, placeholder } = props;
  return (
    <>
      <Typography
        sx={{
          textAlign: { xs: 'center', sm: 'center' },
          width: { xs: '100%', sm: '100%', md: 'unset' },
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          m: 'auto',
        }}
      >
        {tableData.map((elem) => (
          <Box
            key={elem.id}
            sx={{
              border: '2px solid #F6F8FA',
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            <InputLabel
              sx={{
                color: '#484848',
                borderBottom: '4px solid #F6F8FA',
                width: '100%',
                textAlign: 'center',
                paddingTop: 1,
                paddingBottom: 1,
              }}
              htmlFor={elem.id}
            >
              {elem.label}
            </InputLabel>
            <TextField
              type='number'
              id={elem.id}
              name={elem.id}
              placeholder={placeholder || ''}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={formik.values[elem.id]}
              onChange={formik.handleChange}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={formik.touched[elem.id] && Boolean(formik.errors[elem.id])}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              helperText={formik.touched[elem.id] && formik.errors[elem.id]}
              sx={{ width: '70%', textAlign: 'center', mt: 2, mb: 2 }}
              inputProps={{
                min: 0,
                step: '0.01',
                style: { textAlign: 'center', paddingTop: 5, paddingBottom: 5 },
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
