// import InputLabel from '@mui/material/InputLabel';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { useState } from 'react';
// import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const LineChart = (props: {
  rawData: any;
  type: 'paymentByCustomers' | 'shipmentReport';
  name: string;
}) => {
  const { rawData, type, name } = props;

  const labelsObject = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const labels = rawData.map(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (elem: { created_at_month: string }) => labelsObject[elem.created_at__month],
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: name,
      },
    },
  };
  let data;
  if (type === 'paymentByCustomers') {
    const paymentData = rawData.map(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (elem: { amount__sum: string }) => elem.amount__sum,
    );
    data = {
      labels,
      datasets: [
        {
          label: 'Payment',
          data: paymentData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
  } else {
    data = {
      labels,
      datasets: [
        {
          label: 'Delivered',
          data: rawData.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (elem: { delivered_count: string }) => elem.delivered_count,
          ),
          borderColor: 'rgb(53, 162, 235)',
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Pending',
          data: rawData.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (elem: { pending_count: string }) => elem.pending_count,
          ),
          borderColor: 'rgb(255, 99, 132)',
          // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
  }

  return (
    // const [customerGroup, setCustomerGroup] = useState('month');

    // const handleCustomerGroup = (event: SelectChangeEvent) => {
    //   setCustomerGroup(event.target.value);
    // };
    <Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* <Typography sx={{}}>{name}</Typography> */}
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              width: '100%',
              //   border: '1px solid red',
            }}
          >
            {/* <InputLabel variant='standard' htmlFor='customers-group'>
            Group By
          </InputLabel>
          <Select
            defaultValue={customerGroup}
            labelId='customers-group'
            id='customers-group'
            value={customerGroup}
            onChange={handleCustomerGroup}
            //   sx={{ padding: '0 2rem' }}
            autoWidth
          >
            <MenuItem value='month'>Month</MenuItem>
            <MenuItem value='year'>Year</MenuItem>
          </Select> */}
          </Box>
        </Box>
      </Box>
      <Line options={options} data={data} />
    </Box>
  );
};
