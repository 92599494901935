import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

export const RadioButtonFormik = (props: {
  id: any;
  label: string;
  formik: any;
  initialValues: any;
  options: { value: string; label: string }[];
}) => {
  const { id, label, formik, initialValues, options } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} key={id}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name={id}
          defaultValue={initialValues[id]}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={formik.values[id]}
          onChange={formik.handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={formik.touched[id] && Boolean(formik.errors[id])}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          helperText={formik.touched[id] && formik.errors[id]}
        >
          <FormLabel
            id={id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 4,
              color: 'black',
            }}
          >
            {label}
          </FormLabel>
          <Box>
            {options &&
              options.map((option: { value: string; label: string }) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  key={option.value}
                />
              ))}
          </Box>
        </RadioGroup>
        {formik.touched[id] && formik.errors[id] && (
          <FormHelperText id={id} error>
            {formik.touched[id] && formik.errors[id]}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
